import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import styles from "src/components/contain/contain.module.css";
import LandingPage from "src/pages/landingPage";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import routes from "src/utils/constants/routes";
import { AuthorizedRoute, UnauthorizedRoute } from "src/utils/routes";
import PublicPage from "../../pages/publicPlaylist/publicPlaylist";
import PrivacyPolicy from "src/pages/privacyPolicy/privacyPolicy";
import TermofCondition from "src/pages/TermsofCondition/TermofCondition";
import About from "src/pages/about/about";
import { Button } from "antd";
import { MenuFoldOutlined } from "@ant-design/icons";
import { setIsHideSideBar, setIsMobileView } from "src/redux/slice/video";

const Contain = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const { isHideSideBar, isMobileView } = useAppSelector(
    (state: RootState) => state.video
  );
  const [ismobile, setIsMobile] = React.useState(false);
  const handleClickMenu = () => {
    setIsMobile(!ismobile);
    dispatch(setIsMobileView(!ismobile));
    dispatch(setIsHideSideBar(!isMobileView));
  };

  // useEffect(() => {
  //   window.addEventListener("resize", () => {
  //     const isViewPresent = document.getElementById("mobileView");
  //     if (isViewPresent) {
  //       // dispatch(setIsMobileView(true));
  //     }
  //   });
  // }, []);

  return (
    <>
      <div
        className={`${styles.contain} ${isMobileView ? styles.nopadding : ""} ${
          isLoggedIn ? styles.contain_color : ""
        }`}
      >
        <Routes>
          <Route
            path="/"
            element={
              <UnauthorizedRoute>
                <LandingPage />
              </UnauthorizedRoute>
            }
          />
          <Route
            path="/privacy_policy"
            element={
              <UnauthorizedRoute>
                <PrivacyPolicy />
              </UnauthorizedRoute>
            }
          />
          <Route
            path="/terms-of-use"
            element={
              // <UnauthorizedRoute>
              <TermofCondition />
            }
          />
          <Route path="/about" element={<About />} />
          <Route
            path="/public/:playlistId/:id"
            element={
              <UnauthorizedRoute>
                <PublicPage />
              </UnauthorizedRoute>
            }
          />
          <Route
            path="/*"
            element={
              <AuthorizedRoute>
                <Button
                  className="menuicon"
                  onClick={handleClickMenu}
                  id="mobileView"
                >
                  <MenuFoldOutlined />
                </Button>
                <Routes>
                  {routes.map((route) => {
                    return (
                      <React.Fragment>
                        <Route
                          key={route.id}
                          path={route.path}
                          element={<route.component />}
                        />
                      </React.Fragment>
                    );
                  })}
                </Routes>
              </AuthorizedRoute>
            }
          />
        </Routes>
      </div>
    </>
  );
};

export default Contain;
