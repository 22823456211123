import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./styles.module.css";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import { Spin } from "antd";
import Footer from "src/components/footer";

const PrivacyPolicy = () => {
  const location = useLocation();
  const allPage = useAppSelector((state: RootState) => state.playlist.allPage);
  const [page, setPage] = useState("");
  useEffect(() => {
    if (allPage) {
      const newPage = allPage.find((item) => {
        return location.pathname.includes(item.path);
      });
      if (newPage) {
        setPage(newPage.content);
      }
    }
  }, [allPage, location.pathname]);
  return (
    <>
      {!page ? (
        <>
          <div className="VideoPlayComponentLoader">
            <div className="text-center">
              {" "}
              <Spin />
            </div>
          </div>
        </>
      ) : (
        <>
          <section className="py-5">
            <Container>
              <div dangerouslySetInnerHTML={{ __html: page }}></div>
            </Container>
          </section>
        </>
      )}
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
