import { IButtonComponent } from "src/components/button";
import React from "react";
import { Button as ButtonBootstrap } from "react-bootstrap";
import styles from "./button.module.css";

function YellowButtonComponent({
  text,
  className,
  ...props
}: IButtonComponent) {
  return (
    <ButtonBootstrap
      {...props}
      className={`${styles.yellow_btn} warning-bg ${className}`}
    >
      {text}
    </ButtonBootstrap>
  );
}

export default YellowButtonComponent;
