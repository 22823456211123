import { SaveVideoPayload } from "src/pages/myPlayList/component/createPlayListModal";
import api from "./api";
import {
  GetPlaylistResponse,
  transformPlaylistResponse,
  getAllPlaylistUserType,
} from "./transform/playlist";
import {
  editPlaylistInput,
  transformVideoResponse,
  VideoResponse,
} from "./transform/video";

const playlistUrl = "/api/v1/playlist/";

export const getAllPlayList = async () => {
  const response = await api.request<GetPlaylistResponse[]>({
    url: playlistUrl,
    method: "get",
  });
  if (response.remote === "success") {
    return {
      remote: "success",
      data: response.data.map((d) => transformPlaylistResponse(d)),
    };
  }
  return response;
};

export const getAllPlaylistOrVideoHandler = async () => {
  const response = await api.request<getAllPlaylistUserType[]>({
    url: `${playlistUrl}allPlaylist-videos`,
    method: "get",
  });
  return response;
};

export const getAllSharedPlaylistHandler = async () => {
  const response = await api.request<getAllPlaylistUserType[]>({
    url: `${playlistUrl}SharedPlaylist`,
    method: "get",
  });
  return response;
};

export const getBinPlaylist = async () => {
  const response = await api.request<getAllPlaylistUserType[]>({
    url: `${playlistUrl}get-bin-playList`,
    method: "get",
  });
  return response;
};
export const createPlayList = async (payload: SaveVideoPayload) => {
  const response = await api.request<getAllPlaylistUserType>({
    url: `${playlistUrl}`,
    method: "post",
    data: payload,
  });
  return response;
};

export const getAllMyplaylistVideos = async (
  id: string,
  name: string,
  days: number
) => {
  const response = await api.request<VideoResponse[]>({
    url: `${playlistUrl}MyPlaylist/${id}?name=${name}&&days=${days}`,
    method: "get",
  });
  if (response.remote === "success") {
    return {
      remote: "success",
      data: response.data.map((d) => transformVideoResponse(d)),
    };
  }
  return response;
};

export const getSharePlaylistVideos = async (id: string) => {
  const response = await api.request<VideoResponse[]>({
    url: `${playlistUrl}SharedPlaylist-videos/${id}`,
    method: "get",
  });
  if (response.remote === "success") {
    return {
      remote: "success",
      data: response.data.map((d) => transformVideoResponse(d)),
    };
  }
  return response;
};

export const deletePlaylist = async (id: string) => {
  const response = await api.request({
    url: `${playlistUrl}${id}`,
    method: "delete",
  });
  return response;
};

export const editPlaylist = async (data: editPlaylistInput) => {
  const response = await api.request({
    url: `${playlistUrl}edit-playlist`,
    method: "patch",
    data,
  });
  return response;
};

export const getBinPlaylistVideo = async (
  id: string,
  name: string,
  days: number
) => {
  const response = await api.request<VideoResponse[]>({
    url: `${playlistUrl}bin-playlist/${id}?name=${name}&&days=${days}`,
    method: "get",
  });
  if (response.remote === "success") {
    return {
      remote: "success",
      data: response.data.map((d) => transformVideoResponse(d)),
    };
  }
  return response;
};
