import { FormControlProps, FormSelectProps } from "react-bootstrap";
import { PrivacyTypeEnum } from "src/utils/constants/enums";
import CheckBoxComponent from "./checkBox";
import IconInputComponent from "./iconInput";
import NormalInput from "./normalInput";
import PasswordInput from "./password";
import SelectInput from "./select";
export interface IInputComponent extends FormControlProps {
  name?: string;
  isDisable?: boolean;
}

type OptionType = {
  id: string;
  title: string;
  value: string;
  privacyType?: PrivacyTypeEnum;
};
export type Tags = {
  _id?: string;
  name: string;
};

export interface ITextAreaComponent extends FormControlProps {
  title?: string;
  setFeedback: (data: string) => void;
  setIsLimit: (data: Boolean) => void;
}
export interface IInputComponent extends FormControlProps {
  title?: string;
}
export interface ISelect extends FormSelectProps {
  title: string;
  options: OptionType[];
  playlist?: [];
}
export interface IIconInput extends IInputComponent {
  isButton: boolean;
  buttonText?: string;
  buttonOnClick?: () => void;
  icon: React.ReactNode;
  iconClassName?: string;
  isGlobalSearch?: boolean;
  id?: string;
  handlePaste?: () => void;
  handlerClear?: () => void;
}

export const Password = PasswordInput;
export const Input = NormalInput;
// export const Name = NameInput;
export const Select = SelectInput;
export const IconInput = IconInputComponent;
export const Checkbox = CheckBoxComponent;
