import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-tag-autocomplete/example/src/styles.css";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <React.StrictMode>
        <SkeletonTheme baseColor="#d9d9d9">
          <App />
        </SkeletonTheme>
      </React.StrictMode>
    </Provider>
  </BrowserRouter>
);
