import React, { useCallback, useEffect, useRef, useState } from "react";

import { Card, Dropdown } from "react-bootstrap";
import { Modal } from "antd";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import {
  addToPrivate,
  deleteShareWithMeVideo,
  deleteVideo,
  downloadVideo,
  editVideo,
  facebookVideoDetail,
  getAllHighlightOrArchiveVideoHandler,
  getEditVideoDetails,
  recycleVideo,
} from "src/api/video";
import SVG from "src/assets/svg";
import SaveVideo from "src/components/header/component/saveVideo";
import { setFavorite, Video } from "src/redux/slice/video";
import styles from "../component/video/video.module.css";
import { setVideoList as setHomeVideoList } from "src/redux/slice/video";
import { getEditVideoDetailUserResponse } from "src/api/transform/video";
import IMAGES from "src/assets/image";
import { PrivacyTypeEnum, VideoType } from "src/utils/constants/enums";
import { highlightOrArchivedType, UserSuggestionType } from "src/types/types";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import { SaveVideoInput } from "src/api/types";
import { getAllPlaylistOrVideoHandler, getBinPlaylist } from "src/api/playlist";
import { setBinPlaylist, setMyPlaylists } from "src/redux/slice/playlist";
import { message } from "antd";
import {
  addToArchiveHandler,
  deleteArchiveOrHightLight,
} from "src/api/archivedOrHightlight";
import { hover } from "@testing-library/user-event/dist/hover";
export interface IVideoComponent extends Video {}

interface IShortsComponentInterface extends IVideoComponent {
  handleDebouncedMouseEnter: ((id: string) => void) & {
    clear(): void;
  } & {
    flush(): void;
  };
  action: string;
  allVideos?: Video[];
  setAllVideos?: (value: Video[]) => void;
  setVideoList?: any;
  gifImage?: string;
  isProviderDelete: boolean;
  setIsStartSequence: (value: boolean) => void;
}

const Shorts = ({
  id,
  thumbnail,
  description,
  handleDebouncedMouseEnter,
  action,
  videoUrl,
  providerName,
  allVideos,
  setVideoList,
  gifImage,
  isHighlighted,
  title,
  isProviderDelete,
  setIsStartSequence,
}: IShortsComponentInterface) => {
  const dispatch = useAppDispatch();
  const [loading, setIsLoading] = useState(false);
  const [isShowTime, setIsShowTime] = useState(false);
  const { videoList } = useAppSelector((state: RootState) => state.video);
  const {
    playlist: { userPlaylist, BinPlaylist },
  } = useAppSelector((state: RootState) => state);
  const [isVideoDelete, setIsVideoDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [videoId, setVideoId] = useState("");
  const [isVideoModalShow, setIsVideoModalShow] = useState(false);
  const customVideoRef = useRef(null);
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  const [hovered, setIsHovered] = useState(false);
  const [customVideoUrl, setCustomVideoUrl] = useState("");
  const [addSearchUserByEmailUsers, setAddSearchUserByEmailUsers] = useState<
    UserSuggestionType[]
  >([]);
  const generateDownloadLink = useCallback(
    async (link: string, action?: string) => {
      const data = await downloadVideo(link);
      if (data.remote === "success") {
        if (action) {
          setCustomVideoUrl(data.data.path);
          return;
        }
      }
    },
    []
  );
  const getFacebookVideo = async (url: string, action?: string) => {
    const data = await facebookVideoDetail(url);
    if (data.remote === "success") {
      if (action === "xvideos") {
        //@ts-ignore
        setCustomVideoUrl(data.data);

        return;
      }

      if (action === "Twitter") {
        //@ts-ignore
        setCustomVideoUrl(data.data.download[0].url);

        return;
      }
      if (action === "Instagram") {
        //@ts-ignore
        setCustomVideoUrl(data[0].download_link);

        return;
      }
      if (action === "Tiktok") {
        //@ts-ignore
        
        setCustomVideoUrl(data.data);
        


        return;
      }
      // @ts-ignore
      setCustomVideoUrl(data.data.medias[0].url);
    }
  };
  const handleCloseModel = () => {
    setIsVideoModalShow(false);
    setVideoId("");
  };
  useEffect(() => {
    if (providerName === "Instagram" && hovered) {
      generateDownloadLink(videoUrl, "Instagram");
    }
  }, [generateDownloadLink, hovered, providerName, videoUrl]);

  useEffect(() => {
    if (providerName === "Twitter" && hovered) {
      getFacebookVideo(videoUrl, "Twitter");
    }
  }, [hovered, providerName, videoUrl]);
  useEffect(() => {
    if (providerName === "TikTok" && hovered) {
      getFacebookVideo(videoUrl, "TikTok");
    }
  }, [hovered, providerName, videoUrl]);

  const handleCancel = () => {
    setOpen(false);
  };
  const editVideoHandler = useCallback(
    async (newPayload: any) => {
      if (BinPlaylist[0]._id === newPayload.playlistId) {
        delete newPayload.playlistId;
      }
      let tempUsers: string[] = [];
      addSearchUserByEmailUsers.forEach((item) => {
        tempUsers.push(item.id);
      });

      const payload: SaveVideoInput = {
        ...newPayload,
        users: tempUsers,
        videoId: id,
      };
      if (!payload.playlistId) {
        payload.playlistId = userPlaylist[0].id;
      }
      const res = await editVideo(payload);
      if (res.remote === "success") {
        if (
          action === "highlight" ||
          action === "private" ||
          action === "myplaylist" ||
          action === "BinPlaylist"
        ) {
          if (allVideos) {
            const vList = [...allVideos];
            const newData = vList.filter((item) => {
              return item.id !== id;
            });
            setVideoList(newData);
          }
        } else {
          const vList = [...videoList];
          const newData = vList.filter((item) => {
            return item.id !== id;
          });
          dispatch(setHomeVideoList(newData));
        }
      }
    },
    [
      BinPlaylist,
      action,
      addSearchUserByEmailUsers,
      allVideos,
      dispatch,
      id,
      setVideoList,
      userPlaylist,
      videoList,
    ]
  );
  const getEditVideoDetailsHandler = useCallback(
    async (id: string) => {
      const data = await getEditVideoDetails(id);

      if (data.remote === "success") {
        const newUsers: getEditVideoDetailUserResponse[] = [];
        data.data.users?.filter((item) => {
          if (item.id) {
            if (item.profilePic) {
              item.profilePic = item.profilePic
                ? `${process.env.REACT_APP_BACKEND_URL_PROFILE_URL}${item.profilePic}`
                : IMAGES.placeholderAvatar;
            }
            newUsers.push(item);
          }
          return item;
        });
        setAddSearchUserByEmailUsers(newUsers);
        if (data.data) {
          const payload = {
            authorName: "",
            customThumbnail: "",
            description: "",
            isPrivate: action === "private" ? true : false,
            playlistId: data.data.playlistId,
            privacyType: data.data.videoPrivacyType || PrivacyTypeEnum.onlyMe,
            providerName: "",
            tag: [],
            thumbnailUrl: data.data.customThumbnail
              ? `${process.env.REACT_APP_BACKEND_URL_PROFILE_URL}${data.data.customThumbnail}`
              : data.data.thumbnailUrl
              ? data.data.thumbnailUrl
              : "",
            type: VideoType.video,
            users: newUsers,
            videoTitle: data.data.videoTitle,
            videoUrl: "",
            isOldThumBnail: false,
            originalThumbnail: data.data.thumbnailUrl || "",
          };

          await editVideoHandler(payload);
        }
      }
    },
    [action, editVideoHandler]
  );
  const handleDeleteShareWithMeVideo = async (id: string) => {
    const data = await deleteShareWithMeVideo(id);
    if (data.remote === "success") {
      setVideoList((pre: any[]) => {
        const newData = pre.filter((item: Video) => {
          return item.id !== id;
        });
        return newData;
      });
    } else {
      message.error("something went wrong");
    }
  };
  const getAllPlaylistOrVideo = useCallback(async () => {
    const data = await getAllPlaylistOrVideoHandler();
    if (data.remote === "success") {
      dispatch(setMyPlaylists(data.data));
    }
  }, [dispatch]);

  const handleDeleteVideo = async (id: string) => {
    setIsLoading(true);
    const data = await deleteVideo(videoId);
    if (data.remote === "success") {
      try {
        await getBinPlaylistHandler();
        if (
          action === "highlight" ||
          action === "private" ||
          action === "myplaylist" ||
          action === "BinPlaylist"
        ) {
          if (allVideos) {
            const vList = [...allVideos];
            const newData = vList.filter((item) => {
              return item.id !== id;
            });
            setVideoList(newData);
          }
        } else {
          const vList = [...videoList];
          const newData = vList.filter((item) => {
            return item.id !== id;
          });
          dispatch(setHomeVideoList(newData));
        }
      } catch (error) {
        console.log({ error });
      }
    } else {
      message.error("Internal server error");
    }
    await getAllPlaylistOrVideo();
    setIsLoading(false);
  };

  const recycleVideoHandler = async (id: string) => {
    const data = await recycleVideo(id);
    if (data.remote === "success") {
      try {
        if (
          action === "highlight" ||
          action === "private" ||
          action === "myplaylist" ||
          action === "BinPlaylist"
        ) {
          if (allVideos) {
            const vList = [...allVideos];
            const newData = vList.filter((item) => {
              return item.id !== id;
            });
            setVideoList(newData);
          }
        } else {
          const vList = [...videoList];
          const newData = vList.filter((item) => {
            return item.id !== id;
          });
          dispatch(setHomeVideoList(newData));
        }
      } catch (error) {
        console.log({ error });
      }
    } else {
      message.error("Internal server error");
    }
    await getAllPlaylistOrVideo();
    setIsLoading(false);
  };
  const getBinPlaylistHandler = useCallback(async () => {
    const data = await getBinPlaylist();
    if (data.remote === "success") {
      dispatch(setBinPlaylist(data.data));
    }
  }, [dispatch]);
  const handleOk = async () => {
    setIsLoading(true);
    if (action === "ShareWithMe") {
      await handleDeleteShareWithMeVideo(videoId);
      setIsLoading(false);
      setOpen(false);
      return;
    }
    if (action === "BinPlaylist") {
      await handleDeleteVideo(videoId);
    } else {
      await recycleVideoHandler(videoId);
      await getBinPlaylistHandler();
    }
    setIsLoading(false);
    setOpen(false);
  };
  const handleRecycle = async () => {
    setIsLoading(true);
    await getEditVideoDetailsHandler(videoId);
    await getBinPlaylistHandler();
    await getAllPlaylistOrVideo();
    setOpen(false);
    setIsVideoDelete(false);
    setIsLoading(false);
  };
  const showDeleteModal = (id: string) => {
    setVideoId(id);
    setOpen(true);
  };
  const addToPrivateHandler = async (id: string) => {
    const data = await addToPrivate(id);
    if (data.remote === "success") {
      if (action === "highlight") {
        if (allVideos) {
          const vList = [...allVideos];
          const newData = vList.filter((item) => {
            return item.id !== id;
          });
          setVideoList(newData);
        }
      } else {
        const vList = [...videoList];
        const newData = vList.filter((item) => {
          return item.id !== id;
        });
        dispatch(setHomeVideoList(newData));
      }
    } else {
      message.error("Internal server error");
    }
  };
  const getAllVideosHandler = useCallback(async () => {
    const data = await getAllHighlightOrArchiveVideoHandler({
      filter: "highlight",
      search: "",
      days: 0,
    });
    if (data.remote === "success") {
      if (data.data.length) {
        dispatch(setFavorite(true));
      }
      if (!data.data.length) {
        dispatch(setFavorite(false));
      }
    }
  }, [dispatch]);
  const addToArchived = async (id: string) => {
    const payload: highlightOrArchivedType = {
      videoId: id,
      privacyType: "highlight",
    };
    const data = await addToArchiveHandler(payload);
    if (data.remote === "success") {
      dispatch(setFavorite(true));
      try {
        setVideoList((pre: any[]) => {
          const newData = pre.map((item: Video) => {
            const newObj = { ...item };
            if (newObj.id === id) {
              newObj.isHighlighted = true;
            }
            return newObj;
          });
          return newData;
        });
      } catch (error) {
        const vList = [...videoList];
        const newData = vList.map((item) => {
          const newObj = { ...item };
          if (item.id === id) {
            newObj.isHighlighted = true;
          }
          return newObj;
        });
        dispatch(setHomeVideoList([...newData]));
      }
      message.success("Success");
    } else {
      message.error("Internal server error");
    }
  };
  const handleDeleteArchiveOrHightLight = async (id: string) => {
    const data = await deleteArchiveOrHightLight(id);
    if (data.remote === "success") {
      await getAllVideosHandler();
      try {
        if (action === "highlight") {
          setVideoList((pre: any[]) => {
            const newData = pre.filter((item: Video) => {
              return item.id !== id;
            });
            return newData;
          });
        } else {
          setVideoList((pre: any[]) => {
            const newData = pre.map((item: Video) => {
              const newObj = { ...item };
              if (newObj.id === id) {
                newObj.isHighlighted = false;
              }
              return newObj;
            });
            return newData;
          });
        }
      } catch (error) {
        const vList = [...videoList];
        const newData = vList.map((item) => {
          const newObj = { ...item };
          if (item.id === id) {
            newObj.isHighlighted = false;
          }
          return newObj;
        });
        dispatch(setHomeVideoList([...newData]));
      }
    } else {
      message.error("Internal server error");
    }
  };

  useEffect(() => {
    if (hovered) {
      const interval = setInterval(() => {
        setIsShowTime(true);
      }, 6000);
      return () => clearInterval(interval);
    }
  }, [hovered]);
  const checkIsVideoDeleted = () => {
    if (isProviderDelete) {
      message.error(
        "Sorry, We can't play this video because this video is deleted from the provider"
      );
    }
  };
  return (
    <>
      <Modal
        title="Video"
        open={open}
        onOk={
          action === "BinPlaylist" && isVideoDelete ? handleRecycle : handleOk
        }
        okButtonProps={{
          style: { backgroundColor: "red", color: "white" },
          children: "Yes",
        }}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <p>
          {action === "BinPlaylist" && isVideoDelete
            ? "Do you want to restore this video ?"
            : "Do you want to delete this video ?"}{" "}
        </p>
      </Modal>
      <Card
        onMouseLeave={() => {
          setIsHovered(false);
          setIsMediaLoaded(false);
          setCustomVideoUrl("");
          setIsShowTime(false);
        }}
        className={
          hovered ? styles.short_video_card : styles.short_video_card_default
        }
      >
        {isProviderDelete ? (
          <>
            <img
              onClick={checkIsVideoDeleted}
              src={IMAGES.deleteImage2}
              alt="gif"
              className={styles.short_thumbnail}
            />
          </>
        ) : (
          <>
            <Link
              to={`/video/${id}?action=${action}`}
              target="_blank"
              style={{ textDecoration: "none" }}
              onClick={() => {
                setIsStartSequence(true);
              }}
            >
              <div className={`text-center`}>
                <span
                  onMouseEnter={() => {
                    // handleDebouncedMouseEnter(id);
                    setIsHovered(true);
                  }}
                >
                  {hovered ? (
                    <>
                      {gifImage?.includes("gif") ? (
                        <>
                          <img
                            src={gifImage}
                            alt="thumb"
                            className={styles.short_thumbnail}
                          />
                        </>
                      ) : (
                        <div
                          style={{
                            pointerEvents: "none",
                          }}
                        >
                          <div>
                            <img
                              src={thumbnail}
                              alt="thumb"
                              onError={(e) => {
                                // @ts-ignore
                                e.target.src = IMAGES.noThumbnail2;
                              }}
                              className={styles.short_thumbnail}
                              style={{
                                display: !isMediaLoaded ? "block" : "none",
                              }}
                            />
                          </div>

                          <div
                            style={{
                              display: isMediaLoaded ? "block" : "none",
                            }}
                          >
                            {customVideoUrl ? (
                              <>
                                <video
                                  style={{ backgroundColor: "#000" }}
                                  className={styles.short_card_view}
                                  autoPlay={true}
                                  id="customVideoPlayer"
                                  width="100%"
                                  height="100%"
                                  ref={customVideoRef}
                                  onLoadedMetadata={() => {
                                    // @ts-ignore
                                    customVideoRef.current.volume = 0;
                                    setIsMediaLoaded(true);
                                  }}
                                  onEnded={() => {
                                    setIsMediaLoaded(false);
                                  }}
                                >
                                  <source src={customVideoUrl} />
                                </video>
                              </>
                            ) : (
                              <>
                                <ReactPlayer
                                  url={videoUrl}
                                  id="customVideoPlayer"
                                  width="100%"
                                  height={300}
                                  config={{
                                    youtube: {
                                      playerVars: {
                                        showinfo: 1,
                                      },
                                    },
                                    facebook: {
                                      appId:
                                        process.env.REACT_APP_FACEBOOK_APP_ID,
                                      attributes: {
                                        "data-height": "198",
                                      },
                                    },
                                  }}
                                  volume={0}
                                  
                                  playing={true}
                                  onReady={() => {
                                    setIsMediaLoaded(true);
                                  }}
                                  onEnded={() => {
                                    setIsMediaLoaded(false);
                                  }}
                                  controls={false}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <>
                        <img
                          onError={(e) => {
                            // @ts-ignore
                            e.target.src = IMAGES.noThumbnail2;
                          }}
                          id="thumbnail"
                          src={thumbnail}
                          alt="thumb"
                          className={styles.short_thumbnail}
                        />
                      </>
                    </>
                  )}
                </span>

                <div className="title_text_shorts">
                  {title.slice(0, 30) + "..."}
                </div>
              </div>
            </Link>
          </>
        )}

        <>
          <div className={styles.main_div_control_short}>
            <Card.Text className={styles.short_description}>
              {description}
            </Card.Text>
            {action === "public" ? (
              <></>
            ) : (
              <div className={styles.short_btn_control}>
                {isHighlighted ? (
                  <>
                    <SVG.FillHeart
                      onClick={() => {
                        handleDeleteArchiveOrHightLight(id);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </>
                ) : (
                  <>
                    <SVG.Heart
                      onClick={() => {
                        addToArchived(id);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </>
                )}{" "}
                <Dropdown className={``}>
                  <Dropdown.Toggle
                    variant="outline"
                    id="dropdown-basic"
                    className={`${styles.threedots_icon_dropdown}`}
                  >
                    <SVG.ThreeDots className={styles.threedots_icon} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={`${styles.video_dropdown}`}>
                    <Dropdown.Item
                      onClick={() => {
                        if (action === "BinPlaylist") {
                          setOpen(true);
                          setIsVideoDelete(true);
                        } else {
                          setIsVideoModalShow(true);
                        }
                        setVideoId(id);
                      }}
                    >
                      {action === "BinPlaylist" ? "Restore" : "Edit"}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        showDeleteModal(id);
                      }}
                    >
                      {" "}
                      Delete
                    </Dropdown.Item>
                    {action === "private" || action === "BinPlaylist" ? (
                      <></>
                    ) : (
                      <>
                        <Dropdown.Item
                          onClick={() => {
                            addToPrivateHandler(id);
                          }}
                        >
                          Mark Private
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </>
      </Card>
      <SaveVideo
        setIsStartSequence={setIsStartSequence}
        setAllVideos={setVideoList}
        allVideos={allVideos}
        show={isVideoModalShow}
        handleCloseModel={handleCloseModel}
        id={videoId}
        action={action}
      />
    </>
  );
};

export default Shorts;
