import { highlightOrArchivedType } from "src/types/types";
import api from "./api";

const archiveOrHighlightUrl = "api/v1/highlight-archive/";
export const addToArchiveHandler = async (payload: highlightOrArchivedType) => {
  const response = await api.request({
    url: `${archiveOrHighlightUrl}`,
    method: "post",
    data: payload,
  });
  return response;
};

export const deleteArchiveOrHightLight = async (id: string) => {
  const newPayload = {
    id,
  };
  const response = await api.request({
    url: `${archiveOrHighlightUrl}`,
    method: "delete",
    data: newPayload,
  });
  return response;
};
