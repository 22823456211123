import { Container, Grid } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import styles from "./component/video/video.module.css";
import VideoComponent from "./component/video";
import VideoSkeleton from "./component/video/video.skeleton";
import { debounce } from "debounce";
import Shorts from "./shorts";
import SVG from "src/assets/svg";
import { Video } from "src/redux/slice/video";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  PointerSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { useAppDispatch } from "src/redux/hooks";
import { setVideoList as setHomeVideoList } from "src/redux/slice/video";
import { createSequence } from "src/api/video";
import { createSequenceRequestSchema } from "src/api/transform/video";
import { useParams } from "react-router-dom";
import { message } from "antd";
import IMAGES from "src/assets/image";
interface IVideoListComponent {
  videoList: Video[];
  shots?: boolean;
  setVideoList?: React.Dispatch<React.SetStateAction<Video[]>>;
  action?: string;
  loading: boolean;
}
const VideoListComponent: React.FC<IVideoListComponent> = ({
  videoList,
  setVideoList,
  shots,
  action,
  loading,
}) => {
  const [sequenceVideo, setSequenceVideo] = useState<Video[]>();
  const [customVideoUrl, setCustomVideoUrl] = useState("");
  const params = useParams();
  const dispatch = useAppDispatch();
  const [isStartSequence, setIsStartSequence] = useState(false);
  const [hovered, setHovered] = useState<string>("");
  const [activeId, setActiveId] = useState<string | null>(null);
  const [filterShots, setFilterShots] = useState<Video[]>([]);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    //@ts-ignore
    setActiveId(event.active.id);
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const newVideos = videoList.filter((item) => {
        return (
          !item.videoUrl.includes("shorts") ||
          !item.videoUrl.includes("instagram") ||
          !item.videoUrl.includes("tiktok") ||
          !item.videoUrl.includes("twitter") 
         
          // item.videoUrl.includes("facebook")
        );
      });
      const allShots = videoList.filter((item) => {
        return (
          item.videoUrl.includes("shorts") ||
          item.videoUrl.includes("instagram") ||
          item.videoUrl.includes("tiktok") ||
          item.videoUrl.includes("twitter") 
          
          // item.videoUrl.includes("facebook")
        );
      });

      if (setVideoList && isStartSequence) {
        setVideoList([...allShots, ...newVideos]);
      }
      if (setHomeVideoList && isStartSequence) {
        dispatch(setHomeVideoList([...allShots, ...newVideos]));
      }

      const { active, over } = event;
      if (!active || !over) {
        return;
      }
      if (active.id !== over?.id) {
        try {
          if (setVideoList) {
            // @ts-ignore
            setVideoList((items) => {
              // @ts-ignore
              let oldIndex;
              items.forEach((item, index) => {
                if (item.id === active.id) {
                  oldIndex = index;
                }
              });
              let newIndex;
              items.forEach((item, index) => {
                if (item.id === over!.id) {
                  newIndex = index;
                }
              });
              // @ts-ignore
              if (
                items.length &&
                typeof oldIndex === "number" &&
                oldIndex >= 0 &&
                typeof newIndex === "number" &&
                newIndex >= 0
              ) {
                // your code here
                setActiveId(null);
                setIsStartSequence(true);
                return arrayMove(items, oldIndex, newIndex);
              }
            });
          }
          if (action === "Home") {
            // @ts-ignore
            let oldIndex;
            videoList.forEach((item, index) => {
              if (item.id === active.id) {
                oldIndex = index;
              }
            });
            let newIndex;
            videoList.forEach((item, index) => {
              if (item.id === over!.id) {
                newIndex = index;
              }
            });

            // @ts-ignore
            if (
              videoList.length &&
              typeof oldIndex === "number" &&
              oldIndex >= 0 &&
              typeof newIndex === "number" &&
              newIndex >= 0
            ) {
              // your code here
              const newHomePageData = arrayMove(videoList, oldIndex, newIndex);
              // @ts-ignore
              dispatch(setHomeVideoList(newHomePageData));
              setActiveId(null);
              setIsStartSequence(true);
              return arrayMove(videoList, oldIndex, newIndex);
            }
          }
        } catch (error) {
          console.log({ error });
        }
      }
      setActiveId(null);
    },
    [action, dispatch, setVideoList, videoList]
  );
  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  const handleDebouncedMouseEnter = debounce((id: string) => {
    setHovered(id);
  }, 100);
  const handleOnMouseLeave = () => {
    setHovered("");
    handleDebouncedMouseEnter.clear();
  };

  interface IHoverWrapper {
    hovered: boolean;
    children: React.ReactNode;
  }
  const HoverWrapper = ({ hovered, children }: IHoverWrapper) => {
    return (
      <>
        <div onMouseLeave={handleOnMouseLeave} className={styles.hover_card}>
          {children}
        </div>
      </>
    );
  };
  const [settings, setSettings] = useState({
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  });

  const handleSequence = useCallback(async () => {
    const videoIds: string[] = [];
    videoList.forEach((item) => {
      if (item.id) {
        videoIds.push(item.id);
      }
    });
    const payload: createSequenceRequestSchema = {
      action: action ? action : "",
      videoIds,
      playlistId: params.playlistId ? (params.playlistId as string) : "",
    };
    const data = await createSequence(payload);
    if (data.remote === "success") {
      setIsStartSequence(false);
    } else {
      message.error("Something went wrong");
    }
  }, [action, params.playlistId, videoList]);

  const handleWindowResize = () => {
    const width = window.innerWidth;
    const count = Math.ceil((6 / 1292) * width);
    // setSettings((prevState) => ({
    //   ...prevState,
    //   slidesToShow: count,
    // }));
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (isStartSequence) {
      handleSequence();
    }
  }, [handleSequence, isStartSequence]);

  useEffect(() => {
    const allShots = videoList.filter((item) => {
      return (
        item.videoUrl.includes("shorts") ||
        item.videoUrl.includes("instagram") ||
        item.videoUrl.includes("tiktok") ||
        item.videoUrl.includes("twitter") ||
        item.videoUrl.includes("reel") ||
        item.videoUrl.includes("d.rapidcdn.app") ||
        item.videoUrl.includes("data.xyz")
        
        
    
        // item.videoUrl.includes("facebook")
      );
    });
    setFilterShots(allShots);
  }, [videoList]);
  // useEffect(() => {

  // }, [dispatch, isStartSequence, setVideoList, videoList]);

  return (
    <>
      {filterShots.length ? (
        <>
          <div className={styles.short}>
            <div className={styles.short_heading_div}>
              <SVG.IconShorts className={`me-1`} />
              <h2 className={styles.heading}>Shorts</h2>
            </div>
            {/* <SVG.RadiusDelete /> */}
          </div>
          <div className={`${styles.short_list_video}`}>
            <Container className={styles.short_slider}>
              <Carousel responsive={settings} infinite={true} >
                {filterShots &&
                  filterShots.map((item, index) => {
                    return (
                      <div key={index}>
                        <Shorts
                          setIsStartSequence={setIsStartSequence}
                          allVideos={videoList}
                          setVideoList={setVideoList}
                          providerName={item.providerName}
                          action={action ? action : ""}
                          {...item}
                          handleDebouncedMouseEnter={handleDebouncedMouseEnter}
                        />
                      </div>
                    );
                  })}
              </Carousel>
            </Container>
          </div>
          <div className={styles.short_bottom_section}>
            {/* <SVG.DownArrow /> */}
          </div>
        </>
      ) : (
        ""
      )}

      <div className={`d-flex px-3 mb-5`}>
        <Grid container spacing={{ xs: 1, lg: 3 }}>
          {loading ? (
            [1, 2, 3, 4, 5, 6, 7, 8].map((_, idx) => (
              <Grid item md={3} xs={12} key={idx}>
                <VideoSkeleton />
              </Grid>
            ))
          ) : (
            <>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
              >
                <SortableContext
                  items={videoList}
                  strategy={rectSortingStrategy}
                >
                  {!videoList.length ? (
                    <>
                      <div className={styles.no_data_div}>
                        <img
                          src={IMAGES.noData}
                          alt=""
                          className={styles.noUse}
                        />
                        <h5 className="mt-2">
                          You have no data in your Playlist.
                        </h5>
                      </div>
                      {/* <span className="Nodatafound">No data found</span> */}
                    </>
                  ) : (
                    <>
                      {videoList.map((item, index) => {
                        if (
                          item.videoUrl.includes("shorts") ||
                          item.videoUrl.includes("instagram") ||
                          item.videoUrl.includes("tiktok") ||
                          item.videoUrl.includes("twitter") ||
                          item.videoUrl.includes("reel") ||
                          item.videoUrl.includes("d.rapidcdn.app") ||
                          item.videoUrl.includes("data.xyz")
                          // item.videoUrl.includes("facebook")
                        ) {
                          return "";
                        }

                        return (
                          <Grid item lg={3} xs={12} key={index}>
                            <div onMouseLeave={handleOnMouseLeave}>
                              {/* <HoverWrapper hovered={hovered === item.id}> */}
                              <VideoComponent
                                users={item.users || []}
                                setIsStartSequence={setIsStartSequence}
                                activeId={activeId}
                                allVideos={videoList}
                                setVideoList={setVideoList}
                                {...item}
                                handleDebouncedMouseEnter={
                                  handleDebouncedMouseEnter
                                }
                                action={action}
                              />
                              {/* </HoverWrapper> */}
                            </div>
                          </Grid>
                        );
                      })}
                    </>
                  )}
                </SortableContext>
                {/* <DragOverlay adjustScale>
                  {activeId ? (
                    <GrabComponent
                      id={activeId}
                      isDragging
                      allVideos={videoList}
                    />
                  ) : null}
                </DragOverlay> */}
              </DndContext>
            </>
          )}
        </Grid>
      </div>
    </>
  );
};

export default VideoListComponent;
