import React, { useCallback, useEffect, useState } from "react";
import VideoListComponent from "src/components/videoList";
import { useParams } from "react-router-dom";
import { getMasterUrlVideos } from "src/api/masterUrl";
import { Video } from "src/redux/slice/video";
const PublicPage = () => {
  const params = useParams();
  const [loading, setIsLoading] = useState(false);
  const [videoList, setVideoList] = useState<Video[]>([]);
  const getPublicPlayListVideos = useCallback(async (id: string) => {
    setIsLoading(true);
    const data = await getMasterUrlVideos(id);
    if (data.remote === "success") {
      setVideoList(data.data);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getPublicPlayListVideos(params.playlistId as string);
  }, [getPublicPlayListVideos, params.playlistId]);

  return (
    <>
      <VideoListComponent
        videoList={videoList}
        action="public"
        loading={loading}
      />
    </>
  );
};

export default PublicPage;
