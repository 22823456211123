import { Video } from "src/redux/slice/video";
import ForgetPasswordComponent from "./forgetPassword";
import ChangesPassword from "./forgetPassword/changeForgetPassword";
import LoginComponent from "./login";
import OtpVerification from "./otp-Verification";
import SignUpComponent from "./signUp";

export interface IAuthComponent {
  show: boolean;
  handleCloseModel: () => void;
  videoLink?: string;
  id?: string;
  allVideos?: Video[];
  setAllVideos?: (value: Video[]) => void;
  action?: string;
  setSearchLink?: (value: string) => void;
  setCurrentSection?: (value: string) => void;
  token?: string;
  setToken?: (value: string) => void;
  searchLink?: string;
  setIsGetDetails?: (value: boolean) => void;
  isGetDetails?: boolean;
  setResentEmail?: (value: string) => void;
  resentEmail?: string;
  setIsStartSequence?: (value: boolean) => void;
}

export const Login = LoginComponent;
export const SignUp = SignUpComponent;
export const Verification = OtpVerification;
export const ForgetPassword = ForgetPasswordComponent;
export const ChangesPasswords = ChangesPassword;
