import IMAGES from "src/assets/image";
import { UserType } from "src/types/types";

export type SignUpApiInput = {
  name: string;
  password: string;
  email: string;
};

export type SignUpApiResponse = {
  message: string;
};

export type UserResponse = {
  _id: string;
  name: string;
  email: string;
  profilePic?: string;
};

export type LoginApiInput = {
  email: string;
  password: string;
};

export type loginWithSocialRequest = {
  name: string;
  email: string;
  profilePic?: string;
};

export type updatePasswordInput = {
  token: string;
  password: string;
  isUserPanel: boolean;
};

export type createFeedback = {
  feedback: string;
  subject: string;
};
export type verifyOypResponse = {
  token: string;
};
export const transformUserResponse = (data: UserResponse): UserType => {
  return {
    id: data._id,
    name: data.name,
    email: data.email,
    profilePic: data.profilePic
      ? `${process.env.REACT_APP_BACKEND_URL_PROFILE_URL}${data.profilePic}`
      : IMAGES.placeholderAvatar,
  };
};
