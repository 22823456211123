import React, { useEffect, useState } from "react";
import { Grid, List, ListItem } from "@mui/material";
import styles from "./landingPage.module.css";
import IMAGES, { socialIcons } from "../../assets/image";
import { YellowButton } from "../../components/button";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { getHeader, midHeader } from "src/api/auth";

function LandingPage() {
  const [midHeaderContain, setMidHeaderContain] = useState([]);
  const [mainHeaderTitle, setMainHeaderTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const getUpperHeader = async () => {
    const data = await getHeader();
    if (data.remote === "success") {
      //@ts-ignore
      const newData = data.data[0];
      //@ts-ignore
      setFileList([
        //@ts-ignore
        `${process.env.REACT_APP_BACKEND_URL}${newData.image1}`,
        //@ts-ignore
        `${process.env.REACT_APP_BACKEND_URL}${newData.image2}`,
        //@ts-ignore
        `${process.env.REACT_APP_BACKEND_URL}${newData.image3}`,
        //@ts-ignore
        `${process.env.REACT_APP_BACKEND_URL}${newData.image4}`,
      ]);
      setMainHeaderTitle(newData.title);
    }
  };
  const getMidHeaderContain = async () => {
    const data = await midHeader();
    if (data.remote === "success") {
      //@ts-ignore
      const newData = data.data.map((item) => {
        if (item.parentId) {
          const payload = {
            ...item,
          };
          return payload;
        } else {
          const payload = {
            ...item,
          };
          delete payload.title;
          return payload;
        }
      });
      //@ts-ignore
      setMidHeaderContain(newData);
    }
  };
  useEffect(() => {
    getUpperHeader();
    getMidHeaderContain();
  }, []);
  useEffect(() => {
    console.log({ midHeaderContain });
  }, [midHeaderContain]);
  return (
    <>
      <Grid className="home-page">
        <section className={`px-3 px-lg-0 ${styles.banner_div}`}>
          <Container>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item md={7} xs={12}>
                <div className="banner-left-text">
                  <h2>
                    Save <span>Videos.</span> Watch <span>Later</span>
                  </h2>
                  <p>
                    {mainHeaderTitle ||
                      "Lorem ipsum dolor sit amet, consectetuer   adipiscing elit, Sed diam nonummy nibh  euismod.Lorem ipsum dolor sit amet, consectetuer"}
                    <br></br> <br></br> <br></br>{" "}
                  </p>
                  <Link to="/?at=sign-up">
                    <YellowButton
                      text={"Create new account"}
                      className={`mt-4`}
                    />
                  </Link>
                </div>
              </Grid>
              <Grid item md={5} xs={12} style={{ paddingTop: "6px" }}>
                <div className={styles.social_banner_div}>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} className={styles.first_card}>
                      <div className={styles.icon_banner_card}>
                        <img
                          src={(fileList && fileList[0]) || IMAGES.camera}
                          alt=""
                        />
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className={styles.icon_banner_card}>
                        <img
                          src={(fileList && fileList[1]) || IMAGES.laptop}
                          alt=""
                        />
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className={styles.icon_banner_card}>
                        <img
                          src={(fileList && fileList[2]) || IMAGES.android}
                          alt=""
                        />
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12} className={styles.fourth_card}>
                      <div className={styles.icon_banner_card}>
                        <img
                          src={(fileList && fileList[3]) || IMAGES.laptop}
                          alt=""
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <div className={`${styles.social_icons_div}`}>
              <Grid justifyContent="center">
                <Grid>
                  <Grid item md={12} xs={12}>
                    <h3 className="text-center">Platforms we support </h3>
                    <div className="">
                      <List>
                        {socialIcons.map((item, index) => {
                          return (
                            <ListItem key={index} className={styles.listblock}>
                              <img
                                src={item.image}
                                alt="img"
                                className={
                                  item.id === "youtube"
                                    ? styles.landing_page_img
                                    : ""
                                }
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section className="work-it-seciotn">
          <Container>
            <Grid container spacing={2}>
              <Grid item lg={12} xs={12}>
                <h3 className={styles.middle_heading}>How it works?</h3>
                <p className={styles.middle_sub_heading}>
                  {/* @ts-ignore */}
                  {(midHeaderContain && midHeaderContain[0]?.title) ||
                    " Lorem ipsum dolor sit amet, consectetuer adipiscing elit, seddiam nonummy nibh euismod"}
                </p>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className={styles.work_left_div}>
                  <img
                    src={
                      (midHeaderContain &&
                        // @ts-ignore
                        `${process.env.REACT_APP_BACKEND_URL}${midHeaderContain[0]?.image}`) ||
                      IMAGES.card
                    }
                    alt=""
                  />
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className={styles.landing_create_account_div}>
                  <h2 className={styles.landing_create_account}>
                    {/* @ts-ignore */}
                    {(midHeaderContain && midHeaderContain[0]?.heading) ||
                      " Create Account"}
                  </h2>
                  <p className={styles.landingpage_create_account_para}>
                    {/* @ts-ignore */}
                    {(midHeaderContain && midHeaderContain[0]?.subHeading) ||
                      "  Lorem ipsum dolor sit amet, consectetuer adipiscing elit,sed diam nonummy nibh euismod"}
                  </p>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className={styles.landing_create_account_div}>
                  <h2 className={styles.landing_create_account}>
                    {" "}
                    {/* @ts-ignore */}
                    {(midHeaderContain && midHeaderContain[1]?.heading) ||
                      " Save Videos"}
                  </h2>
                  <p className={styles.landingpage_create_account_para}>
                    {/* @ts-ignore */}
                    {(midHeaderContain && midHeaderContain[1]?.subHeading) ||
                      " Lorem ipsum dolor sit amet, consectetuer adipiscing elit,sed diam nonummy nibh euismod"}
                  </p>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className={styles.work_left_div}>
                  <img
                    src={
                      (midHeaderContain &&
                        // @ts-ignore
                        `${process.env.REACT_APP_BACKEND_URL}${midHeaderContain[1]?.image}`) ||
                      IMAGES.card
                    }
                    alt=""
                  />
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className={styles.work_left_div}>
                  <img
                    src={
                      (midHeaderContain &&
                        // @ts-ignore
                        `${process.env.REACT_APP_BACKEND_URL}${midHeaderContain[2]?.image}`) ||
                      IMAGES.card
                    }
                    alt=""
                  />
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className={styles.landing_create_account_div}>
                  <h2 className={styles.landing_create_account}>
                    {/* @ts-ignore */}
                    {(midHeaderContain && midHeaderContain[2]?.heading) ||
                      "Sit back and Enjoy"}
                  </h2>
                  <p className={styles.landingpage_create_account_para}>
                    {/* @ts-ignore */}
                    {(midHeaderContain && midHeaderContain[2]?.subHeading) ||
                      "Sit back and Enjoy"}
                  </p>
                </div>
              </Grid>
              <Grid item md={12} xs={12}>
                <div className={styles.four_img}>
                  {/* <img src={IMAGES.four} alt="" /> */}
                </div>
              </Grid>
              <Grid item md={12} xs={12}>
                <div className="work-div">
                  <img
                    src={
                      (midHeaderContain &&
                        // @ts-ignore
                        `${process.env.REACT_APP_BACKEND_URL}${midHeaderContain[3]?.image}`) ||
                      IMAGES.dashboardHome
                    }
                    alt=""
                    className={`w-100`}
                  />
                </div>
              </Grid>
            </Grid>
            <div className={`text-center my-4`}>
              <h1 className={styles.landing_bottom_heading}>
                Try ONEStream for free
              </h1>
              <Link to="/?at=sign-up">
                <YellowButton text={"Create new account"} />
              </Link>
            </div>
            <hr className={styles.landing_page_hr} />
          </Container>
        </section>
      </Grid>
      <Footer />
    </>
  );
}

export default LandingPage;
