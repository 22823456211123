import SVG from "src/assets/svg";

export const NavBarItems = [
  {
    id: "home",
    title: "Home",
    path: "/",
  },
  {
    id: "how-to-save",
    title: "How to save",
    path: "?at=how-to-save",
  },
  {
    id: "sign-up",
    title: "Create Account",
    path: "?at=sign-up",
  },
];

export const ProfileNavBarItems = [
  {
    id: "profile",
    title: "Profile Settings",
    icon: SVG.Setting,
    path: "/profile",
  },
  // {
  //   id: "dark-theme",
  //   title: "Appearance: Dark theme",
  //   icon: SVG.DarkTheme,
  //   path: "?at=theme",
  // },
  // {
  //   id: "language",
  //   title: "Language: English",
  //   icon: SVG.Language,
  //   path: "",
  // },
  // {
  //   id: "location",
  //   title: "Location: Japan",
  //   icon: SVG.Location,
  //   path: "",
  // },
  {
    id: "send-feedback",
    title: "Send Feedback",
    icon: SVG.FeedBack,
    path: "/send-feedback",
  },
  {
    id: "logout",
    title: "Logout",
    icon: SVG.Logout,
    path: "/logout",
  },
];
