import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { UserType } from "src/types/types";

interface IUserInitialState {
  currentUser: UserType;
}

const initialState: IUserInitialState = {
  currentUser: {
    id: "",
    email: "",
    name: "",
    profilePic: "",
  },
};

export const userSlice = createSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<UserType>) => {
      state.currentUser = action.payload;
    },
  },
});
export const { setCurrentUser } = userSlice.actions;
export default userSlice.reducer;
