import React from "react";
import { Form } from "react-bootstrap";
import styles from "./input.module.css";
import { IInputComponent } from ".";

const NormalInput = ({ title, className, ...rest }: IInputComponent) => {
  return (
    <>
      <Form.Label>
        <small
          style={{
            fontSize: "15px",
          }}
        >
          <b>{title}</b>
        </small>
      </Form.Label>
      <Form.Control
        size="lg"
        className={`${styles.password_input} ${className}`}
        {...rest}
      />
    </>
  );
};

export default NormalInput;
