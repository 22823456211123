import { IAuthComponent } from "src/components/header/component/auth";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { RedButton } from "../../../../button";
import Modal from "../../../../modal";
import styles from ".././auth.module.css";
import { updatePassword } from "src/api/auth";
import { updatePasswordInput } from "src/api/transform/auth";
import { message } from "antd";
import { useAppDispatch } from "src/redux/hooks";
import { setIsLoggedIn } from "src/redux/slice/authSlice";
const ChangesPassword = ({ show, handleCloseModel, token }: IAuthComponent) => {
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const updatePasswordHandler = async () => {
    if (token && password) {
      const payload: updatePasswordInput = {
        token,
        password,
        isUserPanel: true,
      };
      if (password !== confirmPassword) {
        message.error("password or confirmPassword does not match");
        return;
      }
      if (password.length < 8) {
        message.error("password Should be minimum 8 Character");
        return;
      }
      const data = await updatePassword(payload);
      if (data.remote === "success") {
        message.success("Password changed successfully");
        dispatch(setIsLoggedIn(true));
      } else {
        message.error(data.error.errors);
      }
    }
  };

  return (
    <>
      <div className={styles.f}>
        <Modal show={show} onClose={handleCloseModel}>
          <h1 className={styles.heading}>Forgot Password</h1>
          {/* <p className={styles.forget_title}>
            Enter your email for the verification proccess, we will send 4
            digits code to your email.
          </p> */}
          <Form>
            <Form.Group className={`mb-4`}>
              <Form.Label>New Password</Form.Label>
              <Form.Control
                size="lg"
                type="password"
                placeholder="New Password"
                className={styles.signup_form_control}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              </Form.Group>
              <Form.Group className={`mb-4`}>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                size="lg"
                type="password"
                placeholder="Confirm Password"
                className={styles.signup_form_control}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
            </Form.Group>
          </Form>
          <RedButton
            text={"Submit"}
            className={`w-100 mb-4`}
            onClick={() => {
              updatePasswordHandler();
            }}
          />
        </Modal>
      </div>
    </>
  );
};

export default ChangesPassword;
