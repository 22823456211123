import React, { useState } from "react";
import OtpInput from "react18-input-otp";
import { forgetPassword, verifyOyp } from "src/api/auth";
import Modal from "src/components/modal";
import { IAuthComponent } from "..";
import { RedButton } from "../../../../button";
import styles from "../auth.module.css";
import { message, Spin } from "antd";
const OtpVerification = ({
  show,
  handleCloseModel,
  setToken,
  setCurrentSection,
  resentEmail,
}: IAuthComponent) => {
  const [loading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const handleChange = (otp: string) => setOtp(otp);
  const verifyOtpHandler = async (otp: string) => {
    setIsLoading(true);
    const data = await verifyOyp(otp);
    if (data.remote === "success") {
      if (setToken) {
        setToken(data.data.token);
        if (setCurrentSection) {
          setCurrentSection("change-forget-password");
        }
      }
    } else {
      message.error(data.error.errors);
    }
    setIsLoading(false);
  };

  const resendPasswordOnEmail = async (email: string) => {
    setIsLoading(true);
    const data = await forgetPassword(email);
    if (data.remote === "success") {
      message.success(`Your verification code is send to ${email}`);
      if (setCurrentSection) {
        setCurrentSection("oto-verification");
      }
    } else {
      message.error("Something went Wrong");
    }
    setIsLoading(false);
  };
  return (
    <>
      <div className={styles.f}>
        <Modal show={show} onClose={handleCloseModel}>
          {loading ? (
            <div className="example">
              <Spin />
            </div>
          ) : (
            <></>
          )}
          <h1 className={styles.heading}>Verification</h1>
          <p className={styles.verification_title}>
            Enter your 4 digits code that We have send to {resentEmail}
          </p>
          <div className={`d-flex justify-content-center`}>
            <OtpInput
              isInputNum
              containerStyle
              value={otp}
              onChange={handleChange}
              numInputs={4}
              className={`${styles.inputStyle}`}
            />
          </div>
          {/* <p className={styles.otp_time}>00:30</p> */}
          <RedButton
            text={"CONTINUE"}
            className={`w-100 my-4`}
            onClick={() => {
              verifyOtpHandler(otp);
            }}
          />
          <p
            className={styles.verification_text}
            onClick={() => {
              if (resentEmail) resendPasswordOnEmail(resentEmail);
            }}
          >
            If you did not receive a code.
            <span className={styles.resend}>Resend</span>
          </p>
        </Modal>
      </div>
    </>
  );
};
export default OtpVerification;
