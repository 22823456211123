import { Grid } from "@mui/material";
import React, { Suspense } from "react";
import { Navigate, useLocation } from "react-router-dom";
import VideoSkeleton from "src/components/videoList/component/video/video.skeleton";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import { ICustomRoute } from ".";

/**
 * @desc: If user is loggedIn then only be these component are visible
 */
function AuthorizedRouteComponent({ children }: ICustomRoute) {
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  const location = useLocation();
  if (!isLoggedIn) {
    return (
      <Navigate
        to="/"
        state={{ from: `${location.pathname}${location.search}` }}
      />
    );
  }
  return (
    <Suspense
      fallback={
        <Grid container spacing={2}>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((_, idx) => (
            <Grid item lg={3} xs={12} key={idx}>
              <VideoSkeleton />
            </Grid>
          ))}
        </Grid>
      }
    >
      {children}
    </Suspense>
  );
}

export default AuthorizedRouteComponent;
