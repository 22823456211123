import IMAGES from "src/assets/image";
import { Video } from "src/redux/slice/video";

export type createMasterUrlRequest = {
  playlistId: string;
};

export type createMasterUrlResponse = {
  playlistId: string;
  masterUrl: string;
};

export type MasterUrlVideoResponse = {
  providerName: string;
  thumbnailUrl: string;
  videoTitle: string;
  videoUrl: string;
  _id: string;
  profilePic?: string;
  isProviderDelete: boolean;
};

export const transformVideoResponseForMasterUrl:any = (
  data: MasterUrlVideoResponse
): Video => {
  return {
    id: data._id,
    thumbnail: data.thumbnailUrl,
    source:
      data.providerName === "YouTube"
        ? IMAGES.youtube
        : data.providerName === "Dailymotion"
        ? IMAGES.dailymotion
        : data.providerName === "Tiktok"
        ? IMAGES.tiktok
        : data.providerName === "Vimeo"
        ? IMAGES.vimeo
        :data.providerName === "Instagram"
        ? IMAGES.instagram
        : data.providerName === "Twitter"
        ? IMAGES.twitter
        : data.providerName === "Facebook"
        ? IMAGES.facebook
        : data.providerName === "SoundCloud"
        ? IMAGES.soundCloud
        : data.providerName === "xvideos"
        ? IMAGES.xvideos
        : data.providerName === "MixCloud"
        ? IMAGES.mixCloud
        : "",
    userImage: data.profilePic || IMAGES.placeholderAvatar,
    title: data.videoTitle,
    description: "",
    isHighlighted: false,
    videoUrl: data.videoUrl,
    providerName: data.providerName,
    isProviderDelete: data.isProviderDelete || false,
    // isProviderDelete: false,
  };
};
