import React from "react";
import { ButtonProps } from "react-bootstrap";
import OutlineButtonComponent from "./outlineButton";
import RedButtonComponent from "./redButton";
import YellowButtonComponent from "./yellowButton";

export interface IButtonComponent extends ButtonProps {
  text: React.ReactNode;
}

export const YellowButton = YellowButtonComponent;

export const RedButton = RedButtonComponent;

export const OutlinedButton = OutlineButtonComponent;
