import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import Footer from "../../components/footer";
import { Container } from "react-bootstrap";
import { Grid } from "@mui/material";
import IMAGES from "../../assets/image";
import styles from "../landingPage/landingPage.module.css";

import { Link } from "react-router-dom";
import { YellowButton } from "../../components/button";

const About = () => {
  const location = useLocation();
  const allPage = useAppSelector((state: RootState) => state.playlist.allPage);
  const [page, setPage] = useState("");
  useEffect(() => {
    if (allPage) {
      const newPage = allPage.find((item) => {
        return location.pathname.includes(item.path);
      });
      if (newPage) {
        setPage(newPage.content);
      }
    }
  }, [allPage, location.pathname]);
  return (
    <>
      {!page ? (
        <>
          <div className="VideoPlayComponentLoader">
            <div className="text-center">
              {" "}
              <Spin />
            </div>
          </div>
        </>
      ) : (
        <>
          <section className={`px-3 px-lg-0 ${styles.banner_div}`}>
            <Container>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item md={7} xs={12}>
                  <div className="banner-left-text">
                    <h2>
                      Save <span>Videos.</span> Watch <span>Later</span>
                    </h2>
                    <p>
                      Testing Description Lorem ipsum dolor sit amet,
                      consectetuer adipiscing elit,sed diam nonummy nibh euismod
                    </p>
                    <Link to="/?at=sign-up">
                      <YellowButton
                        text={"Create new account"}
                        className={`mt-4`}
                      />
                    </Link>
                  </div>
                </Grid>
                <Grid item md={5} xs={12} style={{ paddingTop: "6px" }}>
                  <div className={styles.social_banner_div}>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12} className={styles.first_card}>
                        <div className={styles.icon_banner_card}>
                          <img src={IMAGES.camera} alt="" />
                        </div>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <div className={styles.icon_banner_card}>
                          <img src={IMAGES.laptop} alt="" />
                        </div>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <div className={styles.icon_banner_card}>
                          <img src={IMAGES.android} alt="" />
                        </div>
                      </Grid>
                      <Grid item md={6} xs={12} className={styles.fourth_card}>
                        <div className={styles.icon_banner_card}>
                          <img src={IMAGES.laptop} alt="" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </section>
          <section className="py-5">
            <Container>
              <div dangerouslySetInnerHTML={{ __html: page }}></div>;
            </Container>
          </section>
        </>
      )}
      <Footer />
    </>
  );
};

export default About;
