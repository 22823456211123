import { IInputComponent } from "src/components/input";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import SVG from "../../assets/svg";
import styles from "./input.module.css";

function PasswordInput({
  title,
  className,
  isDisable,
  ...rest
}: IInputComponent) {
  const [isShow, setIsShow] = useState(false);
  const handleShowChange = () => setIsShow(!isShow);

  return (
    <>
      <Form.Label>{title}</Form.Label>
      <Form.Control
        size="lg"
        type={isShow ? "text" : "password"}
        placeholder="Password"
        className={`${styles.password_input} ${className}`}
        {...rest}
      />
      {isDisable ? (
        <></>
      ) : (
        <>
          {!isShow ? (
            <span className={styles.eye} onClick={handleShowChange}>
              <SVG.Eye />
            </span>
          ) : (
            <span className={styles.eye} onClick={handleShowChange}>
              <SVG.EyeClose />
            </span>
          )}
        </>
      )}
    </>
  );
}

export default PasswordInput;
