import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { RedButton } from "../../../../button";
import styles from "../auth.module.css";
import { Password } from "../../../../input";
import Modal from "../../../../modal";
import { IAuthComponent } from "src/components/header/component/auth";
import { tokens } from "src/utils/jwtTokenStorage";
import { useAppDispatch } from "src/redux/hooks";
import { setIsLoggedIn } from "src/redux/slice/authSlice";
import { loginUser, loginWithSocialMedia } from "src/api/auth";
import { LoginApiInput, loginWithSocialRequest } from "src/api/transform/auth";
import { setCurrentUser } from "src/redux/slice/userSlice";
import { UserErrorType } from "src/types/types";
import Loader from "src/utils/loader";
import { useToastContext } from "src/context/toastContext";
import { facebookLogin, signInWithGoogle } from "src/firebase";
import { message } from "antd";
import { socialMediaLoginIcons } from "src/assets/image";
interface LoginForm extends LoginApiInput {
  rememberMe: boolean;
}

function LoginComponent({ show, handleCloseModel }: IAuthComponent) {
  const addToast = useToastContext();
  const dispatch = useAppDispatch();
  const [loading, setIsLoading] = useState(false);
  const [isSocialButtonShow, setIsSocialButtonShow] = useState(false);
  const [userData, setUserData] = useState<LoginForm>({
    email: "",
    password: "",
    rememberMe: false,
  });

  const [loginUserError, setLoginUserError] = useState<UserErrorType>({
    emailError: "",
    passwordError: "",
    backendError: "",
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value;
    if (e.target.name === "rememberMe") {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }
    const type = e.target.name;
    setUserData({
      ...userData,
      [type]: value,
    });
  };

  const login = async () => {
    const newError: UserErrorType = {};
    if (userData.password === "" && userData.email === "") {
      newError.emailError = "Email is required";
      newError.passwordError = "Password is required";
    }

    if (userData.email === "") {
      newError.emailError = "Email is required";
    }
    if (userData.password === "") {
      newError.passwordError = "Password is required";
    }
    if (Object.keys(newError).length) {
      return setLoginUserError({
        ...newError,
      });
    }
    setIsLoading(true);
    const payload: LoginApiInput = {
      password: userData.password,
      email: userData.email,
    };

    const data = await loginUser(payload);
    if (data.remote === "success") {
      dispatch(setIsLoggedIn(true));
      dispatch(setCurrentUser(data.data));
      handleCloseModel();
    } else {
      const error = data.error.errors;
      addToast(error);
    }
    setIsLoading(false);
  };

  const rememberMe = useCallback(async () => {
    if (!userData.password) {
      return;
    }
    if (!userData.email) {
      return;
    }
    if (!userData.rememberMe) {
      tokens.removeIsRememberMe();
    } else {
      tokens.setIsRememberMe();
    }
  }, [userData.email, userData.password, userData.rememberMe]);

  const loginWithGoogle = useCallback(async () => {
    const user = await signInWithGoogle();
    if (user.user.providerData.length) {
      const payload: loginWithSocialRequest = {
        name: user.user.providerData[0].displayName || "",
        email: user.user.providerData[0].email || "",
        profilePic: user.user.providerData[0].photoURL || "",
      };
      const data = await loginWithSocialMedia(payload);
      if (data.remote === "success") {
        dispatch(setIsLoggedIn(true));
      } else {
        message.error("Something went wrong");
      }
    }
  }, [dispatch]);

  const loginWithFacebook = useCallback(async () => {
    const user = await facebookLogin();
    if (user.user.providerData.length) {
      const payload: loginWithSocialRequest = {
        name: user.user.providerData[0].displayName || "",
        email: user.user.providerData[0].email || "",
        profilePic: user.user.providerData[0].photoURL || "",
      };
      const data = await loginWithSocialMedia(payload);
      if (data.remote === "success") {
        dispatch(setIsLoggedIn(true));
      } else {
        message.error("Something went wrong");
      }
    }
  }, [dispatch]);

  useEffect(() => {
    rememberMe();
  }, [rememberMe, userData.rememberMe]);

  return (
    <div>
      <Modal show={show} onClose={handleCloseModel}>
        <div>
          <h1 className={styles.heading}>Log In</h1>
          <Form>
            <Form.Group className={`mb-4`}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                placeholder="Email"
                type="email"
                size="lg"
                className={styles.signup_form_control}
                value={userData.email}
                onChange={handleChange}
              />
              <span>{loginUserError.emailError}</span>
            </Form.Group>
            <Form.Group className={`mb-1`}>
              <Form.Label>Password</Form.Label>
              <Password
                name="password"
                onChange={handleChange}
                value={userData.password}
              />
              <span>{loginUserError.passwordError}</span>
            </Form.Group>
            <div className={`text-end`}>
              <Link to="/?at=forget-password" className={styles.login_forgot}>
                Forgot Password ?
              </Link>
            </div>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Remember me"
                name="rememberMe"
                onChange={handleChange}
                className={styles.login_remember_me}
              />
            </Form.Group>
            <RedButton
              text={loading ? <Loader /> : "Log In"}
              className={`w-100 mb-4`}
              onClick={login}
            />
            <RedButton
              text={"sign with social account"}
              className={`w-100 mb-3`}
              onClick={() => {
                setIsSocialButtonShow(true);
              }}
            />
            {isSocialButtonShow ? (
              <div className="d-flex align-items-center justify-content-center">
                {socialMediaLoginIcons.map((item, index) => {
                  return (
                    <div key={index} className={`mx-3 my-2 `}>
                      <img
                        onClick={
                          item.id === "facebook"
                            ? loginWithFacebook
                            : loginWithGoogle
                        }
                        src={item.image}
                        alt="img"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
            <p className={styles.title_account}>Don’t have an account?</p>
            <Link to="/?at=sign-up" style={{ textDecoration: "none" }}>
              <h2 className={styles.sub_heading}>Sign Up</h2>
            </Link>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default LoginComponent;
