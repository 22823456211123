import React, { useCallback, useEffect, useState } from "react";
import { Container, Dropdown, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import SVG from "src/assets/svg";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import { YellowButton } from "../button";
import { IconInput } from "../input";
import { ForgetPassword, Login, SignUp } from "./component/auth";
import OtpVerification from "./component/auth/otp-Verification";
import SaveVideo from "./component/saveVideo";
import styles from "./header.module.css";
import { NavBarItems, ProfileNavBarItems } from "./utils/helper";
import { ChangesPasswords } from "../../../src/components/header/component/auth/index";
import {
  setFavorite,
  setFilter,
  setGlobalSearch,
  setStopGlobalSearch,
} from "src/redux/slice/video";
import { TypeAllVideoFormate, getFormate } from "src/api/video";
import { message } from "antd";

const Header = () => {
  const [allVideoFormate, setAllVideoFormate] = useState<TypeAllVideoFormate[]>(
    []
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [filterOption, setFilterOption] = useState("Sort by");
  const [resentEmail, setResentEmail] = useState<string>("");
  const [isGetDetails, setIsGetDetails] = useState(false);
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const currentUser = useAppSelector(
    (state: RootState) => state.user.currentUser
  );
  const { globalSearch, stopGlobalSearch } = useAppSelector(
    (state: RootState) => state.video
  );
  const [token, setToken] = useState("");
  const [searchLink, setSearchLink] = useState("");
  const [currentSection, setCurrentSection] = useState("");
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  useEffect(() => {
    const url = new URL(window.location.href);
    const currentSection = url.searchParams.get("at") || "";
    setCurrentSection(currentSection);
    const videoUrl = url.searchParams.get("url") || "";
    setSearchLink(videoUrl);
  }, [location]);

  // useEffect(() => {
  //   console.log(location);
  //   if (location.search.includes("at=login")) {
  //     document.body.style.overflow = "auto";
  //     console.log("helllo");
  //   } else {
  //     console.log("world");
  //     document.body.style.overflow = "hidden";
  //   }
  // }, [location]);

  const handleCloseModel = () => {
    const url = new URL(window.location.href);
    url.searchParams.set("at", "");
    navigate(url.pathname);
    setSearchLink("");
  };

  interface IHeaderContainer {
    children: React.ReactNode;
  }
  const HeaderContainerComponent: React.FC<IHeaderContainer> = ({
    children,
  }) => {
    return (
      <>
        {isLoggedIn ? (
          <div style={{ display: "contents" }}>{children}</div>
        ) : (
          <Container>{children}</Container>
        )}
      </>
    );
  };

  const getAllFormateHandler = async () => {
    const data = await getFormate();
    if (data.remote === "success") {
      setAllVideoFormate(data.data);
    }
  };
  const handleSaveVideo = async () => {
    // const isAvailable = allVideoFormate.find((item) => {
    //   return (
    //     searchLink.toLowerCase().includes(item.name.toLowerCase()) ||
    //     (searchLink.toLowerCase().includes("youtu.be") && !item.isDeleted)
    //   );
    // });
    const isAvailable = true;
    if (isAvailable) {
      if (searchLink) {
        navigate(`?at=save-video&url=${searchLink}`);
      }
      setIsGetDetails(true);
    } else {
      message.error(
        "Currently, the admin has disabled this service. Please contact the admin for assistance.",
        3
      );
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchLink = e.target.value;
    setSearchLink(newSearchLink.trimStart());
  };

  const handleOpenGlobalSearch = () => {
    setIsGlobalSearch(true);
    dispatch(setStopGlobalSearch(false));
  };
  const handleCloseGlobalSearch = useCallback(
    (name: string) => {
      setIsGlobalSearch(false);
      dispatch(setGlobalSearch(name));
      setSearchLink("");
    },
    [dispatch]
  );

  const handlePaste = async () => {
    const clipboardData = await navigator.clipboard.readText();
    setSearchLink(clipboardData);
  };

  const handlerClear = () => {
    setSearchLink("");
  };

  useEffect(() => {
    if (globalSearch === "" && stopGlobalSearch) {
      handleCloseGlobalSearch("");
    }
  }, [globalSearch, handleCloseGlobalSearch, stopGlobalSearch]);
  useEffect(() => {
    if (isGlobalSearch) {
      dispatch(setGlobalSearch(searchLink));
    }
  }, [searchLink, isGlobalSearch, dispatch]);
  useEffect(() => {
    getAllFormateHandler();
  }, []);

  return (
    <>
      <Navbar
        expand="lg"
        className={`pe-2 shadow-sm ${styles.header} ${
          isLoggedIn ? styles.header_header : ""
        }`}
      >
        <HeaderContainerComponent>
          <Navbar.Brand
            href="/home"
            className={`${styles.logo} ${isLoggedIn ? styles.header_logo : ""}`}
          >
            ONEStream
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className={styles.fixedmenu}>
            {!isLoggedIn ? (
              <Nav className={styles.header_nav}>
                <div className={`${styles.mainHeader}`}>
                  {NavBarItems.map((item) => (
                    <NavLink
                      key={item.id}
                      to={item.path}
                      className={`${styles.menu} ${
                        currentSection === item.id ||
                        (currentSection === "" && item.id === "home")
                          ? styles.active
                          : ""
                      }`}
                    >
                      {item.title}
                    </NavLink>
                  ))}
                  <Link to="/?at=login">
                    {/* <YellowButton text={"Login"} className={`ms-5`} /> */}
                    <YellowButton
                      text={"Login"}
                      className={`${styles.loginBtn}`}
                    />
                  </Link>
                </div>
              </Nav>
            ) : (
              <Nav className={styles.header_nav}>
                <div className={styles.search}>
                  <IconInput
                    handlerClear={handlerClear}
                    value={searchLink}
                    handlePaste={handlePaste}
                    onChange={handleChange}
                    isButton
                    buttonText={"Save Video"}
                    buttonOnClick={() => {
                      handleSaveVideo();
                    }}
                    iconClassName={styles.header_attachicon}
                    icon={
                      isGlobalSearch ? (
                        <SVG.GlobalSearchIcon />
                      ) : (
                        <>
                          <SVG.AttachIcon />
                        </>
                      )
                    }
                    isGlobalSearch={isGlobalSearch}
                  />
                </div>
                <div
                  className={`d-flex align-items-center header-right-section`}
                >
                  {isGlobalSearch ? (
                    <span
                      className={styles.crossIcon}
                      onClick={() => {
                        handleCloseGlobalSearch("");
                      }}
                    >
                      x
                    </span>
                  ) : (
                    <span
                      className={styles.header_right_side}
                      onClick={handleOpenGlobalSearch}
                    >
                      <SVG.SearchIcon />
                    </span>
                  )}

                  <div className={styles.dropmenu}>
                    {" "}
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="filterOption"
                      >
                        {filterOption}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            setFilterOption("All");
                            dispatch(setFilter(0));
                          }}
                          active={filterOption === "All"}
                        >
                          All
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setFilterOption("Today");
                            dispatch(setFilter(1));
                          }}
                          active={filterOption === "Today"}
                        >
                          Today
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setFilterOption("Last two days");
                            dispatch(setFilter(2));
                          }}
                          active={filterOption === "Last two days"}
                        >
                          Last two days
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setFilterOption("Last 7 days");
                            dispatch(setFilter(7));
                          }}
                          active={filterOption === "Last 7 days"}
                        >
                          Last 7 days
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <Dropdown className={`ms-lg-4`}>
                    <Dropdown.Toggle
                      variant="outline"
                      id="dropdown-basic"
                      className="header_sidebar_dropdown text-light"
                    >
                      <div className={styles.profil_name_div}>
                        <img
                          src={currentUser.profilePic}
                          alt="avatar"
                          className={styles.avatar}
                        />{" "}
                        <p>{currentUser.name}</p>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={styles.header_dropdown}>
                      {ProfileNavBarItems.map((items) => {
                        return (
                          <React.Fragment key={items.id}>
                            <Dropdown.Item
                              onClick={() => {
                                if (items.path) navigate(items.path);
                              }}
                            >
                              <div className={styles.header_item_feedback}>
                                <div>
                                  <items.icon className={`me-3`} />{" "}
                                  {items.title}
                                </div>
                                {items.path && (
                                  <SVG.RightArrow
                                    className={styles.right_icon}
                                  />
                                )}
                              </div>
                            </Dropdown.Item>
                          </React.Fragment>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav>
            )}
          </Navbar.Collapse>
        </HeaderContainerComponent>
      </Navbar>
      <SignUp
        show={currentSection === "sign-up"}
        handleCloseModel={handleCloseModel}
      />
      <Login
        show={currentSection === "login"}
        handleCloseModel={handleCloseModel}
      />
      <ForgetPassword
        setResentEmail={setResentEmail}
        show={currentSection === "forget-password"}
        handleCloseModel={handleCloseModel}
        setCurrentSection={setCurrentSection}
      />
      <SaveVideo
        isGetDetails={isGetDetails}
        setIsGetDetails={setIsGetDetails}
        show={currentSection === "save-video"}
        handleCloseModel={handleCloseModel}
        setSearchLink={setSearchLink}
        searchLink={searchLink}
      />
      <OtpVerification
        show={currentSection === "oto-verification"}
        handleCloseModel={handleCloseModel}
        setToken={setToken}
        setCurrentSection={setCurrentSection}
        resentEmail={resentEmail}
      />
      <ChangesPasswords
        show={currentSection === "change-forget-password"}
        handleCloseModel={handleCloseModel}
        token={token}
      />
    </>
  );
};

export default Header;
