import { PrivacyTypeEnum } from "./enums";

export const AvailablePrivacyList = [
  {
    id: "onlyMe",
    title: "Only Me",
    value: PrivacyTypeEnum.onlyMe,
  },
  {
    id: "sharedWith",
    title: "Friends",
    value: PrivacyTypeEnum.sharedWith,
  },
  {
    id: "public",
    title: "Public",
    value: PrivacyTypeEnum.public,
  },
  //add public option may be later
];

export const highlightOrArchiveType = {
  archived: "archived",
  highlight: "highlight",
};
