import "./App.css";
import Header from "src/components/header";
import Sidebar from "src/components/sidebar";
import Contain from "src/components/contain";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { setIsLoggedIn } from "./redux/slice/authSlice";
import { tokens } from "./utils/jwtTokenStorage";
import { RootState } from "./redux/store";
import { getUserDetails } from "./api/auth";
import { setCurrentUser } from "./redux/slice/userSlice";
import { Route, Routes } from "react-router-dom";
import Video from "./pages/video";
import { ToastContextProvider } from "./context/toastContext";
import { setOneStreamPage, setUserPlaylist } from "./redux/slice/playlist";
import { getAllPlayList } from "./api/playlist";
import { getAllPage } from "./api/video";
import { message } from "antd";

function App() {
  const dispatch = useAppDispatch();
  const isLoggedIn: boolean = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const [hasError, setHasError] = useState(false);
  const getUserInformation = useCallback(async () => {
    const data = await getUserDetails();
    console.log({ data });

    if (data.remote === "success") {
      dispatch(setCurrentUser(data.data));
    } else {
      //@ts-ignore
      if (data.error.status === 401) {
        dispatch(setIsLoggedIn(false));
      }
    }
  }, [dispatch]);

  const fetchPlaylist = useCallback(async () => {
    const res = await getAllPlayList();
    if (res.remote === "success") {
      dispatch(setUserPlaylist(res.data));
    }
  }, [dispatch]);

  const getAllPages = useCallback(async () => {
    const res = await getAllPage();
    if (res.remote === "success") {
      dispatch(setOneStreamPage(res.data));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(setIsLoggedIn(!!tokens.accessToken()));
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      getUserInformation();
      fetchPlaylist();
    }
  }, [getUserInformation, fetchPlaylist, isLoggedIn]);

  useEffect(() => {
    const errorHandler = (error: any) => {
      console.log({ error });
      setHasError(true);
    };
    window.addEventListener("error", errorHandler);
    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);
  useEffect(() => {
    getAllPages();
  }, [getAllPages]);
  useEffect(() => {
    document.body.addEventListener("click", (event) => {
      const messageElement = document.getElementsByClassName(
        "ant-message-notice-content"
      );
      if (messageElement) {
        const forceAntdMessageClose = document.getElementById("antd-msg-close");
        //@ts-ignore
        if (forceAntdMessageClose) {
          if (messageElement.length) {
            forceAntdMessageClose.style.display = "block";
            forceAntdMessageClose.click();
          } else {
            forceAntdMessageClose.style.display = "none";
          }
        } else {
        }
      } else {
        //@ts-ignore
        // forceAntdMessageClose?.style.display = "block";
      }
      const clickedElement = event.target;

      if (clickedElement) {
        // message.destroy();
      }
    });
  }, []);
  return (
    <div className="App">
      <div
        onClick={() => {
          message.destroy();
        }}
        id="antd-msg-close"
      ></div>
      <ToastContextProvider>
        <Routes>
          <Route path="/video/:videoId" element={<Video />} />
          <Route
            path="/*"
            element={
              <>
                <Header />
                <div className="sidebar_contain">
                  {isLoggedIn ? <Sidebar /> : null}
                  <Contain />
                </div>
              </>
            }
          />
        </Routes>
      </ToastContextProvider>
    </div>
  );
}

export default App;
