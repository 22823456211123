import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Footer from "src/components/footer";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";

const TermofCondition = () => {
  const location = useLocation();
  const allPage = useAppSelector((state: RootState) => state.playlist.allPage);
  const [page, setPage] = useState("");
  useEffect(() => {
    if (allPage) {
      const newPage = allPage.find((item) => {
        return location.pathname.includes(item.path);
      });
      if (newPage) {
        setPage(newPage.content);
      }
    }
  }, [allPage, location.pathname]);
  return (
    <>
      {!page ? (
        <>
          <>
            <div className="VideoPlayComponentLoader">
              <div className="text-center">
                {" "}
                <Spin />
              </div>
            </div>
          </>
        </>
      ) : (
        <>
          <section className="py-5">
            <Container>
              <div dangerouslySetInnerHTML={{ __html: page }}></div>
            </Container>
          </section>
        </>
      )}
      <Footer />
    </>
  );
};

export default TermofCondition;
