import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
  deleteUser,
  FacebookAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIRE_BASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGEdeNId,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MESSAGINGSdeNId,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  provider.setCustomParameters({
    prompt: "select_account",
  });
  const user = await signInWithPopup(auth, provider);
  return user;
};

export const facebookLogin = async () => {
  const facebookProvider = new FacebookAuthProvider();
  facebookProvider.setCustomParameters({
    display: "popup",
  });
  const user = await signInWithPopup(auth, facebookProvider);
  return user;
};

export const getFirebaseUser = async () => {
  let presentUser;
  try {
    onAuthStateChanged(auth, (user) => {
      presentUser = user;
    });
    return presentUser;
  } catch (error) {
    console.log({ error });
  }
};

export const logout = () => {
  signOut(auth)
    .then((res) => {
      console.log("Logout Succes", res);
      // Sign-out successful.
    })
    .catch((error) => {
      console.log("Logout fail", error);
      // An error happened.
    });
};

export const deleteUserFromFireBase = () => {
  const user = auth.currentUser;
  if (user) {
    deleteUser(user)
      .then(() => {
        console.log("user Delete");
        // User deleted.
      })
      .catch((error) => {
        console.log("user Not Delete", error);
        // An error ocurred
        // ...
      });
  }
};
