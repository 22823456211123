import laptop from "./laptop.png";
import tiktok from "./tiktok.png";
import instagram from "./instagram.png";
import vimeo from "./vimeo.png";
import twitter from "./twitter.png";
import youtube from "./youtube.png";
import camera from "./camera.png";
import android from "./android.png";
import facebook from "./facebook.png";
import dailymotion from "./dailymotion.png";
import dashboardHome from "./dashboard-home.png";
import card from "./card.png";
import card1 from "./card1.png";
import card2 from "./card2.png";
import four from "./04.png";
import avatar from "./avatar-1.jpg";
import image1 from "./image 1.png";
import frame from "./Frame.png";
import videoImg from "./video_img.png";
import whatsApp from "./whatsapp.png";
import twitter1 from "./twitter1.png";
import placeholderAvatar from "./placeholder-avatar.png";
import Rar from "./rar.png";
import facebookIcon from "./facebook-new.png";
import googleIcon from "./google-new.png";
import downloadIcon from "./newDownloadIcon.png";
import noThumbnail from "./logo2.png";
import Twitch from "./twicth.png";
import GifImage from "./change-clothes-old-man.gif";
import rewind from "./play (3).png";
import fastForword from "./play (2).png";
import play from "./play.png";
import pause from "./pause.png";
import play2 from "./play (1).png";
import pause2 from "./pause (1).png";
import tiktoke from "./tiktoke1.png";
import deleteImage from "./horizontalNot.jpeg";
import deleteVideoImg from "./deleteVideoMsg.png";
import deleteImage2 from "./delete3.jpeg";
import volume from "./sound.png";
import muteVolume from "./mute.png";
import xvideos from "./xvideos.png";
import noData from "./no-data-found.png";
import errorImage from "./500error.png";
import mixCloud from "./mixCloud.png";
import soundCloud from "./soundCloud.png";
import noThumbnail2 from "./logo3.jpg";
const IMAGES = {
  Rar,
  twitter1,
  whatsApp,
  laptop,
  instagram,
  vimeo,
  tiktok,
  twitter,
  camera,
  android,
  youtube,
  facebook,
  dailymotion,
  dashboardHome,
  card,
  card1,
  card2,
  four,
  avatar,
  image1,
  frame,
  videoImg,
  placeholderAvatar,
  facebookIcon,
  googleIcon,
  downloadIcon,
  noThumbnail,
  Twitch,
  GifImage,
  fastForword,
  rewind,
  play,
  pause,
  play2,
  pause2,
  tiktoke,
  deleteImage,
  deleteVideoImg,
  volume,
  muteVolume,
  noData,
  xvideos,
  errorImage,
  mixCloud,
  soundCloud,
  noThumbnail2,
  deleteImage2,
};
export const socialIcons = [
  {
    id: "youtube",
    image: IMAGES.youtube,
  },
  {
    id: "facebook",
    image: IMAGES.facebook,
  },
  {
    id: "twitter",
    image: IMAGES.twitter,
  },

  {
    id: "instagram",
    image: IMAGES.instagram,
  },
  {
    id: "tiktok",
    image: IMAGES.tiktok,
  },
  {
    id: "vimeo",
    image: IMAGES.vimeo,
  },
  {
    id: "dailymotion",
    image: IMAGES.dailymotion,
  },
];

export const socialMediaLoginIcons = [
  {
    id: "facebook",
    image: IMAGES.facebookIcon,
  },
  {
    id: "google",
    image: IMAGES.googleIcon,
  },
];

export default IMAGES;
