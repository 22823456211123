export enum PrivacyTypeEnum {
  sharedWith = "sharedWith",
  public = "public",
  onlyMe = "onlyMe",
}

export enum VideoType {
  short = "short",
  video = "video",
}
