import api from "./api";
import {
  createMasterUrlRequest,
  createMasterUrlResponse,
  MasterUrlVideoResponse,
  transformVideoResponseForMasterUrl,
} from "./transform/masterUrl";

const masterUrl = "api/v1/master-url/";

export const masterUrlGenerate = async (payload: createMasterUrlRequest) => {
  const response = await api.request<createMasterUrlResponse>({
    url: `${masterUrl}`,
    method: "POST",
    data: payload,
  });
  return response;
};

export const getMasterUrlVideos = async (id: string) => {
  const response = await api.request<MasterUrlVideoResponse[]>({
    url: `${masterUrl}${id}`,
    method: "get",
  });
  if (response.remote === "success") {
    return {
      remote: "success",
      data: response.data.map((data) =>
        transformVideoResponseForMasterUrl(data)
      ),
    };
  }
  return response;
};
