import React from "react";
import { Form, FormCheckProps } from "react-bootstrap";
import styles from "./input.module.css";

interface ICheckbox extends FormCheckProps {
  name?: string;
}

const CheckBox = ({ name, ...rest }: ICheckbox) => {
  return (
    <div className={`mb-3`}>
      <Form>
        <div className={`${styles.form_check}`}>
          <Form.Check {...rest} />
          <Form.Label className={`mx-2`}>{name}</Form.Label>
        </div>
      </Form>
    </div>
  );
};

export default CheckBox;
