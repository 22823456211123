import { message, Modal } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CSS } from "@dnd-kit/utilities";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import {
  addToArchiveHandler,
  deleteArchiveOrHightLight,
} from "src/api/archivedOrHightlight";
import ReactPlayer from "react-player";
import SVG from "src/assets/svg";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { setFavorite, Video } from "src/redux/slice/video";
import { highlightOrArchivedType, UserSuggestionType } from "src/types/types";
import styles from "./video.module.css";
import { setVideoList as setHomeVideoList } from "src/redux/slice/video";
import { RootState } from "src/redux/store";
import {
  addToPrivate,
  deleteShareWithMeVideo,
  deleteVideo,
  editVideo,
  getAllHighlightOrArchiveVideoHandler,
  getEditVideoDetails,
  recycleVideo,
} from "src/api/video";
import SaveVideo from "src/components/header/component/saveVideo";
import { getAllPlaylistOrVideoHandler, getBinPlaylist } from "src/api/playlist";
import { setBinPlaylist, setMyPlaylists } from "src/redux/slice/playlist";
import { useSortable } from "@dnd-kit/sortable";
import IMAGES from "src/assets/image";
import { SaveVideoInput } from "src/api/types";
import { PrivacyTypeEnum, VideoType } from "src/utils/constants/enums";
import { getEditVideoDetailUserResponse } from "src/api/transform/video";
import { useWindowSize } from "usehooks-ts";
import { Avatar, Box, Stack } from "@mui/material";

export interface IVideoComponent extends Video { }
interface IVideoComponentInterface extends IVideoComponent {
  handleDebouncedMouseEnter: ((id: string) => void) & {
    clear(): void;
  } & {
    flush(): void;
  };
  setVideoList?: any;
  action?: string;
  allVideos?: Video[];
  videoUrl: string;
  activeId: string | null;
  isProviderDelete: boolean;
  users?: getEditVideoDetailUserResponse[];
  setIsStartSequence: (value: boolean) => void;
}
function VideoComponent({
  id,
  thumbnail,
  source,
  userImage,
  title,
  description,
  isHighlighted,
  handleDebouncedMouseEnter,
  setVideoList,
  action,
  allVideos,
  gifImage,
  videoUrl,
  providerName,
  activeId,
  isProviderDelete,
  users,
  setIsStartSequence,
  ownerImage,
}: IVideoComponentInterface) {
  const [isUserList, setIsUserList] = useState(false);
  const [errorImage, setErrorImage] = useState("");
  const [customVideoUrl, setCustomVideoUrl] = useState("");
  const { width, height } = useWindowSize();
  const currentUser = useAppSelector(
    (state: RootState) => state.user.currentUser
  );
  const {
    playlist: { userPlaylist, BinPlaylist },
  } = useAppSelector((state: RootState) => state);
  const dispatch = useAppDispatch();
  const [addSearchUserByEmailUsers, setAddSearchUserByEmailUsers] = useState<
    UserSuggestionType[]
  >([]);
  const [isShowTime, setIsShowTime] = useState(false);
  const [isVideoDelete, setIsVideoDelete] = useState(false);
  const [isVideoModalShow, setIsVideoModalShow] = useState(false);
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  const { videoList } = useAppSelector((state: RootState) => state.video);
  const [videoId, setVideoId] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
      disabled: action === "public" || width <= 700 ? true : false,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  const handleHover = (id: string) => {
    if (gifImage) {
      handleDebouncedMouseEnter(id);
      setHovered(true);
    }
  };
  const editVideoHandler = useCallback(
    async (newPayload: any) => {
      if (BinPlaylist[0]._id === newPayload.playlistId) {
        delete newPayload.playlistId;
      }
      let tempUsers: string[] = [];
      addSearchUserByEmailUsers.forEach((item) => {
        tempUsers.push(item.id);
      });

      const payload: SaveVideoInput = {
        ...newPayload,
        users: tempUsers,
        videoId: id,
      };
      if (!payload.playlistId) {
        payload.playlistId = userPlaylist[0].id;
      }
      const res = await editVideo(payload);
      if (res.remote === "success") {
        if (
          action === "highlight" ||
          action === "private" ||
          action === "myplaylist" ||
          action === "BinPlaylist"
        ) {
          if (allVideos) {
            const vList = [...allVideos];
            const newData = vList.filter((item) => {
              return item.id !== id;
            });
            setVideoList(newData);
          }
        } else {
          const vList = [...videoList];
          const newData = vList.filter((item) => {
            return item.id !== id;
          });
          dispatch(setHomeVideoList(newData));
        }
      }
    },
    [
      BinPlaylist,
      action,
      addSearchUserByEmailUsers,
      allVideos,
      dispatch,
      id,
      setVideoList,
      userPlaylist,
      videoList,
    ]
  );
  const getEditVideoDetailsHandler = useCallback(
    async (id: string) => {
      const data = await getEditVideoDetails(id);

      if (data.remote === "success") {
        const newUsers: getEditVideoDetailUserResponse[] = [];
        data.data.users?.filter((item) => {
          if (item.id) {
            if (item.profilePic) {
              item.profilePic = item.profilePic
                ? `${process.env.REACT_APP_BACKEND_URL_PROFILE_URL}${item.profilePic}`
                : IMAGES.placeholderAvatar;
            }
            newUsers.push(item);
          }
          return item;
        });
        setAddSearchUserByEmailUsers(newUsers);
        if (data.data) {
          const payload = {
            authorName: "",
            customThumbnail: "",
            description: "",
            isPrivate: action === "private" ? true : false,
            playlistId: data.data.playlistId,
            privacyType: data.data.videoPrivacyType || PrivacyTypeEnum.onlyMe,
            providerName: "",
            tag: [],
            thumbnailUrl: data.data.customThumbnail
              ? `${process.env.REACT_APP_BACKEND_URL_PROFILE_URL}${data.data.customThumbnail}`
              : data.data.thumbnailUrl
                ? data.data.thumbnailUrl
                : "",
            type: VideoType.video,
            users: newUsers,
            videoTitle: data.data.videoTitle,
            videoUrl: "",
            isOldThumBnail: false,
            originalThumbnail: data.data.thumbnailUrl || "",
          };

          await editVideoHandler(payload);
        }
      }
    },
    [action, editVideoHandler]
  );

  const getAllPlaylistOrVideo = useCallback(async () => {
    const data = await getAllPlaylistOrVideoHandler();
    if (data.remote === "success") {
      dispatch(setMyPlaylists(data.data));
    }
  }, [dispatch]);

  const addToArchived = async () => {
    const payload: highlightOrArchivedType = {
      videoId: id,
      privacyType: "highlight",
    };
    const data = await addToArchiveHandler(payload);
    if (data.remote === "success") {
      dispatch(setFavorite(true));
      try {
        setVideoList((pre: any[]) => {
          const newData = pre.map((item: Video) => {
            const newObj = { ...item };
            if (newObj.id === id) {
              newObj.isHighlighted = true;
            }
            return newObj;
          });
          return newData;
        });
      } catch (error) {
        const vList = [...videoList];
        const newData = vList.map((item) => {
          const newObj = { ...item };
          if (item.id === id) {
            newObj.isHighlighted = true;
          }
          return newObj;
        });
        dispatch(setHomeVideoList([...newData]));
      }
      message.success("Success");
    } else {
      message.error("Internal server error");
    }
  };

  const addToPrivateHandler = async (id: string) => {
    const data = await addToPrivate(id);
    if (data.remote === "success") {
      if (action === "highlight") {
        if (allVideos) {
          const vList = [...allVideos];
          const newData = vList.filter((item) => {
            return item.id !== id;
          });
          setVideoList(newData);
        }
      } else {
        const vList = [...videoList];
        const newData = vList.filter((item) => {
          return item.id !== id;
        });
        dispatch(setHomeVideoList(newData));
      }
    } else {
      message.error("Internal server error");
    }
  };

  const recycleVideoHandler = async (id: string) => {
    const data = await recycleVideo(id);
    if (data.remote === "success") {
      try {
        if (
          action === "highlight" ||
          action === "private" ||
          action === "myplaylist" ||
          action === "BinPlaylist"
        ) {
          if (allVideos) {
            const vList = [...allVideos];
            const newData = vList.filter((item) => {
              return item.id !== id;
            });
            setVideoList(newData);
          }
        } else {
          const vList = [...videoList];
          const newData = vList.filter((item) => {
            return item.id !== id;
          });
          dispatch(setHomeVideoList(newData));
        }
      } catch (error) {
        console.log({ error });
      }
    } else {
      message.error("Internal server error");
    }
    await getAllPlaylistOrVideo();
    setIsLoading(false);
  };

  const handleDeleteVideo = async (id: string) => {
    setIsLoading(true);
    const data = await deleteVideo(videoId);
    if (data.remote === "success") {
      try {
        await getBinPlaylistHandler();
        if (
          action === "highlight" ||
          action === "private" ||
          action === "myplaylist" ||
          action === "BinPlaylist"
        ) {
          if (allVideos) {
            const vList = [...allVideos];
            const newData = vList.filter((item) => {
              return item.id !== id;
            });
            setVideoList(newData);
          }
        } else {
          const vList = [...videoList];
          const newData = vList.filter((item) => {
            return item.id !== id;
          });
          dispatch(setHomeVideoList(newData));
        }
      } catch (error) {
        console.log({ error });
      }
    } else {
      message.error("Internal server error");
    }
    await getAllPlaylistOrVideo();
    setIsLoading(false);
  };

  const getBinPlaylistHandler = useCallback(async () => {
    const data = await getBinPlaylist();
    if (data.remote === "success") {
      dispatch(setBinPlaylist(data.data));
    }
  }, [dispatch]);
  const showDeleteModal = (id: string) => {
    setVideoId(id);
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleOk = async () => {
    setIsLoading(true);
    if (action === "ShareWithMe") {
      await handleDeleteShareWithMeVideo(videoId);
      setIsLoading(false);
      setOpen(false);
      return;
    }
    if (action === "BinPlaylist") {
      await handleDeleteVideo(videoId);
    } else {
      await recycleVideoHandler(videoId);
      await getBinPlaylistHandler();
    }
    setIsLoading(false);
    setOpen(false);
  };
  const getAllVideosHandler = useCallback(async () => {
    const data = await getAllHighlightOrArchiveVideoHandler({
      filter: "highlight",
      search: "",
      days: 0,
    });
    if (data.remote === "success") {
      if (data.data.length) {
        dispatch(setFavorite(true));
      }
      if (!data.data.length) {
        dispatch(setFavorite(false));
      }
    }
  }, [dispatch]);
  const handleDeleteArchiveOrHightLight = async (id: string) => {
    const data = await deleteArchiveOrHightLight(id);
    if (data.remote === "success") {
      await getAllVideosHandler();
      try {
        if (action === "highlight") {
          setVideoList((pre: any[]) => {
            const newData = pre.filter((item: Video) => {
              return item.id !== id;
            });
            return newData;
          });
        } else {
          setVideoList((pre: any[]) => {
            const newData = pre.map((item: Video) => {
              const newObj = { ...item };
              if (newObj.id === id) {
                newObj.isHighlighted = false;
              }
              return newObj;
            });
            return newData;
          });
        }
      } catch (error) {
        const vList = [...videoList];
        const newData = vList.map((item) => {
          const newObj = { ...item };
          if (item.id === id) {
            newObj.isHighlighted = false;
          }
          return newObj;
        });
        dispatch(setHomeVideoList([...newData]));
      }
    } else {
      message.error("Internal server error");
    }
  };

  const handleCloseModel = () => {
    setIsVideoModalShow(false);
  };

  const checkIsVideoDeleted = () => {
    if (isProviderDelete) {
      message.error(
        "Sorry, We can't play this video because this video is deleted from the provider"
      );
    }
  };
  const handleRecycle = async () => {
    setIsLoading(true);
    await getEditVideoDetailsHandler(videoId);
    await getBinPlaylistHandler();
    await getAllPlaylistOrVideo();
    setOpen(false);
    setIsVideoDelete(false);
    setIsLoading(false);
  };
  const handleDeleteShareWithMeVideo = async (id: string) => {
    const data = await deleteShareWithMeVideo(id);
    if (data.remote === "success") {
      setVideoList((pre: any[]) => {
        const newData = pre.filter((item: Video) => {
          return item.id !== id;
        });
        return newData;
      });
    } else {
      message.error("something went wrong");
    }
  };
  useEffect(() => {
    if (hovered) {
      const interval = setInterval(() => {
        setIsShowTime(true);
      }, 6000);
      return () => clearInterval(interval);
    }
  }, [hovered]);
  function checkImageURL(url: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => reject();
      img.src = url;
    });
  }

  return (
    <div className={``}>
      <Modal
        title="Video"
        open={open}
        onOk={
          action === "BinPlaylist" && isVideoDelete ? handleRecycle : handleOk
        }
        okButtonProps={{
          style: { backgroundColor: "red", color: "white" },
          children: "Yes",
        }}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <p>
          {action === "BinPlaylist" && isVideoDelete
            ? "Do you want to restore this video ?"
            : "Do you want to delete this video ?"}{" "}
        </p>
      </Modal>
      <Card
        className={styles.video_card}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        style={style}
      >
        <div
          className={styles.thumbnail_img}
          onMouseLeave={() => {
            setHovered(false);
            setIsShowTime(false);
            setIsMediaLoaded(false);
          }}
        >
          <span onMouseEnter={() => handleHover(id)}>
            {isProviderDelete ? (
              <img
                onClick={checkIsVideoDeleted}
                src={IMAGES.deleteImage}
                alt="gif"
                className={styles.thumbnail}
              />
            ) : (
              <>
                <Link
                  to={`/video/${id}?action=${action}`}
                  target="_blank"
                  onClick={() => {
                    setIsStartSequence(true);
                  }}
                >
                  {hovered ? (
                    <>
                      {gifImage?.includes("gif") ? (
                        <img
                          src={gifImage}
                          alt="gif"
                          className={styles.thumbnail}
                        />
                      ) : (
                        <>
                          <span>
                            <>
                              <img
                                src={thumbnail}
                                alt="img"
                                onError={(event) => {
                                  //@ts-ignore
                                  event.target.src = IMAGES.noThumbnail;
                                }}
                                className={styles.thumbnail}
                                style={{
                                  pointerEvents: "none",
                                  display:
                                    isMediaLoaded && providerName !== ""
                                      ? "none"
                                      : "block",
                                }}
                              />
                            </>
                            <div
                              style={{
                                pointerEvents: "none",
                                display:
                                  isMediaLoaded && providerName !== ""
                                    ? "block"
                                    : "none",
                              }}
                            >
                              {customVideoUrl ? (
                                <>
                                  <video
                                    style={{ backgroundColor: "#000" }}
                                    className={styles.short_card_view}
                                    autoPlay={true}
                                    id="customVideoPlayer"
                                    width="100%"
                                    height="100%"
                                    onLoadedMetadata={() => {
                                      // @ts-ignore
                                      customVideoRef.current.volume = 0;
                                      setIsMediaLoaded(true);
                                    }}
                                    onEnded={() => {
                                      setIsMediaLoaded(false);
                                    }}
                                  >
                                    <source src={customVideoUrl} />
                                  </video>
                                </>
                              ) : (
                                <>
                                
                                  <ReactPlayer
                                    url={videoUrl}
                                    id="customVideoPlayer"
                                    width="100%"
                                    config={{
                                      youtube: {
                                        playerVars: {
                                          showinfo: 1,
                                        },
                                      },
                                      facebook: {
                                        appId:
                                          process.env.REACT_APP_FACEBOOK_APP_ID,
                                        playerId: (Math.floor(Math.random() * 900000000) + 100000000).toString(),
                                        attributes: {
                                          "data-width": "500",
                                          "data-height": "350",
                                        },
                                      },
                                    }}
                                    height={180}
                                    volume={0}
                                    playing={true}
                                    onPlay={() => {
                                      setIsMediaLoaded(true);
                                    }}
                                    onEnded={() => {
                                      setIsMediaLoaded(false);
                                    }}
                                    controls={false}
                                    style={{
                                      width: "100%",
                                      // height: "auto",
                                      // paddingBottom: "56.25%", // 16:9 aspect ratio
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <img
                        // className="thumbnail"
                        id="thumb"
                        onError={(event) => {
                          //@ts-ignore
                          event.target.src = IMAGES.noThumbnail;
                        }}
                        src={thumbnail}
                        alt="thumb"
                        className={styles.thumbnail}
                      />
                    </>
                  )}
                </Link>
              </>
            )}
          </span>
        </div>
        {hovered ? (
          <></>
        ) : (
          <div className={styles.time_div}>
            {/* <span className={styles.time}>8:26</span> */}
          </div>
        )}
        <div className={styles.source_img}>
          <img src={source} alt="daily" className={styles.source} />
        </div>

        <Card.Body className={styles.video_padding_body}>
          <div className={`d-flex justify-content-between`}>
            <div className={`d-flex`}>
              <img
                src={
                  ownerImage
                    ? ownerImage
                    : currentUser?.profilePic
                      ? currentUser?.profilePic
                      : userImage
                }
                alt="img"
                className={styles.image}
              />
              {/* <Link to={`/video/${id}`} style={{ textDecoration: "none" }}> */}
              <div>
                <Card.Text
                  className={`${styles.textlimit} ${styles.card_text}`}
                >
                  {title}
                </Card.Text>
                <Card.Text
                  className={styles.description}
                  style={{ margin: "0px", textAlign: "left" }}
                >
                  {action === "ShareWithMe" ? (
                    <>
                      share with you,
                      <small>{users ? users[0]?.name : ""}</small>
                      <small>{users ? users[1]?.name : ""}</small>
                      <small>{users ? users[2]?.name : ""}</small>
                      {users?.length && users.length > 3 ? (
                        <span onClick={() => setIsUserList(true)}>
                          {" "}
                          ,{" "}
                          <small
                            style={{
                              color: "red",
                            }}
                          >
                            and more...
                          </small>
                        </span>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Card.Text>
              </div>
              {/* </Link> */}
            </div>
            <div>
              {isHighlighted ? (
                <>
                  {action === "ShareWithMe" || action === "public" ? (
                    <></>
                  ) : (
                    <>
                      <span className={styles.heart_threedots}>
                        {action === "BinPlaylist" || action === "private" ? (
                          <></>
                        ) : (
                          <>
                            {" "}
                            <SVG.FillHeart
                              onClick={() => {
                                handleDeleteArchiveOrHightLight(id);
                              }}
                            />
                          </>
                        )}

                        <Dropdown className={``}>
                          <Dropdown.Toggle
                            variant="outline"
                            id="dropdown-basic"
                            className={`${styles.threedots_icon_dropdown}`}
                          >
                            <SVG.ThreeDots className={styles.threedots_icon} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className={`${styles.video_dropdown}`}>
                            <Dropdown.Item
                              onClick={() => {
                                if (action === "BinPlaylist") {
                                  setOpen(true);
                                  setIsVideoDelete(true);
                                } else {
                                  setIsVideoModalShow(true);
                                }
                                setVideoId(id);
                              }}
                            >
                              {action === "BinPlaylist" ? "Restore" : "Edit"}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                showDeleteModal(id);
                              }}
                            >
                              Delete
                            </Dropdown.Item>
                            {action === "private" ||
                              action === "public" ||
                              action === "BinPlaylist" ? (
                              <></>
                            ) : (
                              <Dropdown.Item
                                onClick={() => {
                                  addToPrivateHandler(id);
                                }}
                              >
                                Mark Private
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </span>
                    </>
                  )}
                </>
              ) : (
                <>
                  {action === "public" ? (
                    <></>
                  ) : (
                    <>
                      <span className={styles.heart_threedots}>
                        {action === "ShareWithMe" ||
                          action === "BinPlaylist" ||
                          action === "private" ? (
                          <></>
                        ) : (
                          <>
                            {" "}
                            <SVG.Heart onClick={addToArchived} />
                          </>
                        )}

                        <Dropdown className={``}>
                          <Dropdown.Toggle
                            variant="outline"
                            id="dropdown-basic"
                            className={`${styles.threedots_icon_dropdown}`}
                          >
                            <SVG.ThreeDots className={styles.threedots_icon} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className={`${styles.video_dropdown}`}>
                            {action === "ShareWithMe" ? (
                              <></>
                            ) : (
                              <Dropdown.Item
                                onClick={() => {
                                  if (action === "BinPlaylist") {
                                    setOpen(true);
                                    setIsVideoDelete(true);
                                  } else {
                                    setIsVideoModalShow(true);
                                  }
                                  setVideoId(id);
                                }}
                              >
                                {action === "BinPlaylist" ? "Restore" : "Edit"}
                              </Dropdown.Item>
                            )}

                            <Dropdown.Item
                              onClick={() => {
                                showDeleteModal(id);
                              }}
                            >
                              {action === "ShareWithMe" ? "Remove" : "Delete"}
                            </Dropdown.Item>
                            {action === "private" ||
                              action === "ShareWithMe" ||
                              action === "BinPlaylist" ? (
                              <></>
                            ) : (
                              <Dropdown.Item
                                onClick={() => {
                                  addToPrivateHandler(id);
                                }}
                              >
                                Mark Private
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <Card.Text className={styles.description}>{description}</Card.Text>
        </Card.Body>
      </Card>
      <SaveVideo
        setIsStartSequence={setIsStartSequence}
        setAllVideos={setVideoList}
        allVideos={allVideos}
        show={isVideoModalShow}
        handleCloseModel={handleCloseModel}
        id={videoId}
        action={action}
      />
      <Modal
        title="User List"
        centered
        open={isUserList}
        onOk={() => setIsUserList(false)}
        onCancel={() => setIsUserList(false)}
        okText="Yes"
        okButtonProps={{
          style: { backgroundColor: "red", color: "white" },
        }}
        bodyStyle={{
          borderTop: "1px solid #ccc",
          marginTop: "15px",
          paddingTop: "15px",
        }}
      >
        <SimpleBar style={{ maxHeight: 300 }}>
          {users?.map((item) => {
            if (!item.id) {
              return;
            }
            const sharedUserProfilePic = `${process.env.REACT_APP_BACKEND_URL}${item.profilePic}`;
            return (
              <Stack
                direction={"column"}
                spacing={2}
                sx={{ mb: 2, fontSize: "18px" }}
              >
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <Avatar src={sharedUserProfilePic} />{" "}
                  <Box component={"span"}>{item.name}</Box>
                </Stack>
              </Stack>
            );
          })}
        </SimpleBar>
      </Modal>
    </div>
  );
}

export default VideoComponent;
