import React from "react";
import { Form } from "react-bootstrap";
import IMAGES from "src/assets/image";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import { AddPeopleUserType, UserSuggestionType } from "src/types/types";
import { Input } from "../input";
import styles from "./styles.module.css";
interface IAddPeople {
  userList: UserSuggestionType[];
  searchUserList?: AddPeopleUserType[];
  onChange?: (data: React.ChangeEvent<any>) => void;
  handleDelete?: (data: string) => void;
  onClick?: (data: any) => void;
}
function AddPeople({
  onClick,
  userList,
  handleDelete,
  onChange,
  searchUserList,
}: IAddPeople) {
  const { currentUser } = useAppSelector((state: RootState) => state.user);
  return (
    <>
      <Form.Group className="mb-3">
        <Input
          title="Add friend"
          // placeholder="Write email here"
          className={styles.create_input}
          onChange={(e) => {
            if (onChange) onChange(e);
          }}
        />
      </Form.Group>
      <div className="position-relative">
        {searchUserList?.length ? (
          <>
            <div className={styles.friend_list}>
              {searchUserList?.map((item) => {
                if (currentUser.email === item.label) {
                  return "";
                }

                return (
                  <>
                    <Form.Group
                      className=""
                      onClick={() => {
                        if (onClick) onClick(item);
                      }}
                    >
                      <small>{item.label || item.name}</small>
                    </Form.Group>
                  </>
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className={`mb-4`}>
        <span className={styles.sub_text}>People with access</span>
        <div className={styles.people_access_list}>
          {userList.map((user) => {
            return (
              <div className={styles.create_user_div}>
                <div className="d-flex align-items-center">
                  <img
                    src={
                      user.profilePic === ""
                        ? IMAGES.placeholderAvatar
                        : user.profilePic
                    }
                    alt="img"
                    className={styles.create_avatar_img}
                  />
                  <p className={styles.create_user}>{user.name}</p>
                </div>
                <span
                  className={styles.close_icn}
                  onClick={() => {
                    if (handleDelete) handleDelete(user.id);
                  }}
                >
                  x
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default AddPeople;
