import React from "react";
// import PrivacyPolicy from "src/pages/privacyPolicy/privacyPolicy";
// import PlayList from "src/pages/myPlayList/playList";
const Home = React.lazy(() => import("src/pages/home"));

const Highlights = React.lazy(() => import("src/pages/highlights"));
const ShareWithMe = React.lazy(() => import("src/pages/shareWithMe"));
const Archived = React.lazy(() => import("src/pages/archived"));
const MyPlayList = React.lazy(() => import("src/pages/myPlayList"));
const SharePlaylist = React.lazy(
  () => import("src/pages/sharePlaylist/components/index")
);
const BinPlaylist = React.lazy(() => import("src/pages/binPlaylist/index"));
const ProfilePage = React.lazy(() => import("src/pages/profilePage"));
const Logout = React.lazy(() => import("src/pages/logout"));
const Private = React.lazy(() => import("src/pages/private"));
const SendFeedBack = React.lazy(() => import("src/pages/sendFeedBack"));
const PlayList = React.lazy(() => import("src/pages/myPlayList/playList"));
const MyPlaylistVideos = React.lazy(
  () => import("../../pages/myPlaylistVideo/myplaylistvideo")
);
type ROUTE_TYPE = {
  id: string;
  path: string;
  name: string;
  component: React.LazyExoticComponent<() => JSX.Element>;
};
const routes: ReadonlyArray<ROUTE_TYPE> = [
  {
    id: "home",
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    id: "highlights",
    path: "/highlights",
    name: "Highlights",
    component: Highlights,
  },
  {
    id: "shared-with-me",
    path: "/shared-with-me",
    name: "Shared With Me",
    component: ShareWithMe,
  },
  {
    id: "archived",
    path: "/archived",
    name: "Archived",
    component: Archived,
  },
  {
    id: "private",
    path: "/private",
    name: "Private",
    component: Private,
  },
  {
    id: "my-play-list",
    path: "/my-play-list",
    name: "My Play List",
    component: MyPlayList,
  },
  {
    id: "profile",
    path: "/profile",
    name: "Profile",
    component: ProfilePage,
  },
  {
    id: "send-feedback",
    path: "/send-feedback",
    name: "Send Feedback",
    component: SendFeedBack,
  },
  {
    id: "playlist",
    path: "/playlist/:playlistId",
    name: "Playlist",
    component: PlayList,
  },
  {
    id: "logout",
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    id: "myplaylist",
    path: "/myplaylist/:playlistId",
    name: "myplaylist",
    component: MyPlaylistVideos,
  },
  {
    id: "SharePlaylist",
    path: "/SharePlaylist/:playlistId",
    name: "SharePlaylist",
    component: SharePlaylist,
  },
  {
    id: "BinPlaylist",
    path: "/binPlaylist/:playlistId",
    name: "BinPlaylist",
    component: BinPlaylist,
  },
];

export default routes;
