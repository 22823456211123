import { Playlist } from "src/redux/slice/playlist";
import { PrivacyTypeEnum } from "src/utils/constants/enums";

export type GetPlaylistResponse = {
  name: string;
  privacyType: PrivacyTypeEnum;
  _id: string;
};

export type getAllPlaylistUserType = {
  _id: string;
  name: string;
  newVideo: number;
  path: string;
};
export const transformPlaylistResponse = (
  data: GetPlaylistResponse
): Playlist => {
  return {
    name: data.name,
    privacyType: data.privacyType,
    id: data._id,
  };
};
