import React from "react";
import { Button, Form } from "react-bootstrap";
import { IIconInput } from ".";
import { RedButton } from "../button";
import styles from "./input.module.css";
import { IconButton, Stack } from "@mui/material";
import { CloseOutlined } from "@ant-design/icons";

function IconInput({
  buttonText,
  isButton,
  buttonOnClick,
  icon,
  iconClassName,
  isGlobalSearch,
  id,
  handlePaste,
  handlerClear,
  ...rest
}: IIconInput) {
  const stopPageReload = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  return (
    <Form
      className={`position-relative`}
      onSubmit={(e) => {
        stopPageReload(e);
      }}
    >
      <Form.Control
        size="lg"
        type="text"
        autoFocus
        onPaste={(e) => {
          //@ts-ignore
          console.log({ hello: e.clipboardData.getData("text") });
        }}
        placeholder={
          isGlobalSearch
            ? "search video by tag or tittle"
            : id
            ? "Title"
            : "Paste video link here"
        }
        className={styles.search_input}
        {...rest}
      />

      {isGlobalSearch ? (
        <>
          <span className={`${iconClassName}`} style={{ cursor: "pointer" }}>
            {icon}
          </span>
        </>
      ) : (
        <>
          <span
            className={`${iconClassName}`}
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (handlePaste) {
                handlePaste();
              }
            }}
          >
            {icon}
          </span>

          {isButton && (
            <>
              <Stack
                direction={"row"}
                spacing={1}
                alignItems={"center"}
                className="savebtn-new"
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    if (handlerClear) {
                      handlerClear();
                    }
                  }}
                >
                  <CloseOutlined />
                </IconButton>
                <RedButton
                  text={buttonText}
                  className={`${styles.red_button}`}
                  onClick={() => (buttonOnClick ? buttonOnClick() : "")}
                />
              </Stack>
            </>
          )}
        </>
      )}
    </Form>
  );
}

export default IconInput;
