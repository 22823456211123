import AuthorizedRouteComponent from "./authorized";
import PublicRouteComponent from "./public";
import UnauthorizedRouteComponent from "./unauthorized";

export interface ICustomRoute {
  children?: React.ReactNode;
}

export const PublicRoute = PublicRouteComponent;
export const AuthorizedRoute = AuthorizedRouteComponent;
export const UnauthorizedRoute = UnauthorizedRouteComponent;
