import { IAuthComponent } from "src/components/header/component/auth";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { RedButton } from "../../../../button";
import Modal from "../../../../modal";
import styles from ".././auth.module.css";
import { forgetPassword } from "src/api/auth";
import { message, Spin } from "antd";
const ForgetPasswordComponent = ({
  show,
  handleCloseModel,
  setCurrentSection,
  setResentEmail,
}: IAuthComponent) => {
  const [email, setEmail] = useState("");
  const [loading, setIsLoading] = useState(false);
  const forgetPasswordHandler = async (email: string) => {
    setIsLoading(true);
    const data = await forgetPassword(email);
    if (data.remote === "success") {
      if (setResentEmail) {
        setResentEmail(email);
      }
      message.success(`Your verification code is send to ${email}`);
      if (setCurrentSection) {
        setCurrentSection("oto-verification");
      }
    } else {
      message.error("Something went Wrong");
    }
    setIsLoading(false);
  };
  return (
    <>
      <div className={styles.f}>
        <Modal show={show} onClose={handleCloseModel}>
          {loading ? (
            <div className="example">
              <Spin />
            </div>
          ) : (
            <></>
          )}
          <h1 className={styles.heading}>Forgot Password</h1>
          <p className={styles.forget_title}>
            Enter your email for the verification proccess, we will send 4
            digits code to your email.
          </p>
          <Form>
            <Form.Group className={`mb-4`}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                size="lg"
                type="email"
                placeholder="mymail@gmail.com"
                className={styles.signup_form_control}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Form.Group>
          </Form>
          <RedButton
            text={"Continue"}
            className={`w-100 mb-4`}
            onClick={() => {
              forgetPasswordHandler(email);
            }}
          />
        </Modal>
      </div>
    </>
  );
};

export default ForgetPasswordComponent;
