import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import { ICustomRoute } from ".";

/**
 * @desc: If user is loggedOut then only be these component are visible
 */
function UnauthorizedRouteComponent({ children }: ICustomRoute) {
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const location = useLocation();
  if (isLoggedIn) {
    return <Navigate to={location.state?.from || "/home"} />;
  }
  return <>{children}</>;
}

export default UnauthorizedRouteComponent;
