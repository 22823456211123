import React, { useCallback, useEffect, useState } from "react";
import styles from "./saveVideo.module.css";
import { IAuthComponent } from "../auth";
import SVG from "src/assets/svg";
import { RedButton } from "src/components/button";
import { Spin } from "antd";
import Modal from "src/components/modal";
import { Checkbox, IconInput, Select } from "src/components/input";
import { useParams } from "react-router-dom";
import { AvailablePrivacyList } from "src/utils/constants/constants";
import TagInput from "src/components/input/inputTag";
import {
  addToPrivate,
  editVideo,
  getAllTagHandler,
  getEditVideoDetails,
  getFormate,
  getLinkDetails,
  saveVideos,
} from "../../../../api/video";
import { SaveVideoInput } from "src/api/types";
import { PrivacyTypeEnum, VideoType } from "src/utils/constants/enums";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import { setVideoList } from "src/redux/slice/video";
import AddPeople from "src/components/addPeople";
import { searchUserByEmail } from "src/api/auth";
import {
  AddPeopleUserType,
  SuggestionType,
  UserSuggestionType,
} from "src/types/types";
import { message } from "antd";
import IMAGES from "src/assets/image";
import { getEditVideoDetailUserResponse } from "src/api/transform/video";
import { setBinPlaylist, setMyPlaylists } from "src/redux/slice/playlist";
import { getAllPlaylistOrVideoHandler, getBinPlaylist } from "src/api/playlist";

const SaveVideo = ({
  show,
  handleCloseModel,
  id,
  allVideos,
  setAllVideos,
  action,
  setSearchLink,
  searchLink,
  setIsGetDetails,
  isGetDetails,
  setIsStartSequence,
}: IAuthComponent) => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const {
    playlist: { userPlaylist, MyPlaylists },
    video: { videoList },
  } = useAppSelector((state: RootState) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [bufferFile, setBufferFile] = useState("");
  const [isOldThumbnail, setIsOldThumbnail] = useState(false);
  const [tagSuggestion, setTagSuggestion] = useState<SuggestionType[]>([
    {
      label: "",
      value: "",
    },
  ]);
  const [videoDetails, setVideoDetails] = useState<SaveVideoInput>({
    authorName: "",
    customThumbnail: "",
    description: "",
    isPrivate: false,
    playlistId: "",
    privacyType: PrivacyTypeEnum.onlyMe,
    providerName: "",
    tag: [],
    thumbnailUrl: "",
    type: VideoType.video,
    users: [],
    videoTitle: "",
    videoUrl: "",
    isOldThumBnail: false,
    originalThumbnail: "",
  });
  const [, setDeleteUserPlaylistId] = useState("");
  const [searchUserByEmailUsers, setSearchUserByEmailUsers] = useState<
    AddPeopleUserType[]
  >([]);
  const [addSearchUserByEmailUsers, setAddSearchUserByEmailUsers] = useState<
    UserSuggestionType[]
  >([]);
  const uploadFile = () => {
    const input: HTMLElement | null = document.getElementById("fileInput");
    if (input) {
      input.click();
    } else {
      message.error("someThing Went Wrong");
    }
  };

  const getVideoDetailsByUrl = useCallback(
    async (videoLink: string) => {
      console.log("step-1");
      setIsLoading(true);
      const res = await getLinkDetails(videoLink);
      console.log("step-2", res);
      if (res.remote === "success") {
        console.log("step-3");
        const formateData = await getFormate();
        console.log("step-4", formateData);
        if (formateData.remote === "success") {
          console.log("step-5");
          const isAvailable = formateData.data.find((item) => {
            console.log("step-6");
            return res.data.providerName
              .toLowerCase()
              .includes(item.name.toLocaleLowerCase());
          });

          if (isAvailable?.isDeleted === true) {
            console.log("step-7");

            message.error(
              "Sorry, we cannot retrieve details from the provided URL. Please try a different URL."
            );
            setIsLoading(false);
            handleCloseModel();
            return;
          }
          console.log({ isAvailable });
        }

        const data = res.data;
        setVideoDetails((prevState) => {
          return {
            ...prevState,
            authorName: data.authorName,
            videoTitle: data.videoTitle,
            videoUrl: data.videoUrl,
            thumbnailUrl: data.thumbnailUrl,
            providerName: data.providerName,
          };
        });
      } else {
        message.error(
          "Sorry, we cannot retrieve details from the provided URL. Please try a different URL."
        );
        handleCloseModel();
      }
      setIsLoading(false);
    },
    [handleCloseModel]
  );

  const handleChange =
    (name: string) =>
    (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      if (name !== "isPrivate") {
        setVideoDetails((prevState) => ({
          ...prevState,
          [name]: e.target.value,
        }));
      } else {
        setVideoDetails((prevState) => ({
          ...prevState,
          [name]: !prevState.isPrivate,
        }));
      }
    };

  const handleChangeTittle = (value: string, type: string) => {
    setVideoDetails((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };

  async function urlToFileObject(url: string): Promise<File> {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], "filename"); // Replace 'filename' with the desired file name
  }


  const saveVideoHandler = async () => {
    let newFile;
    const isValid = addValidation();
    if (isValid) {
      message.error("please select Users");
      return;
    }
    if (!videoDetails.videoTitle) {
      message.error("Please enter Title");
      return;
    }
    if (
      videoDetails.customThumbnail === "" &&
      videoDetails.thumbnailUrl === ""
    ) {
      newFile = await urlToFileObject(IMAGES.noThumbnail);
      setVideoDetails({
        ...videoDetails,
        customThumbnail: newFile,
      });
      convertToBufferString(newFile)
        .then((bufferString) => {
          const imageElement = document.getElementById(
            "customThumbnail"
          ) as HTMLImageElement;
          imageElement.src = bufferString;
          setBufferFile(bufferString);
        })
        .catch((error) => console.error(error));
      // message.error("please select Thumbnail", 20);
      // return;
    }
    setIsLoading(true);
    let tempUsers: string[] = [];
    addSearchUserByEmailUsers.forEach((item) => {
      tempUsers.push(item.id);
    });
    const payload: SaveVideoInput = {
      ...videoDetails,
      users: tempUsers,
      customThumbnail: videoDetails.customThumbnail
        ? videoDetails.customThumbnail
        : newFile,
    };
    if (!payload.playlistId) {
      payload.playlistId = userPlaylist[0].id;
    }
    const res = await saveVideos(payload);

    if (res.remote === "success") {
      const NewUserPlaylist = [...MyPlaylists];
      const ModifyUserPlaylist = NewUserPlaylist.map((item) => {
        const newObj = { ...item };
        if (newObj._id === payload.playlistId) {
          let newVideoCount: number = newObj.newVideo;
          if (!newVideoCount) {
            const tempValue = 1;
            newObj.newVideo = tempValue;
          } else {
            const tempValue = Number(newVideoCount) + 1;
            newObj.newVideo = tempValue;
          }
          return newObj;
        }
        return newObj;
      });
      // if (bufferFile !== "") {
      //   res.data.thumbnail = bufferFile;
      // }
      if (res.data.thumbnail.includes("png")) {
        res.data.gifImage = bufferFile;
      } else {
        if (bufferFile !== "") {
          res.data.thumbnail = bufferFile;
        }
      }
      // res.data.thumbnail=res.data.
      res.data.videoUrl = videoDetails.videoUrl;
      dispatch(setMyPlaylists([...ModifyUserPlaylist]));
      dispatch(setVideoList([res.data, ...videoList]));
      if (payload.isPrivate && res.data.id) {
        await addToPrivateHandler(res.data.id);
      }
    } else {
      message.error("Internal server error");
    }
    handleCloseModel();
    clearState();
    if (setSearchLink) {
      setSearchLink("");
    }
    setIsLoading(false);
    setBufferFile("");
    if (setIsStartSequence) {
      setIsStartSequence(true);
    }
  };

  const searchUserByEmailHandler = async (value: string) => {
    const data = await searchUserByEmail(value);
    if (data.remote === "success") {
      let newData = [...data.data];
      addSearchUserByEmailUsers.forEach((item) => {
        newData = newData.filter((d) => item.id !== d.id);
      });
      setSearchUserByEmailUsers(
        newData.map((d) => {
          return {
            label: d.email,
            value: d.id,
            profilePic: d.profilePic,
            name: d.name,
          };
        })
      );
    }
  };

  const addPeopleHandleChange = async (e: any) => {
    searchUserByEmailHandler(e.target.value);
  };

  const handleAddPeople = async (value: any) => {
    const payload: UserSuggestionType = {
      id: value.value,
      profilePic: value.profilePic,
      name: value.name,
    };
    if (payload) {
      setAddSearchUserByEmailUsers((prevState) => {
        const newState = [...prevState, payload];
        return newState;
      });
    }
    searchUserByEmailHandler("");
  };

  const handleDeletePeople = async (value: string) => {
    setAddSearchUserByEmailUsers((prevState) => {
      const newState = prevState.filter((item) => {
        return item.id !== value;
      });
      return [...newState];
    });
    searchUserByEmailHandler("");
  };

  const getAllTag = async () => {
    const data = await getAllTagHandler();
    if (data.remote === "success") {
      setTagSuggestion(data.data);
    }
  };

  const deleteTag = (index: number) => {
    setVideoDetails((pre) => {
      const newTag = pre.tag.filter((item, ind) => {
        return ind !== index;
      });
      return {
        ...pre,
        tag: newTag,
      };
    });
  };

  const clearState = useCallback(() => {
    if (setIsGetDetails) {
      setIsGetDetails(false);
    }
    setVideoDetails({
      authorName: "",
      customThumbnail: "",
      description: "",
      isPrivate: false,
      playlistId: "",
      privacyType: PrivacyTypeEnum.onlyMe,
      providerName: "",
      tag: [],
      thumbnailUrl: "",
      type: VideoType.video,
      users: [],
      videoTitle: "",
      videoUrl: "",
      isOldThumBnail: false,
      originalThumbnail: "",
    });
    setAddSearchUserByEmailUsers([]);
  }, [setIsGetDetails]);

  const getEditVideoDetailsHandler = useCallback(
    async (id: string) => {
      setIsLoading(true);
      const data = await getEditVideoDetails(id);
      if (data.remote === "success") {
        const newUsers: getEditVideoDetailUserResponse[] = [];
        data.data.users?.filter((item) => {
          if (item.id) {
            if (item.profilePic) {
              item.profilePic = item.profilePic
                ? `${process.env.REACT_APP_BACKEND_URL_PROFILE_URL}${item.profilePic}`
                : IMAGES.placeholderAvatar;
            }
            newUsers.push(item);
          }
          return item;
        });
        if (newUsers) {
          setAddSearchUserByEmailUsers(newUsers);
        }
        if (data.data) {
          setDeleteUserPlaylistId(data.data.playlistId);
          setVideoDetails({
            authorName: "",
            customThumbnail: "",
            description: "",
            isPrivate: action === "private" ? true : false,
            playlistId: data.data.playlistId,
            privacyType: data.data.videoPrivacyType || PrivacyTypeEnum.onlyMe,
            providerName: "",
            tag: !data.data.tag[0]._id ? [] : data.data.tag,
            thumbnailUrl: data.data.customThumbnail
              ? `${process.env.REACT_APP_BACKEND_URL_PROFILE_URL}${data.data.customThumbnail}`
              : data.data.thumbnailUrl
              ? data.data.thumbnailUrl
              : "",
            type: VideoType.video,
            users: [],
            videoTitle: data.data.videoTitle,
            videoUrl: "",
            isOldThumBnail: false,
            originalThumbnail: data.data.thumbnailUrl || "",
          });
        }
      }
      if (setSearchLink) {
        setSearchLink("");
      }
      setBufferFile("");
      setIsLoading(false);
    },

    [action, setSearchLink]
  );
  const addValidation = useCallback(() => {
    let isValid = false;
    if (videoDetails.privacyType === PrivacyTypeEnum.sharedWith) {
      if (!addSearchUserByEmailUsers.length) {
        isValid = true;
        return isValid;
      }
    }
    return isValid;
  }, [addSearchUserByEmailUsers.length, videoDetails.privacyType]);

  const getBinPlaylistHandler = useCallback(async () => {
    const data = await getBinPlaylist();
    if (data.remote === "success") {
      dispatch(setBinPlaylist(data.data));
    }
  }, [dispatch]);

  const addToPrivateHandler = useCallback(
    async (id: string) => {
      const data = await addToPrivate(id);
      if (data.remote === "success") {
        if (action === "highlight" || action === "private") {
          if (allVideos) {
            const vList = [...allVideos];
            const newData = vList.filter((item) => {
              return item.id !== id;
            });
            if (setAllVideos) setAllVideos(newData);
          }
        } else {
          const vList = [...videoList];
          const newData = vList.filter((item) => {
            return item.id !== id;
          });
          dispatch(setVideoList(newData));
        }
      } else {
        message.error("Internal server error");
      }
    },
    [action, allVideos, dispatch, setAllVideos, videoList]
  );

  const getAllPlaylistOrVideo = useCallback(async () => {
    const data = await getAllPlaylistOrVideoHandler();
    if (data.remote === "success") {
      dispatch(setMyPlaylists(data.data));
    }
  }, [dispatch]);

  const editVideoHandler = useCallback(async () => {
    let newFile;
    const isValid = addValidation();
    if (!videoDetails.thumbnailUrl && !videoDetails.customThumbnail) {
      // message.error("please select thumbnail ");
      newFile = await urlToFileObject(IMAGES.noThumbnail);
      setVideoDetails({
        ...videoDetails,
        customThumbnail: newFile,
      });

      // return;
    }
    if (isValid) {
      message.error("please select Users");
      return;
    }
    if (!videoDetails.videoTitle) {
      message.error("Please enter Title", 30);
      return;
    }
    setIsLoading(true);
    let tempUsers: string[] = [];
    addSearchUserByEmailUsers.forEach((item) => {
      tempUsers.push(item.id);
    });

    const payload: SaveVideoInput = {
      ...videoDetails,
      users: tempUsers,
      videoId: id,
      customThumbnail: videoDetails.customThumbnail
        ? videoDetails.customThumbnail
        : newFile,
    };

    if (!payload.playlistId) {
      payload.playlistId = userPlaylist[0].id;
    }
    if (payload.privacyType === PrivacyTypeEnum.onlyMe) {
      payload.users = [];
    }
    const res = await editVideo(payload);

    if (res.remote === "success") {
      if (
        action === "highlight" ||
        action === "private" ||
        action === "myplaylist" ||
        action === "BinPlaylist"
      ) {
        if (allVideos?.length) {
          const vList = [...allVideos];
          const newData = vList.map((item) => {
            const newObj = { ...item };
            if (item.id === id) {
              newObj.title = payload.videoTitle;
              newObj.users = addSearchUserByEmailUsers;
              if (bufferFile !== "") {
                newObj.thumbnail = bufferFile;
                newObj.gifImage = bufferFile;
              }
              if (videoDetails.isOldThumBnail) {
                newObj.thumbnail = videoDetails.originalThumbnail;
                newObj.gifImage = videoDetails.originalThumbnail;
              }
              if (res.data.pngFilePath) {
                newObj.thumbnail =
                  process.env.REACT_APP_BACKEND_URL + res.data.pngFilePath;
                newObj.gifImage =
                  process.env.REACT_APP_BACKEND_URL + res.data.filePath;
              }
            }
            return newObj;
          });
          if (newData) {
            if (setAllVideos) setAllVideos([...newData]);
          }
          if (action === "myplaylist") {
            if (params.playlistId) {
              if (allVideos) {
                if (payload.playlistId !== params.playlistId) {
                  if (setAllVideos) {
                    //@ts-ignore
                    // if (allVideos) {
                    //   const vList = [...allVideos];
                    //   const newData = vList.filter((item) => {
                    //     return item.id !== id;
                    //   });
                    //   console.log({ newData });
                    //   setAllVideos(newData);
                    // }
                    setAllVideos((pre) => {
                      const newData = pre.filter((item: any) => {
                        return item.id !== id;
                      });
                      return newData;
                    });
                  }
                }
              }
            }
          }
        }
      } else {
        const vList = [...videoList];
        const newData = vList.map((item) => {
          const newObj = { ...item };
          if (item.id === id) {
            newObj.title = payload.videoTitle;
            newObj.users = addSearchUserByEmailUsers;
            if (bufferFile !== "") {
              newObj.thumbnail = bufferFile;
              if (res.data.pngFilePath) {
                newObj.gifImage =
                  process.env.REACT_APP_BACKEND_URL + res.data.pngFilePath;
              }
            }

            if (res.data.pngFilePath) {
              newObj.thumbnail =
                process.env.REACT_APP_BACKEND_URL + res.data.pngFilePath;
              newObj.gifImage =
                process.env.REACT_APP_BACKEND_URL + res.data.filePath;
            }
            if (videoDetails.isOldThumBnail) {
              newObj.thumbnail = videoDetails.originalThumbnail;
              newObj.gifImage = videoDetails.originalThumbnail;
              if (res.data.pngFilePath) {
                newObj.gifImage =
                  process.env.REACT_APP_BACKEND_URL + res.data.pngFilePath;
              }
            }
          }
          return newObj;
        });
        dispatch(setVideoList([...newData]));
      }

      // const NewUserPlaylist = [...MyPlaylists];
      // Currently We are comment this code just because they did,nt work fine fixed it leater
      // const ModifyUserPlaylist = NewUserPlaylist.map((item) => {
      //   const newObj = { ...item };
      //   if (
      //     newObj._id === deleteUserPlaylistId &&
      //     newObj._id !== payload.playlistId
      //   ) {
      //     let newVideoCount: number = newObj.newVideo;
      //     const tempValue = newVideoCount - 1;
      //     newObj.newVideo = tempValue;
      //     return newObj;
      //   }
      //   if (newObj._id === payload.playlistId) {
      //     let newVideoCount: number = newObj.newVideo;
      //     if (!newVideoCount) {
      //       const tempValue = 1;
      //       newObj.newVideo = tempValue;
      //     } else {
      //       if (
      //         NewUserPlaylist.length > 1 &&
      //         newObj._id !== payload.playlistId
      //       ) {
      //         const tempValue = Number(newVideoCount) + 1;
      //         newObj.newVideo = tempValue;
      //       }
      //     }
      //     return newObj;
      //   }
      //   return newObj;
      // });
      if (payload.isPrivate && id && action !== "private") {
        await addToPrivateHandler(id);
      } else {
        if (!payload.isPrivate && id && action === "private") {
          if (action === "private") {
            if (allVideos) {
              const vList = [...allVideos];
              const newData = vList.filter((item) => {
                return item.id !== id;
              });
              if (setAllVideos) setAllVideos(newData);
            }
          } else {
            const vList = [...videoList];
            const newData = vList.filter((item) => {
              return item.id !== id;
            });
            dispatch(setVideoList(newData));
          }
        }
      }
      await getBinPlaylistHandler();
      await getAllPlaylistOrVideo();
      setIsLoading(false);
      //  Currently We are comment this code just because they did,nt work fine fixed it letter
      // dispatch(setMyPlaylists([...ModifyUserPlaylist]));
    } else {
      message.error("Internal server error");
    }
    handleCloseModel();
    clearState();
  }, [
    action,
    addSearchUserByEmailUsers,
    addToPrivateHandler,
    addValidation,
    allVideos,
    bufferFile,
    clearState,
    dispatch,
    getAllPlaylistOrVideo,
    getBinPlaylistHandler,
    handleCloseModel,
    id,
    params.playlistId,
    setAllVideos,
    userPlaylist,
    videoDetails,
    videoList,
  ]);

  useEffect(() => {
    if (isGetDetails && searchLink && searchLink !== "") {
      getVideoDetailsByUrl(searchLink);
      getAllTag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchLink, isGetDetails]);

  const convertToBufferString = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise<string>((resolve, reject) => {
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
    });
  };

  // Example usage
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    setIsOldThumbnail(true);
    setVideoDetails({
      ...videoDetails,
      customThumbnail: file,
      isOldThumBnail: false,
    });
    convertToBufferString(file)
      .then((bufferString) => {
        const imageElement = document.getElementById(
          "customThumbnail"
        ) as HTMLImageElement;
        imageElement.src = bufferString;
        setBufferFile(bufferString);
      })
      .catch((error) => console.error(error));
  };

  const handleOldThumbnail = () => {
    setIsOldThumbnail(false);
    setVideoDetails({
      ...videoDetails,
      customThumbnail: "",
      isOldThumBnail: false,
    });
    const imageElement = document.getElementById(
      "customThumbnail"
    ) as HTMLImageElement;
    imageElement.src = videoDetails.thumbnailUrl;
  };
  // if user Want to add real thumbnail means video real thumbnail so this function is Work for that
  const handleActualThumbnail = () => {
    if (!videoDetails.originalThumbnail) {
      message.error("Actual thumbnail is not available for this provider");
      return;
    }
    setVideoDetails({
      ...videoDetails,
      isOldThumBnail: true,
      customThumbnail: "",
    });

    const imageElement = document.getElementById(
      "customThumbnail"
    ) as HTMLImageElement;
    imageElement.src = videoDetails.originalThumbnail;
  };

  useEffect(() => {
    if (!show) {
      clearState();
    }
  }, [clearState, show]);

  useEffect(() => {
    if (id) {
      getEditVideoDetailsHandler(id);
      getAllTag();
    }
  }, [getEditVideoDetailsHandler, id]);

  return (
    <>
      <div className="test">
        <Modal
          show={show}
          onClose={handleCloseModel}
          title={
            <h1 className={styles.add_video_heading}>
              {id ? "Edit video" : "Add Video"}
            </h1>
          }
        >
          <div>
            <IconInput
              id={id}
              isButton={false}
              icon=""
              // {<SVG.Paste className={styles.paste_icon} />}
              className={styles.search_input_search}
              iconClassName={styles.attach_icon}
              value={videoDetails.videoTitle}
              onChange={(e) => {
                handleChangeTittle(e.target.value, "videoTitle");
              }}
            />

            <div className={styles.edit_hover}>
              <img 
                onError={() => {
                  const newErrorImg =
                    document.getElementById("customThumbnail");
                  if (newErrorImg) {
                    // @ts-ignore
                    newErrorImg.src = IMAGES.noThumbnail;
                    setVideoDetails({
                      ...videoDetails,
                      customThumbnail: "",
                      thumbnailUrl: "",
                    });
                  }
                }}
                id="customThumbnail"
                src={
                  videoDetails.thumbnailUrl ||
                  videoDetails.customThumbnail ||
                  IMAGES.noThumbnail
                }
                alt="img"
                className={styles.modal_img}
              />
              {isOldThumbnail ? (
                <div className={styles.edit_thumbnail_div_remove}>
                  <span
                    className={styles.remove_thmbunail}
                    onClick={handleOldThumbnail}
                  >
                    x
                  </span>
                </div>
              ) : (
                <></>
              )}
              {!isOldThumbnail ? (
                <div className={styles.edit_thumbnail_div}>
                  <span className={styles.edit_icon_span}>
                    <>
                      <input
                        id="fileInput"
                        style={{ display: "none" }}
                        type="file"
                        accept="image/png, image/jpeg, image/Gif"
                        className={styles.edit_input}
                        onChange={async (e) => {
                          handleInputChange(e);
                        }}
                      />
                    </>
                    {/* /  */}
                    {/* <div className="text-center">
                      <SVG.Upload
                        className={styles.edit_icon}
                        onClick={uploadFile}
                      />
                      <h5 className="fw-bold mt-2">Update Thumbnail </h5>
                    </div> */}
                  </span>
                </div>
              ) : (
                <></>
              )
              }
            </div>
            {id ? (
              <>
                <span
                  className={styles.actualThumbnail}
                  onClick={handleActualThumbnail}
                >
                  ActualThumbnail
                </span>
              </>
            ) : (
              <></>
            )}
            {isLoading ? (
              <div className="example">
                <Spin />
              </div>
            ) : (
              <></>
            )}
            <Select
              onChange={handleChange("playlistId")}
              title={"Choose Playlist"}
              options={[
                // {
                //   id: "",
                //   value: "",
                //   title: "",
                // },
                ...userPlaylist.map((playlist) => ({
                  id: playlist.id,
                  value: playlist.id,
                  title: playlist.name,
                  privacyType: playlist.privacyType,
                })),
              ]}
              value={videoDetails.playlistId}
            />
            <Select
              title={"Who can see"}
              options={AvailablePrivacyList}
              onChange={handleChange("privacyType")}
              value={videoDetails.privacyType}
            />
            {videoDetails.privacyType === PrivacyTypeEnum.sharedWith ||
            videoDetails.privacyType === PrivacyTypeEnum.public ? (
              <AddPeople
                userList={addSearchUserByEmailUsers}
                onChange={addPeopleHandleChange}
                searchUserList={searchUserByEmailUsers}
                onClick={handleAddPeople}
                handleDelete={handleDeletePeople}
              />
            ) : (
              ""
            )}

            <TagInput
              title="Tags"
              onAdd={(e) => {
                const newTag = {
                  _id: e.value as string,
                  name: e.label,
                };
                setVideoDetails((prevState) => {
                  const tags = [...(prevState?.tag || []), newTag];
                  return {
                    ...prevState,
                    tag: tags,
                  };
                });
              }}
              onDelete={(e: number) => {
                deleteTag(e);
              }}
              selected={videoDetails?.tag?.map((tag) => ({
                value: tag._id || tag.name,
                label: tag.name,
              }))}
              allowNew
              suggestions={tagSuggestion}
            />
            <Checkbox
              onChange={handleChange("isPrivate")}
              name="Save into a Private folder"
              checked={videoDetails.isPrivate}
            />

            <RedButton
              text={id ? "Update Video" : "Save Video"}
              className={`w-100`}
              onClick={id ? editVideoHandler : saveVideoHandler}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default SaveVideo;
