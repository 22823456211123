import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Grid, Stack, SvgIcon } from "@mui/material";
import styles from "./video.module.css";
import VideoHeader from "./VideoHeader";
import { useLocation, useParams } from "react-router-dom";

import {
  downloadVideo,
  facebookVideoDetail,
  getSequences,
  getVideoDetailsById,
} from "src/api/video";
import { Video } from "src/redux/slice/video";
import ReactPlayer from "react-player";
import IMAGES from "../../assets/image/index";
import { Spin } from "antd";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import { videoSequenceObj } from "src/api/types";
import SVG from "src/assets/svg";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
type VideoHightOrWidth = {
  width: number;
  height: number;
};
const VideoComponent = () => {
  const handle = useFullScreenHandle();
  const [ispadding, setIspadding] = useState(false);
  const [isMuteButtonShow, setIsMuteButtonShow] = useState(false);
  const [stopLoading, setStopLoading] = useState(false);
  const location = useLocation();
  const [showCurrentTime, setShowCurrentTime] = useState(0);
  const customKeyBoardControllerRef = useRef(null);
  const [, setTotalTime] = useState(0);
  const [, setRemainingTime] = useState(0);
  const [remainingMinute, setRemainingMinute] = useState(0);
  const [remainingSecond, setRemainingSecond] = useState(0);
  const [customVideoUrl, setCustomVideoUrl] = useState("");
  const [onPlayPause, setOnPlayPause] = useState(false);
  const [isFullScrenn, setIsFullScrenn] = useState(false);
  const [played, setPlayed] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [totalDuration, setTotalDuration] = useState(0);
  const [currentSecond, setCurrentSecond] = useState(0);
  const [volume, setVolume] = useState(0.5);
  const playerRef = useRef(null);
  const [customVideoPlaying, setCustomVideoPlaying] = useState(false);
  const isLoggedIn: boolean = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const params = useParams();
  const navigate = useNavigate();
  const customVideoRef = useRef(null);
  const CustomPlayerRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [id, setId] = useState("");
  const [videoList, setVideoList] = useState<string[]>([]);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [isNextVideo, setIsNextVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [, setCurrentMinute] = useState(0);
  const [videoHeightOrWidth, setVideoHeightOrWidth] =
    useState<VideoHightOrWidth>({
      width: 0,
      height: 0,
    });
  const [videoDetails, setVideoDetails] = useState<Video>({
    id: "",
    thumbnail: "",
    source: "",
    userImage: "",
    title: "",
    description: "",
    isHighlighted: false,
    videoUrl: "",
    isProviderDelete: false,
  });

  const handleTimeUpdate = () => {
    //@ts-ignore
    const time = customVideoRef.current.currentTime;
    setCurrentMinute(Math.floor(time / 60));
    // setCurrentTime(time);
    // setCurrentMinute(Math.floor(time / 60));
    setCurrentSecond(Math.floor(time % 60));
    //@ts-ignore
    setShowCurrentTime(Math.floor(time / 60));
    //@ts-ignore
    setTotalTime(Math.floor(customVideoRef.loadedSeconds % 60));
    //@ts-ignore
    const duration = customVideoRef.current.duration;
    //@ts-ignore
    const remaining = duration - time;
    setRemainingTime(remaining);
    setRemainingMinute(Math.floor(remaining / 60));
    setRemainingSecond(Math.floor(remaining % 60));
    //@ts-ignore
    setCurrentTime(customVideoRef.current.currentTime);
  };

  //@ts-ignore
  const handleTimeRangeChange = (event) => {
    //@ts-ignore
    const newTime = parseFloat(event.target.value);
    //@ts-ignore
    customVideoRef.current.currentTime = newTime;
    //@ts-ignore
    setCurrentTime(newTime);
  };
  const getFacebookVideo = async (url: string, action?: string) => {
    setIsLoading(true);
    const data = await facebookVideoDetail(url);
    if (data.remote === "success") {
      if (action === "xvideos") {
        //@ts-ignore
        setCustomVideoUrl(data.data);
        return;
      }

      if (action === "Twitter") {
        //@ts-ignore
        setCustomVideoUrl(data.data.download[0].url);

        return;
      }
      if (action === "Tiktok") {
        console.log({ data });
        //@ts-ignore
        setCustomVideoUrl(data.data[0].hd);
        return;
      }
      // @ts-ignore
      setCustomVideoUrl(data.data.medias[0].url);
    }
  };
  const getVideoDetails = async (videoId: string) => {
    const res = await getVideoDetailsById(videoId);
    if (res.remote === "success") {
      setVideoDetails(res.data);
    }
  };

  const handleProgress = (state: any) => {
    const time = state.playedSeconds;
    setCurrentMinute(Math.floor(time / 60));
    // setCurrentTime(time);
    // setCurrentMinute(Math.floor(time / 60));
    setCurrentSecond(Math.floor(time % 60));
    //@ts-ignore
    setShowCurrentTime(Math.floor(time / 60));
    setTotalTime(Math.floor(state.loadedSeconds % 60));
    // const duration = state.duration;
    const remaining = totalDuration - time;
    setRemainingTime(remaining);
    setRemainingMinute(Math.floor(remaining / 60));
    setRemainingSecond(Math.floor(remaining % 60));
    if (!seeking) {
      setPlayed(state.played);
    }
  };

  const handleSeekStart = () => {
    setSeeking(true);
  };

  const handleSeekEnd = (time: number) => {
    //@ts-ignore
    playerRef.current.seekTo(time);
    setSeeking(false);
  };
  const handleSkip = async (seconds: number) => {
    //@ts-ignore
    const newTime = playerRef.current.getCurrentTime() + seconds;
    if (newTime < 0) {
      handleSeekEnd(0);
      //@ts-ignore
    } else if (newTime > playerRef.current.getDuration()) {
      //@ts-ignore
      handleSeekEnd(playerRef.current.getDuration());
    } else {
      handleSeekEnd(newTime);
    }
    setPlaying(true);
  };

  const handlePlayPause = useCallback(() => {
    setPlaying(!playing);
  }, [playing]);
  async function getBase64FromUrl(url: string) {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }
  const generateDownloadLink = useCallback(
    async (link: string, action?: string) => {
      setIsLoading(true);
      try {
        const data = await downloadVideo(link);

        if (data.remote === "success") {
          if (action) {
            setCustomVideoUrl(data.data.path);
            return;
          }
          if (action)
            if (!link.includes("facebook" || "instagram")) {
              console.log("facebook link start");
              
              const tag = document.createElement("a");
              const url = process.env.REACT_APP_BACKEND_URL + data.data.path;
              const base64 = await getBase64FromUrl(url);
              tag.href = base64 as string;
              tag.download = `${videoDetails.title}test1.mp4`;
              tag.click();
            } else {
              const tag = document.createElement("a");
              tag.href = data.data.path;
              tag.download = `${videoDetails.title}.mp4`;
              tag.click();
            }
        }
      } catch (error) {
        message.error("Something went wrong");
        console.error(error);
      }
    },
    [videoDetails.title]
  );

  const handlePlay = () => {
    setCustomVideoPlaying(false);
    if (customVideoRef.current){
      // @ts-ignore
    customVideoRef.current.play();
    }
  };

  const handlePause = () => {
    setCustomVideoPlaying(true);
    if(customVideoRef.current){
      // @ts-ignore
      customVideoRef.current.pause();
    }
  };

  const handleRewind = () => {
    // @ts-ignore
    const newTime = (customVideoRef.current.currentTime -= 10);
    setCurrentTime(newTime);
  };

  const handleFastForward = () => {
    // @ts-ignore
    const newTime = (customVideoRef.current.currentTime += 10);
    setCurrentTime(newTime);
  };

  useEffect(() => {
    getVideoDetails(params.videoId as string);
  }, [params.videoId]);

  useEffect(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const newHeight = height - 150;
    setVideoHeightOrWidth({
      height: newHeight,
      width,
    });
  }, []);

  // chrome cast

  const [, setIsConnected] = useState(false);
  // const [, setIsMediaLoaded] = useState(false);
  const [, setPlayerState] = useState<any>(null);

  const [, setRemotePlayer] = useState<any>(null);
  const [, setRemotePlayerController] = useState<any>(null);

  useEffect(() => {
    const castSdkScript = document.createElement("script");
    castSdkScript.src =
      "https://www.gstatic.com/cv/js/sender/v1/cast_sender.js";
    castSdkScript.onload = () => {
      window["__onGCastApiAvailable"] = (isAvailable: boolean) => {
        if (isAvailable && cast) {
          console.log({ isAvailable });
          initializeCastPlayer();
        } else {
          console.log("Was not able to initialize CastPlayer");
        }
      };
    };
    document.head.appendChild(castSdkScript);
  }, []);

  const initializeCastPlayer = () => {
    var options: any = {};
    // options.receiverApplicationId = "CC1AD845";
    options.receiverApplicationId =
      chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID;
    options.autoJoinPolicy = chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED;
    options.androidReceiverCompatible = true;
    cast.framework.CastContext.getInstance().setOptions(options);

    const remotePlayer = new cast.framework.RemotePlayer();
    setRemotePlayer(remotePlayer);
    const remotePlayerController = new cast.framework.RemotePlayerController(
      remotePlayer
    );

    remotePlayerController.addEventListener(
      cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
      function (e: any) {
        var isConnected = e.value;
        setIsConnected(isConnected);
      }
    );

    remotePlayerController.addEventListener(
      cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
      function (e: any) {
        if (e.value === "PLAYING") {
          console.log("Chromecast is " + e.value);
          setPlayerState(e.value);
        } else if (e.value === "BUFFERING") {
          setPlayerState(e.value);
        } else {
          setPlayerState(e.value);
        }
      }
    );

    setRemotePlayerController(remotePlayerController);
  };
  //  Dont Remove those function
  // const castSrc = async () => {
  //   let mediaInfo = new chrome.cast.media.MediaInfo(
  //     "https://commondatastorage.googleapis.com/gtv-videos-bucket/CastVideos/mp4/ElephantsDream.mp4",
  //     "video/mp4"
  //   );
  //   mediaInfo.metadata = new chrome.cast.media.TvShowMediaMetadata();
  //   mediaInfo.metadata.title = "Sample Title";

  //   let request = new chrome.cast.media.LoadRequest(mediaInfo);
  //   request.autoplay = true;

  //   let cast = window.cast;
  //   let context = cast.framework.CastContext.getInstance();
  //   let session = context.getCurrentSession();
  //   if (session) {
  //     try {
  //       await session.loadMedia(request);
  //       console.log("Media is loaded");
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   } else {
  //     console.log("No session is available");
  //   }
  // };

  // const stopSrc = () => {
  //   var session = cast.framework.CastContext.getInstance().getCurrentSession();
  //   if (session) {
  //     remotePlayerController.stop();
  //   }
  // };

  // end
  useEffect(() => {
    if (videoDetails.providerName === "Facebook") {
      getFacebookVideo(videoDetails.videoUrl);
    }
  }, [videoDetails.providerName, videoDetails.videoUrl]);

  useEffect(() => {
    if (videoDetails.providerName === "xvideos") {
      getFacebookVideo(videoDetails.videoUrl, "xvideos");
    }
  }, [videoDetails.providerName, videoDetails.videoUrl]);
  const headerComponent = (id: string): JSX.Element => {
    return <VideoHeader />;
  };

  const memoHeader = useMemo(() => headerComponent(id), [id]);

  const getVideoSequences = useCallback(async (action: string) => {
    const data = await getSequences(action);
    if (data.remote === "success") {
      const newArray: string[] = [];
      data.data.forEach((item: videoSequenceObj) => {
        newArray.push(item.videoId);
      });
      setVideoList(newArray);
    }
  }, []);
  const playNextVideo = useCallback(
    (videoId: string) => {
      const queryParams = new URLSearchParams(location.search);
      const action = queryParams.get("action");
      const currentIndex = videoList.findIndex((elem) => elem === videoId);
      const nextVideo = videoList[currentIndex + 1];
      if (nextVideo && nextVideo !== videoDetails.id) {
        navigate(`/video/${nextVideo}?action=${action}`);
      } else {
        const previousVideo = videoList[0];
        if (previousVideo && previousVideo !== videoDetails.id) {
          navigate(`/video/${previousVideo}?action=${action}`);
        } else {
          navigate("/home");
        }
      }
      setIsNextVideo(false);
    },
    [location.search, navigate, videoDetails.id, videoList]
  );

  useEffect(() => {
    if (params) {
      setId(params.videoId as string);
    }
  }, [params]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const action = queryParams.get("action");
    if (action) {
      getVideoSequences(action as string);
    }
  }, [getVideoSequences, location.search, params.videoId]);

  useEffect(() => {
    if (isNextVideo) {
      playNextVideo(params.videoId as string);
    }
  }, [isNextVideo, params.videoId, playNextVideo]);

  useEffect(() => {
    if (onPlayPause) {
      setPlaying(true);
    } else {
      setPlaying(false);
    }
  }, [handlePlayPause, onPlayPause]);

  useEffect(() => {
    if (videoDetails.providerName === "Instagram") {
      generateDownloadLink(videoDetails.videoUrl, "Instagram");
    }
  }, [generateDownloadLink, videoDetails]);

  useEffect(() => {
    if (videoDetails.providerName === "Twitter") {
      getFacebookVideo(videoDetails.videoUrl, "Twitter");
    }
  }, [generateDownloadLink, videoDetails.providerName, videoDetails.videoUrl]);
  useEffect(() => {
    if (videoDetails.providerName === "TikTok") {
      getFacebookVideo(videoDetails.videoUrl, "TikTok");
    }
  }, [
    generateDownloadLink,
    videoDetails,
    videoDetails.providerName,
    videoDetails.videoUrl,
  ]);

  useEffect(() => {
    if (videoDetails.isProviderDelete) {
      message.error(
        "Sorry We cant play this video because this video is deleted from the provider ",
        5
      );
    }
  }, [videoDetails.isProviderDelete]);

  const handleReactPlayerControls = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.code === "ArrowRight") {
      handleSkip(10);
    }
    if (event.code === "ArrowLeft") {
      handleSkip(-10);
    }
    if (event.code === "Space") {
      handlePlayPause();
    }
    if (event.code === "ArrowUp") {
      increaseVolume();
    }
    if (event.code === "ArrowDown") {
      decreaseVolume();
    }
  };

  const handleCustomPlayerControls = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.code === "ArrowRight") {
      handleFastForward();
    }
    if (event.code === "ArrowLeft") {
      handleRewind();
    }
    if (event.code === "Space") {
      if (!customVideoPlaying) {
        handlePause();
      } else {
        handlePlay();
      }
    }
    if (event.code === "ArrowUp") {
      customPlayerIncreaseVolume();
    }
    if (event.code === "ArrowDown") {
      customPlayerDecreaseVolume();
    }
  };

  useEffect(() => {
    document
      .getElementById("VideoPlayerBody")
      ?.addEventListener("mouseout", () => {
        if (isPlayerReady && (!onPlayPause || onPlayPause)) {
          const element = document.getElementById("customKeyBoardController");
          if (element) {
            //@ts-ignore
            customKeyBoardControllerRef.current.focus();
            //@ts-ignore
            element.innerHTML = "";
            element.click();
          }
        }
      });
  }, [isPlayerReady, onPlayPause]);

  useEffect(() => {
    document
      .getElementById("VideoPlayerBody")
      ?.addEventListener("mouseenter", () => {
        if (isPlayerReady && (!onPlayPause || onPlayPause)) {
          const element = document.getElementById("customKeyBoardController");
          if (element) {
            //@ts-ignore
            customKeyBoardControllerRef.current.focus();
            //@ts-ignore
            element.innerHTML = "";
            element.click();
          }
        }
      });
  }, [isPlayerReady, onPlayPause]);

  useEffect(() => {
    document
      .getElementById("VideoPlayerBody")
      ?.addEventListener("mouseout", () => {
        if (
          (customVideoUrl && (customVideoPlaying || !customVideoPlaying)) ||
          !showProgress
        ) {
          const element = document.getElementById(
            "customVideoPlayerController"
          );

          if (element) {
            //@ts-ignore
            CustomPlayerRef.current.focus();
          }
          if (element) {
            //@ts-ignore
            element.innerHTML = "";
            element.click();
          }
        }
      });
  }, [customVideoPlaying, customVideoUrl, showProgress]);

  useEffect(() => {
    document
      .getElementById("VideoPlayerBody")
      ?.addEventListener("mouseenter", () => {
        if (
          (customVideoUrl && (customVideoPlaying || !customVideoPlaying)) ||
          !showProgress
        ) {
          const element = document.getElementById(
            "customVideoPlayerController"
          );

          if (element) {
            //@ts-ignore
            CustomPlayerRef.current.focus();
          }
          if (element) {
            //@ts-ignore
            element.innerHTML = "";
            element.click();
          }
        }
      });
  }, [customVideoPlaying, customVideoUrl, showProgress]);

  const handleVolumeChange = (newVolume: number) => {
    setVolume(newVolume);
  };

  const increaseVolume = () => {
    if (volume < 1) {
      setVolume(volume + 0.1);
    }
  };

  const decreaseVolume = () => {
    if (volume > 0) {
      setVolume(volume - 0.1);
    }
  };

  const handleVolumeBarChange = (e: any) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
  };

  const customPlayerIncreaseVolume = () => {
    if (volume < 1) {
      setVolume(volume + 0.1);
    }
  };

  const customPlayerDecreaseVolume = () => {
    if (volume > 0) {
      setVolume(volume - 0.1);
      //@ts-ignore
      customVideoRef.current.volume = volume;
    }
  };

  const handleCustomVolumeChange = (event: any) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    //@ts-ignore
    customVideoRef.current.volume = newVolume; // Update the volume of the video element
  };
  useEffect(() => {
    if (volume > 0 && !customVideoUrl) {
      if (volume > 0.1) {
        setIsMuteButtonShow(true);
      } else {
        setIsMuteButtonShow(false);
      }
    } else {
      if (volume > 0 && customVideoUrl) {
        if (volume > 0.1) {
          setIsMuteButtonShow(false);
        } else {
          setIsMuteButtonShow(true);
        }
      }
    }
  }, [customVideoUrl, volume]);

  // useEffect(() => {
  //   if (stopLoading) {
  //     return;
  //   }
  //   const timer = setTimeout(() => {
  //     setStopLoading(true);
  //   }, 5000);
  //   return () => clearTimeout(timer);
  // }, [stopLoading]);

  // useEffect(() => {
  //   document.body.style.overflow = "hidden";
  //   return () => {
  //     document.body.style.overflow = "auto";
  //   };
  // }, []);

  return (
    <>
      {id ? memoHeader : ""}

      <FullScreen handle={handle}>
        <>
          <div
            className={styles.video_video}
            id="VideoPlayerBody"
            onMouseLeave={() => {
              if (!ispadding) {
                setIspadding(true);
              }
            }}
            style={{
              top: ispadding ? "40px" : "40px",
              height: "100%",
            }}
          >
            <div
              onMouseEnter={() => {
                setShowProgress(true);
              }}
              onMouseLeave={() => setShowProgress(false)}
              className="force-click-control"
              onClick={() => {
                setPlaying(!playing);
                if (!customVideoPlaying) {
                  handlePause();
                } else {
                  handlePlay();
                }
              }}
            ></div>
            {videoDetails.providerName === "Mixcloud" ? (
              <div
                className="mix-Cloud-force-hide"
                onMouseEnter={() => {
                  setShowProgress(true);
                }}
                onMouseLeave={() => setShowProgress(false)}
              >
                <img
                  src={
                    videoDetails.thumbnail ||
                    "https://thumbnailer.mixcloud.com/unsafe/600x600/extaudio/3/9/b/8/ba1d-03d0-415f-8a71-063f8ac46230"
                  }
                  alt="mix-Cloud-force-hide"
                />
              </div>
            ) : (
              <>{console.log("i  am working Jim")}</>
            )}
            <>
              {videoDetails.isProviderDelete ? (
                <>
                  <span>
                    <img
                      src={IMAGES.deleteVideoImg}
                      className="deleteVideoImg"
                      alt="deleteVideoImg"
                    />
                  </span>
                </>
              ) : (
                <>
                  {isLoading ? (
                    <>
                      <div className="VideoPlayComponentLoader">
                        <div className="text-center">
                          {" "}
                          <Spin />
                          {/* <h5>Loading Please Wait...</h5> */}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <Grid
                    container
                    spacing={{ xs: 0, lg: 0 }}
                    sx={{ height: "100%" }}
                  >
                    <Grid
                      item
                      lg={12}
                      xs={12}
                      md={10}
                      className={`m-auto`}
                      sx={{ paddingTop: "0px", height: "100%" }}
                    >
                      <>
                        <>
                          <span
                            style={{ display: "block", height: "100%" }}
                            onMouseEnter={() => {
                              setShowProgress(true);
                            }}
                            onMouseLeave={() => setShowProgress(false)}
                            // style={{
                            //   marginLeft: "250px",
                            // }}
                          >
                            {(videoDetails.providerName === "Facebook" ||
                              videoDetails.providerName === "Instagram" ||
                              videoDetails.providerName === "Twitter" ||
                              videoDetails.providerName === "Tiktok" ||
                              videoDetails.providerName === "xvideos") &&
                            customVideoUrl ? (
                              <>
                                {/* customPlayer */}
                                <div
                                  style={{
                                    display: isLoading ? "none" : "block",
                                  }}
                                >
                                  <div
                                    onClick={() => {
                                      setCustomVideoPlaying(
                                        !customVideoPlaying
                                      );
                                      if (customVideoPlaying) {
                                        // @ts-ignore
                                        customVideoRef.current.play();
                                      } else {
                                        // @ts-ignore
                                        customVideoRef.current.pause();
                                      }
                                    }}
                                  >
                                    <video
                                      className="video_player_custome"
                                      // crossOrigin="anonymous"
                                      // style={{
                                      //   height: ispadding ? "99vh" : "99vh",
                                      // }}
                                      // height={videoHeightOrWidth.height}
                                      onLoadedMetadata={() => {
                                        setIsLoading(false);
                                      }}
                                      autoPlay={true}
                                      ref={customVideoRef}
                                      onTimeUpdate={handleTimeUpdate}
                                      onEnded={() => {
                                        if (!isLoggedIn) {
                                          navigate("/home");
                                        }
                                        setCustomVideoUrl("");
                                        isLoggedIn
                                          ? setIsNextVideo(true)
                                          : setIsNextVideo(false);
                                        setIsLoading(true);
                                      }}
                                    >
                                      <source
                                        src={customVideoUrl}
                                        id="customVideoPlayer"
                                      />
                                    </video>
                                  </div>

                                  <span
                                    id="customVideoPlayerController"
                                    ref={CustomPlayerRef}
                                    onKeyDown={(
                                      event: React.KeyboardEvent<HTMLDivElement>
                                    ) => {
                                      handleCustomPlayerControls(event);
                                    }}
                                    tabIndex={0}
                                    style={{
                                      color: "white",
                                    }}
                                  >
                                    CustomPlayerRef
                                  </span>

                                  {showProgress ? (
                                    <div className="hover-button-div custom_player">
                                      <Stack
                                        className={styles.react_player_range}
                                        direction={"row"}
                                        spacing={2}
                                        alignItems="center"
                                        justifyContent="center"
                                        // sx={{ mx: "auto", width: "250px" }}
                                      >
                                        <input
                                          style={{
                                            width: "100%",
                                            cursor: "pointer",
                                          }}
                                          type="range"
                                          min="0"
                                          max={
                                            // @ts-ignore
                                            customVideoRef.current
                                              ? // @ts-ignore
                                                customVideoRef.current.duration
                                              : 0
                                          }
                                          step="0.1"
                                          value={currentTime}
                                          onChange={handleTimeRangeChange}
                                        />
                                      </Stack>
                                      <div
                                        className="d-flex align-items-center custom_player_controls_div"
                                        style={{
                                          marginTop: "15px",
                                        }}
                                      >
                                        <Stack
                                          direction={"row"}
                                          className={styles.custom_play_button}
                                        >
                                          {/* <img
                                          src={IMAGES.rewind}
                                          alt="fastForword"
                                          className="playerIcon"
                                          onClick={() => handleRewind()}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        /> */}
                                          <span>
                                            <SVG.Backward
                                              className="playerIcon"
                                              onClick={() => handleRewind()}
                                              style={{
                                                cursor: "pointer",

                                                width: "16px",
                                                height: "18px",
                                                marginRight: "10px",
                                                marginTop: "11px",
                                              }}
                                            />
                                          </span>
                                          {/* </button> */}

                                          {customVideoPlaying ? (
                                            <>
                                              {/* <img
                                              src={IMAGES.play2}
                                              className="playerIcon"
                                              alt="pause"
                                              onClick={handlePlay}
                                              style={{
                                                cursor: "pointer",
                                              }}
                                                  /> */}
                                              <span>
                                                <SVG.Pause
                                                  className="playerIcon"
                                                  onClick={handlePlay}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              {/* <img
                                              src={IMAGES.pause2}
                                              alt="play"
                                              className="playerIcon"
                                              onClick={handlePause}
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            /> */}
                                              <span>
                                                <SVG.PlayVideo
                                                  className="playerIcon"
                                                  onClick={handlePause}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </span>
                                            </>
                                          )}

                                          {/* <img
                                          src={IMAGES.fastForword}
                                          className="playerIcon"
                                          alt="fastForword"
                                          onClick={() => handleFastForward()}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        /> */}
                                          <span>
                                            <SVG.Forward
                                              className="playerIcon"
                                              alt="fastForword"
                                              onClick={() =>
                                                handleFastForward()
                                              }
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            />
                                          </span>
                                        </Stack>{" "}
                                        <div
                                          className={styles.cstm_player_volume}
                                        >
                                          {/* <img
                                          src={IMAGES.muteVolume}
                                          alt="muteVolume"
                                          className="muteVolume"
                                          onClick={() => {
                                            setVolume(0);
                                            //@ts-ignore
                                            customVideoRef.current.volume = 0;
                                          }}
                                        /> */}
                                          <span>
                                            {!isMuteButtonShow ? (
                                              <>
                                                <SVG.Volume
                                                  // className="volume"
                                                  onClick={() => {
                                                    setIsMuteButtonShow(true);

                                                    setVolume(0);
                                                    //@ts-ignore
                                                    customVideoRef.current.volume = 0;
                                                  }}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <SVG.Mute
                                                  // className="muteVolume"
                                                  onClick={() => {
                                                    setIsMuteButtonShow(false);
                                                    setVolume(1);
                                                    //@ts-ignore
                                                    customVideoRef.current.volume = 1;
                                                  }}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </>
                                            )}
                                          </span>

                                          <input
                                            type="range"
                                            min="0"
                                            max="1"
                                            step="0.01"
                                            value={volume}
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onChange={handleCustomVolumeChange}
                                            // style={{
                                            //   width: "50%",
                                            // }}
                                          />
                                          {/* <img
                                          src={IMAGES.volume}
                                          alt="volume"
                                          className="volume"
                                          onClick={() => {
                                            console.log("i am Working");
                                            setVolume(1);
                                            //@ts-ignore
                                            customVideoRef.current.volume = 1;
                                          }}
                                        /> */}

                                          {/* <span> */}
                                          {/* <SVG.Volume
                                              // className="volume"
                                              onClick={() => {
                                                setVolume(1);
                                                //@ts-ignore
                                                customVideoRef.current.volume = 1;
                                              }}
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            /> */}
                                          {/* </span> */}
                                        </div>
                                        <div
                                          className={
                                            styles.timer_video_cstmplayer
                                          }
                                        >
                                          <p
                                          // style={{
                                          //   color: "white",
                                          //   left:
                                          //     videoDetails.providerName ===
                                          //       "Instagram" ||
                                          //     videoDetails.providerName ===
                                          //       "Twitter" ||
                                          //     videoDetails.providerName ===
                                          //       "TikTok"
                                          //       ? "95vh"
                                          //       : "80vh",
                                          //   width:
                                          //     videoDetails.providerName ===
                                          //       "Instagram" ||
                                          //     videoDetails.providerName ===
                                          //       "Twitter" ||
                                          //     videoDetails.providerName ===
                                          //       "TikTok"
                                          //       ? "20vh"
                                          //       : "50vh",
                                          // }}
                                          >
                                            <span>
                                              {showCurrentTime.toFixed(0)}:
                                              {currentSecond.toFixed(0)}
                                            </span>
                                            /
                                            <span>
                                              {remainingMinute}:
                                              {remainingSecond < 10
                                                ? `0${remainingSecond}`
                                                : remainingSecond}
                                            </span>
                                          </p>
                                        </div>
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "auto",
                                            marginTop: "10px",
                                            marginRight: "10px",
                                          }}
                                          onClick={() => {
                                            setIsFullScrenn(!isFullScrenn);
                                            if (!isFullScrenn) {
                                              handle.enter();
                                            } else {
                                              handle.exit();
                                            }
                                          }}
                                        >
                                          <SVG.fullScreen />
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    display: isLoading ? "none" : "block",
                                    height: "100%",
                                  }}
                                >
                                  <span
                                    ref={customKeyBoardControllerRef}
                                    id="customKeyBoardController"
                                    onKeyDown={(
                                      event: React.KeyboardEvent<HTMLDivElement>
                                    ) => {
                                      handleReactPlayerControls(event);
                                    }}
                                    tabIndex={0}
                                    style={{
                                      color: "white",
                                      position: "absolute",
                                      top: "0px",
                                      left: "0px",
                                      zIndex: 1,
                                    }}
                                  >
                                    ReactPlayer{" "}
                                  </span>
                                  {videoDetails.providerName !== "Facebook" ? (
                                    <>
                                      <ReactPlayer
                                        width="100%"
                                        height="100%"
                                        className={styles.react_player_div}
                                        id="customVideoPlayer"
                                        url={videoDetails?.videoUrl}
                                        ref={playerRef}
                                        playIcon={
                                          <img
                                            src={IMAGES.play2}
                                            alt="Thumbnail"
                                          />
                                        }
                                        onVolumeChange={handleVolumeChange}
                                        volume={1}
                                        muted={false}
                                        config={{
                                          youtube: {
                                            playerVars: { showinfo: 1, rel: 0 },
                                          },
                                          facebook: {
                                            appId:
                                              process.env
                                                .REACT_APP_FACEBOOK_APP_ID,
                                            // attributes: {
                                            //   "data-width": "800px",
                                            //   "data-height": "750px",
                                            // },
                                          },
                                        }}
                                        // width={
                                        //   videoDetails?.providerName === "Facebook"
                                        //     ? 550
                                        //     : videoHeightOrWidth.width
                                        // }
                                        // height={
                                        //   videoDetails?.providerName === "Facebook"
                                        //     ? 550
                                        //     : videoHeightOrWidth.height
                                        // }
                                        onPause={() => {
                                          setOnPlayPause(false);
                                        }}
                                        onPlay={() => {
                                          setOnPlayPause(true);
                                        }}
                                        controls={false}
                                        playing={playing}
                                        onReady={() => {
                                          setPlaying(true);
                                          setIsPlayerReady(true);
                                          setIsLoading(false);
                                        }}
                                        onProgress={handleProgress}
                                        onDuration={(Duration) => {
                                          setTotalDuration(Duration);
                                        }}
                                        onEnded={() => {
                                          isLoggedIn
                                            ? setIsNextVideo(true)
                                            : setIsNextVideo(false);
                                          setIsLoading(true);
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <><ReactPlayer
                                    width="100%"
                                    height="100%"
                                    className={styles.react_player_div}
                                    id="customVideoPlayer"
                                    url={videoDetails?.videoUrl}
                                    ref={playerRef}
                                    playIcon={
                                      <img
                                        src={IMAGES.play2}
                                        alt="Thumbnail"
                                      />
                                    }
                                    onVolumeChange={handleVolumeChange}
                                    volume={1}
                                    muted={false}
                                    config={{
                                      youtube: {
                                        playerVars: { showinfo: 1, rel: 0 },
                                      },
                                      facebook: {
                                        appId:
                                          process.env
                                            .REACT_APP_FACEBOOK_APP_ID,
                                        // attributes: {
                                        //   "data-width": "800px",
                                        //   "data-height": "750px",
                                        // },
                                      },
                                    }}
                                    // width={
                                    //   videoDetails?.providerName === "Facebook"
                                    //     ? 550
                                    //     : videoHeightOrWidth.width
                                    // }
                                    // height={
                                    //   videoDetails?.providerName === "Facebook"
                                    //     ? 550
                                    //     : videoHeightOrWidth.height
                                    // }
                                    onPause={() => {
                                      setOnPlayPause(false);
                                    }}
                                    onPlay={() => {
                                      setOnPlayPause(true);
                                    }}
                                    controls={false}
                                    playing={playing}
                                    onReady={() => {
                                      setPlaying(true);
                                      setIsPlayerReady(true);
                                      setIsLoading(false);
                                    }}
                                    onProgress={handleProgress}
                                    onDuration={(Duration) => {
                                      setTotalDuration(Duration);
                                    }}
                                    onEnded={() => {
                                      isLoggedIn
                                        ? setIsNextVideo(true)
                                        : setIsNextVideo(false);
                                      setIsLoading(true);
                                    }}
                                  /></>
                                  )}

                                  {isPlayerReady ? (
                                    <>
                                      {showProgress && (
                                        <div
                                          className="hover-button-div"
                                          
                                        >
                                          {/* currentSecond */}
                                          <p>
                                            {/* Second Time: {currentSecond.toFixed(2)} */}
                                          </p>

                                          <div
                                            className={
                                              styles.react_player_range
                                            }
                                          >
                                            <input
                                              className={
                                                styles.video_input_slider
                                              }
                                              type="range"
                                              min={0}
                                              max={1}
                                              id="temp"
                                              step="any"
                                              value={played}
                                              onChange={(e) =>
                                                setPlayed(
                                                  parseFloat(e.target.value)
                                                )
                                              }
                                              onPointerDown={handleSeekStart}
                                              onPointerUp={() =>
                                                handleSeekEnd(played)
                                              }
                                              onMouseUp={() =>
                                                handleSeekEnd(played)
                                              }
                                              style={{
                                                backgroundColor:
                                                  "linear-gradient(90deg, rgb(0,239,209) 0%, rgb(0,239,209) 35%, rgb(0,172,234) 100%)",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </div>

                                          <div
                                            className={styles.video_control_div}
                                          >
                                            <Stack
                                              direction={"row"}
                                              spacing={2}
                                            >
                                              <div
                                                className={
                                                  styles.playing_btn_player
                                                }
                                              >
                                                {/* <img
                                                  src={IMAGES.rewind}
                                                  alt="fastForword"
                                                  className="playerIcon"
                                                  onClick={() =>
                                                    handleSkip(-10)
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                /> */}
                                                <span>
                                                  <SVG.Backward
                                                    className="playerIcon"
                                                    onClick={() =>
                                                      handleSkip(-10)
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                      width: "19px",
                                                      height: "18px",
                                                      marginRight: "16px",
                                                    }}
                                                  />
                                                </span>
                                                {/* </button> */}

                                                {playing ? (
                                                  <>
                                                    {/* <img
                                                      src={IMAGES.pause2}
                                                      className="playerIcon"
                                                      alt="pause"
                                                      onClick={handlePlayPause}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    /> */}
                                                    <span>
                                                      <SVG.PlayVideo
                                                        className="playerIcon"
                                                        alt="pause"
                                                        onClick={
                                                          handlePlayPause
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      />
                                                    </span>
                                                  </>
                                                ) : (
                                                  <>
                                                    {/* <img
                                                      src={IMAGES.play2}
                                                      className="playerIcon"
                                                      alt="play"
                                                      onClick={handlePlayPause}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    /> */}
                                                    <span>
                                                      <SVG.Pause
                                                        className="playerIcon"
                                                        alt="play"
                                                        onClick={
                                                          handlePlayPause
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      />
                                                    </span>
                                                  </>
                                                )}
                                                <span>
                                                  <SVG.Forward
                                                    className="playerIcon"
                                                    onClick={() =>
                                                      handleSkip(10)
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </span>
                                                {/* <img
                                                  src={IMAGES.fastForword}
                                                  alt="fastForword"
                                                  className="playerIcon"
                                                  onClick={() => handleSkip(10)}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                /> */}
                                              </div>
                                            </Stack>

                                            <div
                                              className={
                                                styles.volume_section_reactplayer
                                              }
                                            >
                                              {/* <img
                                                src={IMAGES.muteVolume}
                                                alt="muteVolume"
                                                className="muteVolume"
                                                onClick={() => {
                                                  setVolume(0);
                                                }}
                                              /> */}
                                              <span>
                                                {isMuteButtonShow ? (
                                                  <>
                                                    <SVG.Volume
                                                      // className="volume"
                                                      onClick={() => {
                                                        setVolume(0);

                                                        setIsMuteButtonShow(
                                                          false
                                                        );
                                                      }}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  </>
                                                ) : (
                                                  <>
                                                    <SVG.Mute
                                                      // className="muteVolume"
                                                      onClick={() => {
                                                        setIsMuteButtonShow(
                                                          true
                                                        );
                                                        setVolume(1);
                                                      }}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  </>
                                                )}
                                              </span>

                                              <input
                                                type="range"
                                                min="0"
                                                max="1"
                                                step="0.01"
                                                value={volume}
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onChange={handleVolumeBarChange}
                                              />
                                            </div>

                                            <div className={styles.timer_video}>
                                              <p
                                                style={{
                                                  color: "white",
                                                  // display: "flex",
                                                  // justifyContent:
                                                  //   "space-between",
                                                  position: "relative",
                                                }}
                                              >
                                                <span className="me-1">
                                                  {showCurrentTime.toFixed(0)}:
                                                  {currentSecond.toFixed(0)}
                                                </span>
                                                /
                                                <span className="ms-1">
                                                  {remainingMinute}:
                                                  {remainingSecond < 10
                                                    ? `0${remainingSecond}`
                                                    : remainingSecond}
                                                </span>
                                              </p>
                                            </div>

                                            <span
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "auto",
                                                marginTop: "10px",
                                                marginRight: "10px",
                                              }}
                                              onClick={() => {
                                                setIsFullScrenn(!isFullScrenn);
                                                if (!isFullScrenn) {
                                                  handle.enter();
                                                } else {
                                                  handle.exit();
                                                }
                                              }}
                                            >
                                              <SVG.fullScreen />
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            )}
                          </span>
                        </>
                      </>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          </div>
          <span>
            <div
              style={{
                left: "60vh",
                height: "40px",
                width: "200px",
                display: "flex",
                position: "absolute",
              }}
            >
              <google-cast-launcher id="castbutton">
                Connect to cast
              </google-cast-launcher>
              {/* <button onClick={castSrc}>Load Media</button>
          <button onClick={stopSrc}>Stop</button> */}
            </div>
          </span>
        </>
      </FullScreen>
    </>
  );
};

export default VideoComponent;
