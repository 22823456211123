import React from "react";
import { Button } from "react-bootstrap";
import { IButtonComponent } from ".";
import styles from "./button.module.css";
function OutlineButtonComponent({
  text,
  className,
  ...props
}: IButtonComponent) {
  return (
    <Button className={`${styles.outlined_btn} ${className}`} {...props}>
      {text}
    </Button>
  );
}

export default OutlineButtonComponent;
