import React from "react";
import styles from "./modal.module.css";
import { Modal as ModalBootstrap } from "react-bootstrap";
import "./modal.module.css";
interface IModal {
  children: React.ReactNode;
  onClose: () => void;
  show: boolean;
  animation?: boolean;
  title?: React.ReactNode;
  scrollable?: boolean;
}

const Modal = ({
  children,
  onClose,
  show,
  scrollable,
  animation,
  title,
}: IModal) => {
  const handleClose = () => {
    if (onClose) onClose();
  };
  return (
    <>
      <div className={`${styles.modal}`}>
        <ModalBootstrap
          show={show}
          onHide={handleClose}
          animation={animation}
          backdrop="static"
          scrollable={true}
        >
          <ModalBootstrap.Header closeButton className={styles.modal_header}>
            {title}
          </ModalBootstrap.Header>
          <ModalBootstrap.Body>{children}</ModalBootstrap.Body>
        </ModalBootstrap>
      </div>
    </>
  );
};

export default Modal;
