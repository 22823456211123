import React, { useCallback, useEffect, useState } from "react";
import styles from "../auth.module.css";
import { Form } from "react-bootstrap";
import Modal from "../../../../modal";
import { RedButton } from "../../../../button";
import { Link } from "react-router-dom";
import { Password } from "../../../../input";
import { IAuthComponent } from "..";
import { loginWithSocialMedia, signUpUser } from "src/api/auth";
import { setIsLoggedIn } from "src/redux/slice/authSlice";
import { useAppDispatch } from "src/redux/hooks";
import { loginWithSocialRequest, SignUpApiInput } from "src/api/transform/auth";
import { UserErrorType } from "src/types/types";
import Loader from "src/utils/loader";
import { useToastContext } from "src/context/toastContext";
import { facebookLogin, signInWithGoogle } from "src/firebase";
import { message } from "antd";
import { socialMediaLoginIcons } from "src/assets/image";
import validator from "validator";
interface SignUpForm extends SignUpApiInput {
  confirmPassword: string;
}

function SignUpComponent({ show, handleCloseModel }: IAuthComponent) {
  const addToast = useToastContext();
  const dispatch = useAppDispatch();
  const [loading, setIsLoading] = useState(false);
  const [isSocialButtonShow, setIsSocialButtonShow] = useState(false);
  const [registerUserError, setRegisterUserError] = useState<UserErrorType>({
    nameError: "",
    emailError: "",
    passwordError: "",
    confirmPasswordError: "",
  });
  const [userData, setUserData] = useState<SignUpForm>({
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
  });
  const [isVerification, setIsVerification] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const type = e.target.name;
    const value = e.target.value;
    setUserData({
      ...userData,
      [type]: value,
    });
  };

  const registerUser = async () => {
    const newError: UserErrorType = {};
    if (userData.name === "") {
      newError.nameError = "Name is required";
    }

    if (userData.email === "") {
      newError.emailError = "Email is required";
    }
    if (validator.isEmail(userData.email)) {
    } else {
      newError.emailError = "Enter valid Email!";
    }
    if (userData.password === "") {
      newError.passwordError = "Password is required";
    }

    if (userData.confirmPassword === "") {
      newError.confirmPasswordError = "ConfirmPassword is required";
    }
    if (userData.password !== userData.confirmPassword) {
      newError.confirmPasswordError = "Both are not match";
      newError.passwordError = "Both are not match";
    }

    if (userData.password.length < 8) {
      newError.passwordError = "Password is greater then 8 character";
    }
    if (Object.keys(newError).length) {
      return setRegisterUserError({
        ...newError,
      });
    } else {
      setRegisterUserError({});
    }
    setIsLoading(true);
    const payload: SignUpApiInput = {
      name: userData.name,
      password: userData.password,
      email: userData.email,
    };
    if (!payload) {
      return;
    }
    const data = await signUpUser(payload);
    if (data.remote === "success") {
      dispatch(setIsLoggedIn(true));
      setIsVerification(true);
      setIsLoading(false);
    } else {
      if (data.error.status === 409) {
        setIsLoading(false);
        newError.emailError = "Email already exist";
        if (Object.keys(newError).length) {
          return setRegisterUserError({
            ...newError,
          });
        }
      }
      const error = data.error.errors;
      addToast(error);
    }
    setIsLoading(false);
  };
  const loginWithGoogle = useCallback(async () => {
    const user = await signInWithGoogle();
    if (user.user.providerData.length) {
      const payload: loginWithSocialRequest = {
        name: user.user.providerData[0].displayName || "",
        email: user.user.providerData[0].email || "",
        profilePic: user.user.providerData[0].photoURL || "",
      };
      const data = await loginWithSocialMedia(payload);
      if (data.remote === "success") {
        dispatch(setIsLoggedIn(true));
      } else {
        message.error("Something went wrong");
      }
    }
  }, [dispatch]);

  const loginWithFacebook = useCallback(async () => {
    const user = await facebookLogin();
    if (user.user.providerData.length) {
      const payload: loginWithSocialRequest = {
        name: user.user.providerData[0].displayName || "",
        email: user.user.providerData[0].email || "",
        profilePic: user.user.providerData[0].photoURL || "",
      };
      const data = await loginWithSocialMedia(payload);
      if (data.remote === "success") {
        dispatch(setIsLoggedIn(true));
      } else {
        message.error("Something went wrong");
      }
    }
  }, [dispatch]);
  useEffect(() => {
    setIsVerification(false);
  }, [show]);

  return (
    <div>
      <Modal show={show} onClose={handleCloseModel}>
        {!isVerification ? (
          <>
            <h1 className={styles.heading}>Sign Up</h1>
            <Form>
              <Form.Group className={`mb-3`}>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={userData.name}
                  size="lg"
                  placeholder="Name"
                  name="name"
                  onChange={handleChange}
                  className={styles.signup_form_control}
                />
                <span className="text-danger">
                  {registerUserError.nameError}
                </span>
              </Form.Group>
              <Form.Group className={`mb-3`}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  value={userData.email}
                  type="email"
                  size="lg"
                  placeholder="Email"
                  className={styles.signup_form_control}
                  name="email"
                  onChange={handleChange}
                />
                <span className="text-danger">
                  {" "}
                  {registerUserError?.emailError}
                </span>
              </Form.Group>
              <Form.Group className={`mb-3`}>
                <Form.Label>Password</Form.Label>

                <Password
                  onChange={handleChange}
                  name="password"
                  value={userData.password}
                />
                <span className="text-danger">
                  {registerUserError.passwordError}
                </span>
              </Form.Group>
              <Form.Group className={`mb-4`}>
                <Form.Label>Confirm Password</Form.Label>

                <Password
                  // placeholder="Confirm-Password"
                  value={userData.confirmPassword}
                  onChange={handleChange}
                  name="confirmPassword"
                />
                <span className="text-danger">
                  {registerUserError.confirmPasswordError}
                </span>
              </Form.Group>
              <RedButton
                text={loading ? <Loader /> : "Sign Up"}
                className={`w-100 mb-4`}
                onClick={registerUser}
              />
              <RedButton
                text={"Sign Up  with social account"}
                onClick={() => {
                  setIsSocialButtonShow(true);
                }}
                className={`w-100 mb-4`}
              />
              {isSocialButtonShow ? (
                <div className="d-flex align-items-center justify-content-center">
                  {socialMediaLoginIcons.map((item, index) => {
                    return (
                      <div key={index} className={`mx-3`}>
                        <img
                          onClick={
                            item.id === "facebook"
                              ? loginWithFacebook
                              : loginWithGoogle
                          }
                          className="mb-3"
                          src={item.image}
                          alt="img"
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <></>
              )}
              <p className={styles.title_account}>Already have an account?</p>
              <Link to="/?at=login" style={{ textDecoration: "none" }}>
                <h2 className={styles.sub_heading}>Log in</h2>
              </Link>
            </Form>
          </>
        ) : (
          <>
            <div>{/* <Verification /> */}</div>
          </>
        )}
      </Modal>
    </div>
  );
}

export default SignUpComponent;
