import IMAGES from "src/assets/image";
import { Video } from "src/redux/slice/video";
import { highlightOrArchiveType } from "src/utils/constants/constants";
import { PrivacyTypeEnum } from "src/utils/constants/enums";

export type getVideoLinkRequest = {
  url: string;
};

export type getPlaylistIdResponseSchema = {
  playlistId: string;
};

export type FetchVideoDetailsFromOriginResponse = {
  authorName: string;
  videoUrl: string;
  providerName: string;
  provider_url: string;
  thumbnailUrl: string;
  videoTitle: string;
};

export type getEditVideoDetailUserResponse = {
  id: string;
  profilePic: string;
  email?: string;
  name: string;
};
export type getEditVideoDetailsResponse = {
  _id: string;
  videoTitle: string;
  playlistId: string;
  thumbnailUrl?: string;
  customThumbnail?: string;
  users?: getEditVideoDetailUserResponse[];
  videoPrivacyType: PrivacyTypeEnum;
  tag?: any;
};
export type VideoResponse = {
  _id: string;
  customThumbnail?: string;
  thumbnailUrl: string;
  isPrivate: boolean;
  videoUrl: string;
  providerName: string;
  authorName: string;
  description: string;
  videoTitle: string;
  userImage: string; // user who posted the video
  isHighlight: boolean | string; //if user liked the video
  pngImage?: string;
  isProviderDelete: boolean;
  users?: getEditVideoDetailUserResponse[];
  ownerImage?: string;
};

export type editVideoResponseType = {
  videoId: string;
  videoTitle: string;
  customThumbnail?: string;
  playlistId: string;
  privacyType: string;
  users: string;
  tag: any;
  isPrivate: boolean;
  isOnlyMe: boolean;
};
export type editPlaylistInput = {
  playlistId: string;
  name: string;
};

export type editVideoResponse = {
  filePath: string;
  pngFilePath?: string;
};

export type createSequenceRequestSchema = {
  action: string;
  videoIds: string[];
  playlistId?: string;
};

export const transformVideoResponse = (data: VideoResponse): Video => {
  console.log({ data });
  return {
    id: data._id,
    thumbnail: data.pngImage
      ? `${process.env.REACT_APP_BACKEND_URL_PROFILE_URL}${data.pngImage}`
      : data.customThumbnail
      ? `${process.env.REACT_APP_BACKEND_URL_PROFILE_URL}${data.customThumbnail}`
      : data.thumbnailUrl,
    source:
      data.providerName === "YouTube"
        ? IMAGES.youtube
        : data.providerName === "Dailymotion"
        ? IMAGES.dailymotion
        : data.providerName === "Vimeo"
        ? IMAGES.vimeo
        : data.providerName === "Facebook"
        ? IMAGES.facebookIcon
        : data.providerName === "Instagram"
        ? IMAGES.instagram
        : data.providerName === "Twitch"
        ? IMAGES.Twitch
        : data.providerName === "Tiktok"
        ? IMAGES.tiktok
        : data.providerName === "Twitter"
        ? IMAGES.twitter
        : data.providerName === "xvideos"
        ? IMAGES.xvideos
        : data.providerName === "Mixcloud"
        ? IMAGES.mixCloud
        : data.providerName === "SoundCloud"
        ? IMAGES.soundCloud
        : "",

    userImage: data.userImage || IMAGES.placeholderAvatar,
    title: data.videoTitle,
    description: data.description,
    isHighlighted:
      data.isHighlight === highlightOrArchiveType.highlight ? true : false,
    videoUrl: data.videoUrl,
    providerName: data.providerName,
    gifImage: `${process.env.REACT_APP_BACKEND_URL_PROFILE_URL}${data.customThumbnail}`,
    isProviderDelete: data.isProviderDelete ? true : false,
    // isProviderDelete: false,
    users: data.users || [],
    ownerImage: data.ownerImage
      ? `${process.env.REACT_APP_BACKEND_URL_PROFILE_URL}${data.ownerImage}`
      : "",
  };
};
