import { ReactComponent as SearchIcon } from "./searchIcon.svg";
import { ReactComponent as AttachIcon } from "./attachIcon.svg";
import { ReactComponent as Bell } from "./bell.svg";
import { ReactComponent as Eye } from "./eye.svg";
import { ReactComponent as EyeClose } from "./eyeClose.svg";
import { ReactComponent as HomeIcon } from "./home.svg";
import { ReactComponent as Heart } from "./heart.svg";
import { ReactComponent as ShareIcon } from "./shareIcon.svg";
import { ReactComponent as ArchiveIcon } from "./archiveIcon.svg";
import { ReactComponent as PlaylistIcon } from "./playlistIcon.svg";
import { ReactComponent as FillHeart } from "./fillHeart.svg";
import { ReactComponent as ThreeDots } from "./threeDots.svg";
import { ReactComponent as File } from "./file.svg";
import { ReactComponent as Share } from "./share.svg";
import { ReactComponent as Delete } from "./delete.svg";
import { ReactComponent as RightArrow } from "./rightArrow.svg";
import { ReactComponent as Setting } from "./setting.svg";
import { ReactComponent as FeedBack } from "./sendFeedBack.svg";
import { ReactComponent as Logout } from "./logout.svg";
import { ReactComponent as FlexThreeDots } from "./flexThreeDots.svg";
import { ReactComponent as DarkTheme } from "./darkTheme.svg";
import { ReactComponent as Language } from "./language.svg";
import { ReactComponent as Location } from "./location.svg";
import { ReactComponent as Play } from "./play.svg";
import { ReactComponent as Paste } from "./paste.svg";
import { ReactComponent as Pencil } from "./pencil.svg";
import { ReactComponent as Lock } from "./lock.svg";
import { ReactComponent as IconShorts } from "./shorts.svg";
import { ReactComponent as RadiusDelete } from "./radiusDelete.svg";
import { ReactComponent as Download } from "./download.svg";
import { ReactComponent as DownArrow } from "./downArrow.svg";
import { ReactComponent as Shuffle } from "./shuffle.svg";
import { ReactComponent as Equal } from "./equalIcon.svg";
import { ReactComponent as Bars } from "./bars.svg";
import { ReactComponent as SharePlayIcon } from "./share-play-icon.svg";
import { ReactComponent as GlobalSearchIcon } from "./globalSearchIcon.svg";
import { ReactComponent as Upload } from "./upload.svg";
import { ReactComponent as Forward } from "./forward.svg";
import { ReactComponent as Backward } from "./backward.svg";
import { ReactComponent as Pause } from "./pause.svg";
import { ReactComponent as PlayVideo } from "./playVideo.svg";
import { ReactComponent as Volume } from "./volume.svg";
import { ReactComponent as Mute } from "./mute.svg";
import { ReactComponent as UpArrrow } from "./up-arrow.svg";
import { ReactComponent as DownMoreArrow } from "./down-more-arrow.svg";
import { ReactComponent as vector } from "./Vector.svg";
import { ReactComponent as fullScreen } from "./fullScreen.svg";
const SVG = {
  SharePlayIcon,
  Bars,
  Equal,
  Shuffle,
  DownArrow,
  Download,
  RadiusDelete,
  IconShorts,
  Lock,
  Pencil,
  Paste,
  Play,
  Location,
  Language,
  DarkTheme,
  FlexThreeDots,
  Logout,
  FeedBack,
  Setting,
  RightArrow,
  Delete,
  Share,
  File,
  SearchIcon,
  AttachIcon,
  Bell,
  Eye,
  EyeClose,
  HomeIcon,
  Heart,
  ShareIcon,
  ArchiveIcon,
  PlaylistIcon,
  FillHeart,
  ThreeDots,
  GlobalSearchIcon,
  Upload,
  Forward,
  Pause,
  PlayVideo,
  Backward,
  Mute,
  Volume,
  UpArrrow,
  DownMoreArrow,
  vector,
  fullScreen,
};

export default SVG;
