import React from "react";
import { Card } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import styles from "./video.module.css";

function VideoSkeleton() {
  return (
    <div className={`px-3 w-100`}>
      <Card className={styles.video_card}>
        <div className={styles.thumbnail_img}>
          <Skeleton className={styles.thumbnail} height={500} />
        </div>
        <Card.Body className={`px-0`}>
          <div className={`justify-content-between`}>
            <div className={styles.skeleton_image_text}>
              <Skeleton circle className={styles.image} />
              <Card.Text className={styles.card_text}>
                <Skeleton count={1} />
              </Card.Text>
            </div>
          </div>
          <Card.Text className={styles.description}>
            <Skeleton count={5} />
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default VideoSkeleton;
