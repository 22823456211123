import React from "react";
import { Form } from "react-bootstrap";
import { ISelect } from ".";
import styles from "./input.module.css";

function SelectInput({ title, className, options, ...rest }: ISelect) {
  return (
    <>
      <Form.Group className={`mb-3`}>
        <Form.Label>{title}</Form.Label>
        <Form.Select
          {...rest}
          size="lg"
          className={`${styles.input} ${className}`}
        >
          {options.map((item) => {
            return (
              <option value={item.value} key={item.id}>
                {item.title}{" "}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
    </>
  );
}

export default SelectInput;
