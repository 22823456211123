import React from "react";
import styles from "../input.module.css";
// import TagsInput, { ReactTagsInputProps } from "react-tagsinput";
// import "react-tagsinput/react-tagsinput.css";
import { ReactTags, ReactTagsProps } from "react-tag-autocomplete";

// interface ITagInput extends ReactTagsInputProps<string> {
//   title: string,
// }
interface ITagInput extends ReactTagsProps {
  title: string;
}
function TagInput({ title, ...rest }: ITagInput) {
  return (
    <div className="mb-3">
      <h5>{title}</h5>
      <ReactTags
        {...rest}
        labelText=""
        classNames={{
          root: "react-tags",
          rootIsActive: "is-active",
          rootIsDisabled: "is-disabled",
          rootIsInvalid: "is-invalid",
          label: "react-tags__label",
          tagList: "react-tags__list",
          tagListItem: "react-tags__list-item",
          tag: "react-tags__tag",
          tagName: "react-tags__tag-name",
          comboBox: "react-tags__combobox",
          input: `react-tags__combobox-input ${styles.tag_input}`,
          listBox: "react-tags__listbox",
          noOptions: "react-tags__listbox-no-options",
          option: "react-tags__listbox-option",
          optionIsActive: "is-active",
        }}
      />
    </div>
  );
}

export default TagInput;
