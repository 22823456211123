import { IButtonComponent } from "src/components/button";
import React from "react";
import { Button as ButtonBootstrap } from "react-bootstrap";
import styles from "./button.module.css";

function RedButtonComponent({ text, className, ...props }: IButtonComponent) {
  return (
    <ButtonBootstrap {...props} className={`${styles.red_btn} ${className}`}>
      {text}
    </ButtonBootstrap>
  );
}

export default RedButtonComponent;
