import { SuccessResult, UserType } from "src/types/types";
import api from "./api";
import {
  LoginApiInput,
  UserResponse,
  SignUpApiInput,
  SignUpApiResponse,
  transformUserResponse,
  loginWithSocialRequest,
  updatePasswordInput,
  verifyOypResponse,
  createFeedback,
} from "./transform/auth";

const authUrl = "api/v1";
const adminUrl = "api/v1/admin/";
export const signUpUser = async (data: SignUpApiInput) => {
  const response = await api.request<SignUpApiResponse>({
    url: `${authUrl}/auth/register`,
    method: "POST",
    data,
  });
  return response;
};

export const loginUser = async (data: LoginApiInput) => {
  const response = await api.request<UserResponse>({
    url: `${authUrl}/auth/login`,
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    const result: SuccessResult<UserType> = {
      remote: "success",
      data: transformUserResponse(response.data),
    };
    return result;
  }
  return response;
};

export const getUserDetails = async () => {
  const response = await api.request<UserResponse>({
    url: `${authUrl}/auth/user`,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: "success",
      data: transformUserResponse(response.data),
    };
  }
  return response;
};

export const updateUserHandler = async (data: UserType) => {
  // console.log({ hello: data.file[0].originFileObj });
  data.file = data.file[0].originFileObj;
  const formData = new FormData();

  for (const keys in data) {
    // @ts-ignore
    formData.set(keys, data[keys]);
  }
  const response = await api.request<UserResponse>({
    url: `${authUrl}/auth/update-user`,
    method: "patch",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (response.remote === "success") {
    return {
      remote: "success",
      data: transformUserResponse(response.data),
    };
  }
  return response;
};

export const searchUserByEmail = async (email: string) => {
  const response = await api.request<UserResponse[]>({
    url: `${authUrl}/auth/search-user/${email}`,
    method: "get",
  });
  if (response.remote === "success") {
    return {
      remote: "success",
      data: response.data.map((data) => transformUserResponse(data)),
    };
  }
  return response;
};

export const loginWithSocialMedia = async (payload: loginWithSocialRequest) => {
  const response = await api.request({
    url: `${authUrl}/auth/login-with-social`,
    method: "POST",
    data: payload,
  });
  return response;
};

export const forgetPassword = async (email: string) => {
  const response = await api.request({
    url: `${authUrl}/auth/forget-password/${email}?isUserPanel=true`,
    method: "get",
  });
  return response;
};

export const verifyOyp = async (otp: string) => {
  const response = await api.request<verifyOypResponse>({
    url: `${authUrl}/auth/verify-otp/${otp}`,
    method: "get",
  });
  return response;
};

export const updatePassword = async (data: updatePasswordInput) => {
  const response = await api.request({
    url: `${authUrl}/auth/update-password`,
    method: "post",
    data: data,
  });
  return response;
};

export const sendFeedback = async (data: createFeedback) => {
  const response = await api.request({
    url: `${authUrl}/auth/feedback`,
    method: "patch",
    data: data,
  });
  return response;
};

export const midHeader = async () => {
  const response = await api.request({
    url: `${adminUrl}mid-header`,
    method: "get",
  });
  return response;
};

export const getHeader = async () => {
  const response = await api.request({
    url: `${adminUrl}header`,
    method: "get",
  });
  return response;
};