import React, { Fragment, useCallback, useEffect, useState } from "react";
import styles from "src/components/sidebar/sidebar.module.css";
import { Link, useLocation } from "react-router-dom";
import SVG from "src/assets/svg";
import { getAllPlaylistOrVideoHandler, getBinPlaylist } from "src/api/playlist";
import { setMyPlaylists, setBinPlaylist } from "src/redux/slice/playlist";
import { setIsHideSideBar, setIsMobileView } from "src/redux/slice/video";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import {
  setFavorite,
  setGlobalSearch,
  setStopGlobalSearch,
} from "src/redux/slice/video";
import { Popover } from "antd";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { getAllHighlightOrArchiveVideoHandler } from "src/api/video";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEventListener, useIsomorphicLayoutEffect } from "usehooks-ts";
type SubMenuType = {
  path: string;
  icon?: JSX.Element;
  name: string;
  newVideo?: number;
  subSideMenu?: any;
};
type SideMenuType = {
  path: string;
  icon: JSX.Element;
  name: string;
  createPlaylist?: string;
  createPlaylistIcon?: JSX.Element;
  subSideMenu?: undefined | SubMenuType[];
};
interface WindowSize {
  width: number;
  height: number;
}
const Sidebar = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  });
  const dispatch = useAppDispatch();
  const { isFavorite, isHideSideBar, isMobileView } = useAppSelector(
    (state: RootState) => state.video
  );
  const { MyPlaylists, BinPlaylist } = useAppSelector(
    (state: RootState) => state.playlist
  );

  const [isFullMenuShow, setIsFullMenuShow] = useState(false);
  const [fullPlaylistName, setFullPlaylistNameShow] = useState("");

  const [sideMenu, setSideMenu] = useState<SideMenuType[]>([
    {
      path: "/home",
      icon: <SVG.HomeIcon />,
      name: "Home",
    },
    {
      path: "/highlights",
      icon: <SVG.Heart />,
      name: "Favorite",
    },
    {
      path: "/shared-with-me",
      icon: <SVG.ShareIcon />,
      name: "Shared with me",
    },
    // comment This Archived Functionality Just Because currently we are don,t use it but may be use this page in feature   api are intrigate in this page
    // {
    //   path: "/archived",
    //   icon: <SVG.ArchiveIcon />,
    //   name: "Archived",
    // },
    {
      path: "/private",
      icon: <SVG.Lock />,
      name: "Private",
    },
    {
      path: "/my-play-list",
      icon: <SVG.PlaylistIcon />,
      name: "My Playlist",
      createPlaylist: "Create new",
      createPlaylistIcon: <SVG.vector />,
      subSideMenu: [
        {
          path: "",
          name: "#Roadtrip",
          newVideo: 5,
        },
        {
          path: "",
          name: "#mustwatch",
          newVideo: 6,
        },
        {
          path: "",
          name: "#forkids",
          newVideo: 5,
        },
      ],
    },
    {
      path: "",
      icon: <SVG.DownMoreArrow />,
      name: "showMore",
    },
    {
      path: "/bin-playlist",
      icon: <SVG.PlaylistIcon />,
      name: "RecycleBin",
      subSideMenu: [],
    },
    {
      path: "",
      icon: <SVG.UpArrrow />,
      name: "showLess",
    },
  ]);

  const location = useLocation();

  const handleSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEventListener("resize", handleSize);

  // Set size at the first client-side load
  useIsomorphicLayoutEffect(() => {
    handleSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log({ windowSize });
  }, [windowSize]);
  const getAllPlaylistOrVideo = useCallback(async () => {
    const data = await getAllPlaylistOrVideoHandler();
    if (data.remote === "success") {
      dispatch(setMyPlaylists(data.data));
    }
  }, [dispatch]);

  const getAllVideosHandler = useCallback(async () => {
    const data = await getAllHighlightOrArchiveVideoHandler({
      filter: "highlight",
      search: "",
      days: 0,
    });
    if (data.remote === "success") {
      if (data.data.length) {
        dispatch(setFavorite(true));
      }
    }
  }, [dispatch]);
  const getBinPlaylistHandler = useCallback(async () => {
    const data = await getBinPlaylist();
    if (data.remote === "success") {
      dispatch(setBinPlaylist(data.data));
    }
  }, [dispatch]);
  const handleCloseGlobalSearch = (name: string) => {
    dispatch(setGlobalSearch(name));
    dispatch(setStopGlobalSearch(true));
  };

  const content = () => {
    return (
      <div>
        <p>{fullPlaylistName || ""}</p>
      </div>
    );
  };
  const handlerHoverEffect = (id: string) => {
    const getPlaylist = MyPlaylists.find((item) => {
      return item._id === id;
    });
    if (getPlaylist) {
      setFullPlaylistNameShow(getPlaylist?.name || "");
    } else {
      setFullPlaylistNameShow("Bin");
    }
  };

  useEffect(() => {
    getAllPlaylistOrVideo();
    getAllVideosHandler();
  }, [getAllPlaylistOrVideo, getAllVideosHandler]);

  useEffect(() => {
    getBinPlaylistHandler();
  }, [getBinPlaylistHandler]);
  useEffect(() => {
    setSideMenu((pre) => {
      const newPlaylist = MyPlaylists.map((item) => {
        const newObj = { ...item };
        if (item.name.length > 16) {
          let result = newObj.name.slice(0, 16);
          result += "...";
          newObj.name = result;
          return newObj;
        } else {
          return newObj;
        }
      });
      const newData = pre.filter((item) => {
        if (item.path === "/my-play-list") {
          if (newPlaylist) {
            return (item.subSideMenu = newPlaylist);
          }
        }
        return item;
      });
      return newData;
    });
  }, [MyPlaylists]);

  useEffect(() => {
    setSideMenu((pre) => {
      const newData = pre.filter((item) => {
        if (item.path === "/bin-playlist") {
          return (item.subSideMenu = BinPlaylist);
        }
        return item;
      });
      return newData;
    });
  }, [BinPlaylist]);

  useEffect(() => {
    console.log({ isHideSideBar,isMobileView });
  }, [isHideSideBar,isMobileView]);
  return (
    <>
      <div className={`${isMobileView ? styles.mobile : ""} ${styles.sidebar}`}>
        <SimpleBar style={{ maxHeight: 500 }}>
          {sideMenu.map((item, index) => {
            if (item.path === "/highlights") {
              if (isFavorite) {
                item.icon = <SVG.FillHeart />;
              } else {
                item.icon = <SVG.Heart />;
              }
            }
            if (item.name === "Show less") {
              // setIsFullMenuShow(true);
              return "";
            }
            return (
              <Fragment key={index}>
                <div className={styles.sidebar_name_div}>
                  {item.name === "showMore" &&
                  !isFullMenuShow &&
                  MyPlaylists.length > 3 ? (
                    <>
                      <span
                        onClick={() => {
                          setIsFullMenuShow(true);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <span className={`mx-3 ${styles.sidebar_icon}`}>
                          {/* {item.icon} */}
                        </span>
                        <span
                          className={`mt-1`}
                          style={{
                            backgroundColor: "red",
                            color: "#fff",
                            padding: "3px 5px ",
                            borderRadius: "3px",
                            fontSize: "12px",
                            marginLeft: "20px",
                          }}
                        >
                          {item.icon}
                          {/* {item.name} */} Show More
                        </span>
                      </span>
                    </>
                  ) : (
                    <>
                      {item.name === "showLess" && isFullMenuShow ? (
                        <>
                          <span
                            onClick={() => {
                              setIsFullMenuShow(false);
                              dispatch(setIsMobileView(!isMobileView));
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <span
                              className={`mx-3 ${styles.sidebar_icon}`}
                            ></span>
                            <span
                              className={`mt-1`}
                              style={{
                                backgroundColor: "red",
                                color: "#fff",
                                padding: "3px 5px ",
                                borderRadius: "3px",
                                fontSize: "12px",
                                marginLeft: "20px",
                              }}
                            >
                              {item.icon}
                              {/* {item.name} */} Show Less
                            </span>
                          </span>
                        </>
                      ) : (
                        <>
                          {item.name === "showLess" ||
                          item.name === "showMore" ? (
                            <></>
                          ) : (
                            <>
                              <Link
                                to={item.path}
                                className={`${styles.name} ${
                                  location.pathname.includes(item.path)
                                    ? styles.active
                                    : ""
                                }`}
                                onClick={() => {
                                  handleCloseGlobalSearch("");
                                  dispatch(setIsMobileView(!isMobileView));
                                }}
                              >
                                <span>
                                  <span
                                    className={`mx-3 ${styles.sidebar_icon}`}
                                  >
                                    {item.icon}
                                  </span>
                                  <span className={`mt-1`}> {item.name} </span>

                                  {/* createPlaylist */}
                                </span>
                              </Link>
                              {item.createPlaylist ? (
                                <>
                                  <Link
                                    to="my-play-list?at=create-playlist"
                                    className={`${styles.name}`}
                                    onClick={()=>{
                                      dispatch(setIsMobileView(!isMobileView));
                                    }}
                                  >
                                    <span
                                      className={`${styles.name} mt-3 createPlaylist `}
                                      style={{
                                        color: "#9A9A9A",
                                      }}
                                    >
                                      {item.createPlaylistIcon}
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      {item.createPlaylist}{" "}
                                    </span>
                                  </Link>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <>
                  {item.subSideMenu?.map((items: any, subindex: number) => {
                    if (!isFullMenuShow && subindex > 3) {
                      return "";
                    }
                    return (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip">
                            {fullPlaylistName || ""}
                          </Tooltip>
                        }
                      >
                        <div
                          key={subindex}
                          className={styles.sidebar_submenu_name_div}
                        >
                          <Link
                            onClick={() => {
                              if (windowSize.width <= 992) {
                                dispatch(setIsHideSideBar(!isHideSideBar));
                                dispatch(setIsMobileView(!isMobileView));
                              }
                            }}
                            to={`${
                              items.Path === "/binPlaylist"
                                ? `/binPlaylist/${items._id}`
                                : `myplaylist/${items._id}`
                            }`}
                            className={`${styles.submenu_name}`}
                          >
                            {/* <Popover content={content} title="PlaylistName"> */}
                            <span
                              className={
                                location.pathname.includes(items._id)
                                  ? "activeSubSideMenuClass"
                                  : ""
                              }
                              onMouseOver={() => {
                                handlerHoverEffect(items._id);
                              }}
                            >
                              #{items.name}
                              {/* {fullPlaylistName ? <>test</> : <></>} */}
                              <span className={styles.new_video_count}>
                                {items.newVideo || 0}
                              </span>
                            </span>

                            {/* </Popover> */}
                          </Link>
                        </div>
                      </OverlayTrigger>
                    );
                  })}
                </>
              </Fragment>
            );
          })}
        </SimpleBar>
      </div>
    </>
  );
};

export default Sidebar;
