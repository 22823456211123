import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PrivacyTypeEnum } from "src/utils/constants/enums";
import { getAllPlaylistUserType } from "src/api/transform/playlist";

export type Playlist = {
  id: string;
  name: string;
  privacyType: PrivacyTypeEnum;
};

export type PageType = {
  path: string;
  name: string;
  _id: string;
  content: string;
};
interface IPlaylistInitialState {
  userPlaylist: Playlist[];
  MyPlaylists: getAllPlaylistUserType[];
  sharedPlaylist: getAllPlaylistUserType[];
  BinPlaylist: getAllPlaylistUserType[];
  allPage: PageType[];
}

const initialState: IPlaylistInitialState = {
  userPlaylist: [],
  MyPlaylists: [],
  sharedPlaylist: [],
  BinPlaylist: [],
  allPage: [],
};

export const playlistSlice = createSlice({
  name: "playlist",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUserPlaylist: (state, action: PayloadAction<Playlist[]>) => {
      state.userPlaylist = action.payload;
    },
    setMyPlaylists: (
      state,
      action: PayloadAction<getAllPlaylistUserType[]>
    ) => {
      state.MyPlaylists = action.payload;
    },
    setSharedPlaylists: (
      state,
      action: PayloadAction<getAllPlaylistUserType[]>
    ) => {
      state.sharedPlaylist = action.payload;
    },
    setBinPlaylist: (
      state,
      action: PayloadAction<getAllPlaylistUserType[]>
    ) => {
      state.BinPlaylist = action.payload;
    },
    setOneStreamPage: (state, action: PayloadAction<PageType[]>) => {
      state.allPage = action.payload;
    },
  },
});

export const {
  setUserPlaylist,
  setMyPlaylists,
  setSharedPlaylists,
  setBinPlaylist,
  setOneStreamPage,
} = playlistSlice.actions;
export default playlistSlice.reducer;
