export const tokens = {
  accessToken: () => {
    const isRememberMe = localStorage.getItem("rememberMe");
    if (isRememberMe === "true") {
      return sessionStorage.getItem("x-access");
    } else {
      return localStorage.getItem("x-access");
    }
  },
  setAccessToken: (token: string) => {
    const isRememberMe = localStorage.getItem("rememberMe");
    if (isRememberMe === "true") {
      sessionStorage.setItem("x-access", token);
    } else {
      localStorage.setItem("x-access", token);
    }
  },
  removeAccessToken: () => localStorage.removeItem("x-access"),
  setRefreshToken: (refreshToken: string) => {
    const isRememberMe = localStorage.getItem("rememberMe");
    if (isRememberMe === "true") {
      sessionStorage.setItem("x-refresh", refreshToken);
    } else {
      localStorage.setItem("x-refresh", refreshToken);
    }
  },
  removeRefreshToken: () => {
    localStorage.removeItem("x-refresh");
  },
  refreshToken: () => {
    const isRememberMe = localStorage.getItem("rememberMe");
    if (isRememberMe === "true") {
      return sessionStorage.getItem("x-refresh");
    } else {
      return localStorage.getItem("x-refresh");
    }
  },
  removeIsRememberMe: () => {
    localStorage.removeItem("rememberMe");
  },
  setIsRememberMe: () => {
    localStorage.setItem("rememberMe", "true");
  },
};
