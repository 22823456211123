import { PageType } from "src/redux/slice/playlist";
import { SuggestionType } from "src/types/types";
import urlcat from "urlcat";
import api from "./api";
import {
  createSequenceRequestSchema,
  editVideoResponse,
  FetchVideoDetailsFromOriginResponse,
  getEditVideoDetailsResponse,
  getPlaylistIdResponseSchema,
  transformVideoResponse,
  VideoResponse,
} from "./transform/video";

import {
  addToPrivateType,
  downloadLink,
  SaveVideoInput,
  videoSequenceObj,
} from "./types";

export type TypeAllVideoFormate = {
  name: string;
  _id: string;
  isDeleted: boolean;
};
const videoUrl = "api/v1/video/";

const sequenceRoute = "api/v1/sequence";

export const getLinkDetails = async (url: string) => {
  const response = await api.request<FetchVideoDetailsFromOriginResponse>({
    url: `${videoUrl}get-url-detail?url=${url}`,
    method: "get",
  });
  return response;
};

export const saveVideos = async (data: SaveVideoInput) => {
  const formData = new FormData();
  for (const keys in data) {
    if (keys === "tag") {
      const tag = JSON.stringify(data.tag);
      formData.set(keys, tag);
    } else {
      // @ts-ignore
      formData.set(keys, data[keys]);
    }
  }
  const response = await api.request<VideoResponse>({
    url: videoUrl,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  console.log({ response });
  if (response.remote === "success") {
    return {
      remote: "success",
      data: transformVideoResponse(response.data),
    };
  }
  return response;
};

type GetAllVideosFilter = {
  filter?: "sharedWith" | "onlyMe" | "archived" | "highlight";
  name?: string;
  days: number;
};

type GetAllVideosPrivacyType = {
  filter: "archived" | "highlight";
  search?: string;
  days?: number;
};
export const getAllVideos = async (data: GetAllVideosFilter) => {
  const response = await api.request<VideoResponse[]>({
    url: urlcat(videoUrl, data),
    method: "get",
  });
  if (response.remote === "success") {
    return {
      remote: "success",
      data: response.data.map((data) => transformVideoResponse(data)),
    };
  }
  return response;
};

export const getVideoDetailsById = async (id: string) => {
  const response = await api.request<VideoResponse>({
    url: urlcat(`${videoUrl}:id`, { id }),
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: "success",
      data: transformVideoResponse(response.data),
    };
  }
  return response;
};

export const sharedWithMeVideos = async (name: string, days: number) => {
  const response = await api.request<VideoResponse[]>({
    url: `${videoUrl}sharedWithMe?name=${name}&&days=${days}`,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: "success",
      data: response.data.map((data) => transformVideoResponse(data)),
    };
  }
  return response;
};

export const getAllTagHandler = async () => {
  const response = await api.request<SuggestionType[]>({
    url: `${videoUrl}getAllTagHandler`,
    method: "GET",
  });
  return response;
};

export const getAllHighlightOrArchiveVideoHandler = async (
  data: GetAllVideosPrivacyType
) => {
  const response = await api.request<VideoResponse[]>({
    url: `${videoUrl}getAllHighlightVideo/?filter=${data.filter}&&search=${data.search}&&days=${data.days}`,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: "success",
      data: response.data.map((data) => transformVideoResponse(data)),
    };
  }
  return response;
};

export const addToPrivate = async (id: string) => {
  const newPayload = {
    id,
  };
  const response = await api.request<addToPrivateType>({
    url: `${videoUrl}add-private`,
    method: "put",
    data: newPayload,
  });
  return response;
};

export const deleteVideo = async (id: string) => {
  const newPayload = {
    id,
  };
  const response = await api.request({
    url: `${videoUrl}delete-video`,
    method: "delete",
    data: newPayload,
  });
  return response;
};

export const getEditVideoDetails = async (id: string) => {
  const response = await api.request<getEditVideoDetailsResponse>({
    url: `${videoUrl}video-detail-for-edit/${id}`,
    method: "get",
  });
  return response;
};

export const editVideo = async (data: SaveVideoInput) => {
  const formData = new FormData();
  for (const keys in data) {
    if (keys === "tag") {
      const tag = JSON.stringify(data.tag);
      formData.set(keys, tag);
    } else {
      // @ts-ignore
      formData.set(keys, data[keys]);
    }
  }
  const response = await api.request<editVideoResponse>({
    url: `${videoUrl}edit-video`,
    method: "patch",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

export const downloadVideo = async (link: string) => {
  const response = await api.request<downloadLink>({
    url: `${videoUrl}download?url=${link}`,
    method: "get",
  });
  return response;
};

export const recycleVideo = async (id: string) => {
  const response = await api.request({
    url: `${videoUrl}recycle`,
    method: "patch",
    data: id,
  });
  return response;
};

export const getPlaylistIdByVideoId = async (id: string) => {
  const response = await api.request<getPlaylistIdResponseSchema>({
    url: `${videoUrl}to-playlist-id/${id}`,
    method: "get",
  });
  return response;
};

export const facebookVideoDetail = async (url: string) => {
  // @ts-ignore
  const response = await api.request({
    url: `${videoUrl}facebook-video?url=${url}`,
    method: "get",
  });
  // @ts-ignore
  return response;
};

export const createSequence = async (payload: createSequenceRequestSchema) => {
  const response = await api.request({
    url: `${sequenceRoute}/create-sequence`,
    method: "post",
    data: payload,
  });
  return response;
};

export const getSequences = async (action: string) => {
  const response = await api.request<videoSequenceObj[]>({
    url: `${sequenceRoute}/?action=${action}`,
    method: "get",
  });
  return response;
};

export const deleteShareWithMeVideo = async (id: string) => {
  // `${videoUrl}to-playlist-id/${id}`,
  const response = await api.request({
    url: `${videoUrl}share-with-me-delete/${id}`,
    method: "delete",
  });
  return response;
};

export const deleteVideoAfterDownload = async (path: string) => {
  const response = await api.request({
    url: `${videoUrl}delete-download-video`,
    method: "delete",
    data: path,
  });
  return response;
};

export const getAllPage = async () => {
  const response = await api.request<PageType[]>({
    url: "api/v1/admin/content",
    method: "get",
  });
  return response;
};

export const getFormate = async () => {
  const response = await api.request<TypeAllVideoFormate[]>({
    url: `${videoUrl}formate`,
    method: "get",
  });
  return response;
};