import styles from "./footer.module.css";
import React from "react";
import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";

const Footer = () => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate("/privacy_policy");
  };
  return (
    <>
      <Container>
        <Stack
          direction={{ xs: "column", lg: "row" }}
          spacing={1}
          justifyContent={{ xs: "center", lg: "space-between" }}
        >
          <div className={`w-100`}>
            <ul className={styles.footer_ul}>
              <li className={styles.footer_li}>Home</li>

              <li className={styles.footer_li}>
                <Link to={"/about"}>About </Link>
              </li>

              <li className={styles.footer_li}>
                <Link to={"/terms-of-use"}>Terms of use </Link>
              </li>

              <li
                className={styles.footer_li}
                onClick={handleOnClick}
                style={{ cursor: "pointer" }}
              >
                Privacy Policy
              </li>
            </ul>
          </div>
          <div className={`w-100`}>
            <p className={styles.footer_copyright_onestream}>
              © {new Date().getFullYear()} ONEStream, Inc.
            </p>
          </div>
        </Stack>
      </Container>
    </>
  );
};

export default Footer;
