import React, { useState, useEffect, useCallback } from "react";
import styles from "./video.module.css";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  Dropdown,
  Nav,
  Navbar,
  OverlayTrigger,
  ProgressBar,
  Tooltip,
} from "react-bootstrap";
import { Button, Modal } from "antd";
import SVG from "src/assets/svg";
import { Link, useLocation } from "react-router-dom";
import IMAGES from "src/assets/image";
import { Video } from "src/redux/slice/video";
import {
  addToArchiveHandler,
  deleteArchiveOrHightLight,
} from "src/api/archivedOrHightlight";
import { highlightOrArchivedType } from "src/types/types";
import { message } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import {
  CloseOutlined
} from '@ant-design/icons';

import {
  deleteVideoAfterDownload,
  downloadVideo,
  getSequences,
  getVideoDetailsById,
  recycleVideo,
} from "src/api/video";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import { videoSequenceObj } from "src/api/types";
// import { FullScreen, useFullScreenHandle } from "react-full-screen";

const VideoHeader = () => {
  const [downloadProgressBar, setDownloadProgressBar] = useState<number>(0);
  // const handle = useFullScreenHandle();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [pageAction, setPageAction] = useState<string>("");
  const [videoId, setVideoId] = useState("");
  const [videoList, setVideoList] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const isLoggedIn: boolean = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const [videoDetails, setVideoDetails] = useState<Video>({
    id: "",
    thumbnail: "",
    source: "",
    userImage: "",
    title: "",
    description: "",
    isHighlighted: false,
    videoUrl: "",
    isProviderDelete: false,
  });
  const addToArchived = async (id: string) => {
    const payload: highlightOrArchivedType = {
      videoId: id,
      privacyType: "highlight",
    };
    const data = await addToArchiveHandler(payload);
    if (data.remote === "success") {
      setVideoDetails({
        ...videoDetails,
        isHighlighted: true,
      });
    } else {
      message.error("SomeThing Went Wrong");
    }
  };

  const getVideoDetails = useCallback(async (videoId: string) => {
    const res = await getVideoDetailsById(videoId);
    if (res.remote === "success") {
      setVideoDetails(res.data);
    }
  }, []);

  const handleDeleteArchiveOrHightLight = useCallback(
    async (videoId: string) => {
      const data = await deleteArchiveOrHightLight(videoId);
      if (data.remote === "success") {
        setVideoDetails({ ...videoDetails, isHighlighted: false });
      }
    },
    [videoDetails]
  );

  const recycleVideoHandler = async (id: string) => {
    setIsLoading(true);
    const queryParams = new URLSearchParams(location.search);
    const action = queryParams.get("action");
    const data = await recycleVideo(id);
    if (data.remote === "success") {
      navigate("/home");
      // const currentIndex = videoList.findIndex((elem) => elem === videoId);
      // const nextVideo = videoList[currentIndex + 1];
      // if (nextVideo && nextVideo !== videoDetails.id) {
      //   navigate(`/video/${nextVideo}?action=${action}`);
      // } else {
      //   const previousVideo = videoList[0];
      //   if (previousVideo && previousVideo !== videoDetails.id) {
      //     navigate(`/video/${previousVideo}?action=${action}`);
      //   } else {
      //     navigate("/home");
      //   }
      // }
    } else {
      message.error("Internal server error");
    }
    setIsLoading(false);
  };

  const getVideoSequences = useCallback(async (action: string) => {
    const data = await getSequences(action);
    if (data.remote === "success") {
      const newArray: string[] = [];
      data.data.forEach((item: videoSequenceObj) => {
        newArray.push(item.videoId);
      });
      setVideoList(newArray);
    }
  }, []);

  const handleCancel = () => {
    setOpen(false);
  };
  const handleOk = async () => {
    await recycleVideoHandler(videoId);
    setOpen(false);
  };
  useEffect(() => {
    setVideoId(params.videoId as string);
    const queryParams = new URLSearchParams(location.search);
    const action = queryParams.get("action");
    setPageAction(action as string);
    if (action) {
      getVideoSequences(action as string);
    }
  }, [getVideoSequences, location.search, params.videoId]);

  async function getBase64FromUrl(url: string) {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }
  const generateYoutubeDownloadLink = useCallback(
    async (link: string, action?: string) => {     
      setIsVideoLoading(true);
      try {
        const data = await downloadVideo(link);
        if (data.remote === "success") {
          const tag = document.createElement("a");
          const url = process.env.REACT_APP_BACKEND_URL + data.data.path;
          const base64 = await getBase64FromUrl(url);
          console.log(base64);
          tag.href = base64 as string;
          tag.download = `${videoDetails.title}.mp4`;
          tag.click();
          setDownloadProgressBar(0);
           await deleteVideoAfterDownload(data.data.path);
        }
      } catch (error) {
        message.error("Something went wrong");
        console.error(error);
      }
      setIsVideoLoading(false);
    },
    [videoDetails.title]
  );
  const otherLinkDownload = async () => {
    setIsVideoLoading(true);
    const element = document.getElementById("customVideoPlayer");
    //@ts-ignore

    if (element) {
      //@ts-ignore
      if (videoDetails.providerName === "Instagram") {
        const tag = document.createElement("a");
        //@ts-ignore
        tag.href = element.src;
        //@ts-ignore
        tag.download = `${videoDetails.title}test1.mp4`;
        //@ts-ignore
        tag.click();
        setIsVideoLoading(false);
        setDownloadProgressBar(0);
        return;
      }
      //@ts-ignore
      const base64 = await getBase64FromUrl(element.src as string);
      const tag = document.createElement("a");
      tag.href = base64 as string;
      tag.download = `${videoDetails.title}test1.mp4`;
      tag.click();
      setDownloadProgressBar(0);
    } else {
      message.error("Video provider not supported");
    }
    setIsVideoLoading(false);
  };

  useEffect(() => {
    if (videoId) {
      getVideoDetails(videoId);
    }
  }, [getVideoDetails, videoId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (downloadProgressBar < 99 && isVideoLoading) {
        setDownloadProgressBar(downloadProgressBar + 1);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [downloadProgressBar, isVideoLoading]);

  return (
    <>
      <Modal
        title="Video"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        okButtonProps={{
          style: { backgroundColor: "red", color: "white" },
          children: "Yes",
        }}
      >
        <p> Do you want to delete this video</p>
      </Modal>

      <Navbar expand="lg" className={`${styles.video_header}`}>
        {/* <Link to="/home" className={styles.logo}>
          <Navbar.Brand>ONEStream</Navbar.Brand>
        </Link> */}

        {isLoggedIn ? (
          <Nav className={styles.videoheader_nav}>
            <div className={`ms-auto`}>
              <div className="d-flex me-4 align-items-center">
                <div className={styles.downloading_bar}>
                  <CloseOutlined
                    onClick={() => navigate("/home")}
                    style={{
                      cursor: "pointer",
                      marginRight: "15px",
                      fontSize: "18px",
                    }}
                  />
                  {isVideoLoading ? (
                    <>
                      {" "}
                      <div className=" position-relative">
                        <div className="header_progress_download">
                          <ProgressBar
                            animated
                            variant="danger"
                            now={downloadProgressBar}
                            // label={`Downloading...${downloadProgressBar}%`}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {videoDetails.providerName === "Instagram" ||
                      videoDetails.providerName === "Twitter" ||
                      videoDetails.providerName === "Tiktok" ||
                      videoDetails.providerName === "Facebook" ||
                      videoDetails.providerName === "xvideos" ||
                      videoDetails.providerName === "YouTube" ? (
                        <Button
                          onClick={() => {
                            if (videoDetails.providerName === "YouTube") {
                              generateYoutubeDownloadLink(
                                videoDetails.videoUrl
                              );
                              
                            } else {
                              if (
                                videoDetails.providerName === "Instagram" ||
                                videoDetails.providerName === "Twitter" ||
                                videoDetails.providerName === "Tiktok" ||
                                videoDetails.providerName === "Facebook" ||
                                videoDetails.providerName === "xvideos"
                              ) {
                                otherLinkDownload();
                              }
                              console.log("Video provider not supported");
                            }
                          }}
                          disabled={isVideoLoading}
                        >
                          {isVideoLoading ? (
                            <>
                              <CircularProgressbar
                                value={downloadProgressBar}
                                text={`${downloadProgressBar}%`}
                              />
                            </>
                          ) : (
                            "Download"
                          )}
                        </Button>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
                {pageAction === "ShareWithMe" ? (
                  <></>
                ) : (
                  <>
                    {videoDetails?.isHighlighted ? (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="tooltip">Favorite</Tooltip>}
                      >
                        <SVG.FillHeart
                          className={styles.icon}
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleDeleteArchiveOrHightLight(videoId);
                          }}
                        />
                      </OverlayTrigger>
                    ) : (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="tooltip">Favorite</Tooltip>}
                      >
                        <SVG.Heart
                          className={styles.icon}
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            addToArchived(videoId);
                          }}
                        />
                      </OverlayTrigger>
                    )}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="tooltip">Share</Tooltip>}
                    >
                      <Dropdown className={``}>
                        <Dropdown.Toggle
                          variant="outline"
                          id="dropdown-basic"
                          className={`text-light`}
                        >
                          <SVG.Share
                            className={`${styles.share_icon} active`}
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={styles.header_dropdown}>
                          <h1 className={`${styles.drop_heading} px-3`}>
                            Share on social media
                          </h1>
                          <div className={`${styles.middle_img_div} px-3`}>
                            <WhatsappShareButton
                              url={`${window.location.href}`}
                            >
                              <img
                                src={IMAGES.whatsApp}
                                alt="img"
                                className={styles.whatsapp}
                              />
                            </WhatsappShareButton>
                            <TwitterShareButton url={`${window.location.href}`}>
                              <img
                                src={IMAGES.twitter1}
                                alt="img"
                                className={styles.twitter}
                              />
                            </TwitterShareButton>

                            <FacebookShareButton
                              url={`${window.location.href}`}
                            >
                              <img
                                src={IMAGES.facebook}
                                alt="img"
                                className={styles.facebook}
                              />
                            </FacebookShareButton>

                            {/* <SVG.FlexThreeDots /> */}
                          </div>
                          <div className={`${styles.middle_text_div} px-3`}>
                            <span className={styles.whatsapp_text}>
                              WhatsApp
                            </span>
                            <span className={styles.twitter_text}>Twitter</span>
                            <span className={styles.whatsapp_text}>
                              Facebook
                            </span>
                            {/* <span className={styles.more_text}>More</span> */}
                          </div>
                          <p className={styles.text_sub_heading}>
                            Share on OneStream members
                          </p>
                        </Dropdown.Menu>
                      </Dropdown>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="tooltip">Delete</Tooltip>}
                    >
                      <SVG.Delete
                        className={styles.delete_icon}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setOpen(true);
                        }}
                      />
                    </OverlayTrigger>
                  </>
                )}
              </div>
            </div>
          </Nav>
        ) : (
          <span style={{ marginLeft: "auto" }}>
            <CloseOutlined
              onClick={() => navigate("/home")}
              style={{
                cursor: "pointer",
                marginRight: "15px",
                fontSize: "18px",
              }}
            />
          </span>
        )}
      </Navbar>
    </>
  );
};

export default VideoHeader;
