import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getEditVideoDetailUserResponse } from "src/api/transform/video";

export type Video = {
  id: string;
  thumbnail: string;
  source: string;
  userImage: string;
  title: string;
  description: string;
  isHighlighted: boolean | string;
  videoUrl: string;
  providerName?: string;
  gifImage?: string;
  isProviderDelete: boolean;
  users?: getEditVideoDetailUserResponse[];
  ownerImage?: string;
};

interface IPVideoInitialState {
  videoList: Video[];
  globalSearch: string;
  stopGlobalSearch: boolean;
  isFavorite: boolean;
  days: number;
  isHideSideBar: boolean;
  isMobileView: boolean;
}

const initialState: IPVideoInitialState = {
  videoList: [],
  globalSearch: "",
  stopGlobalSearch: false,
  isFavorite: false,
  days: 0,
  isHideSideBar: false,
  isMobileView: false,
};

export const VideoSlice = createSlice({
  name: "playlist",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setVideoList: (state, action: PayloadAction<Video[]>) => {
      state.videoList = action.payload;
    },
    setGlobalSearch: (state, action: PayloadAction<string>) => {
      state.globalSearch = action.payload;
    },
    setStopGlobalSearch: (state, action: PayloadAction<boolean>) => {
      state.stopGlobalSearch = action.payload;
    },
    setFavorite: (state, action: PayloadAction<boolean>) => {
      state.isFavorite = action.payload;
    },
    setFilter: (state, action: PayloadAction<number>) => {
      state.days = action.payload;
    },
    setIsHideSideBar: (state, action: PayloadAction<boolean>) => {
      state.isHideSideBar = action.payload;
    },
    setIsMobileView: (state, action: PayloadAction<boolean>) => {
      state.isMobileView = action.payload;
    },
  },
});
export const {
  setVideoList,
  setGlobalSearch,
  setStopGlobalSearch,
  setFavorite,
  setFilter,
  setIsHideSideBar,
  setIsMobileView,
} = VideoSlice.actions;
export default VideoSlice.reducer;
